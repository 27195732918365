import { notifications } from "@mantine/notifications";
import api from "../../Login/Authentication";
import { CompanySettings } from "../Companies/Companies.types";
import { Vehicle, VehicleCreate, VehicleUpdate } from "./Vehicles.types";
import { AxiosResponse } from "axios";
import { modals } from "@mantine/modals";
import { Driver } from "../Drivers/Drivers.types";
/**
 * Retrieves trucks data for all companies.
 *
 * @returns {Promise<TruckSettings[]>} Array of truck settings for all companies.
 * @throws {Error} If an error occurs while fetching the trucks.
 */
export async function getTrucks(): Promise<{ allTrucks: Vehicle[], allDrivers: Driver[] }> {
  try {
    notifications.show({
      id: 'Updating',
      title: 'Retrieving trucks data',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });

    const allTrucks: Vehicle[] = [];
    const allDrivers: Driver[] = [];

      const companyDriversResponse: AxiosResponse<Driver[]> = await api.get(`/api/v1/driver`);
      
      const drivers: Driver[] = companyDriversResponse.data;
      allDrivers.push(...drivers);  // Collecting all drivers

      const trucksResponse: AxiosResponse<Vehicle[]> =
        await api.get('/api/v1/truck');
      const trucks: Vehicle[] = trucksResponse.data;

      trucks.forEach(truck => {
        const driver = drivers.find(d => d.id === truck.assigneddriverid);
        truck.assigneddrivername = driver ? `${driver.firstName} ${driver.lastName}` : 'Unassigned';
      });

      allTrucks.push(...trucks);
    // }

    notifications.hide('Updating');

    notifications.show({
      title: 'Done',
      message: 'Trucks updated successfully.',
      color: 'teal',
    });

    return { allTrucks, allDrivers }; 
  } catch (error: unknown) {
    console.error('Error fetching trucks:', error);
    notifications.hide('Updating');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while updating trucks list.',
      color: 'red',
    });
    throw error;
  }
}

export const handleCreateTruckSubmit = async (truck: VehicleCreate) => {
  try {
    notifications.show({
      id: 'CreatingTruck',
      title: 'Creating truck',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });

    const companiesResponse: AxiosResponse<CompanySettings[]> = await api.get(`/api/v1/company/${truck.assignedcompanyid}`);

    const { assignedcompanyid, assigneddriverid, ...cleanData } = truck;
    const truckReadyToSubmit = {
      unitNumber: cleanData.unitnumber,
      licensePlateNumber: cleanData.licenseplateNumber,
      VIN: cleanData.vin,
      deviceSerialNumber: cleanData.deviceserialnumber
    }

    await api.post(`/api/v1/truck/`, truckReadyToSubmit);

    notifications.hide('CreatingTruck');

    notifications.show({
      title: 'Done!',
      message: 'Truck created successfully. Page will be reloaded.',
      color: 'teal',
    });
    modals.closeAll();

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error: unknown) {
    console.error('Error creating trucks:', error);
    notifications.hide('CreatingTruck');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while updating the company.',
      color: 'red',
    });
    throw error;
  }
}


export const handleDeleteVehicleSubmit = async (selectedTruck: Vehicle) => {
  try {
    notifications.show({
      id: 'DeletingTruck',
      title: 'Removing truck from company',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });

    const companiesResponse: AxiosResponse<CompanySettings[]> = await api.get(`/api/v1/company/${selectedTruck.assignedcompanyid}`);

    await api.delete(`/api/v1/truck/${selectedTruck.id}`);

    notifications.hide('DeletingTruck');

    notifications.show({
      title: 'Done!',
      message: 'Truck deleted successfully. Page will be reloaded.',
      color: 'teal',
    });
    modals.closeAll();

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error: unknown) {
    console.error('Error deleting trucks:', error);
    notifications.hide('DeletingTruck');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while removing the truck.',
      color: 'red',
    });
    throw error;
  }
}

export const handleUpdateTruckSubmit = async (selectedTruck: VehicleUpdate) => {
  try {
    notifications.show({
      id: 'UpdatingTruck',
      title: 'Updating truck',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });
    console.log('selectedTruck', selectedTruck);

    const companiesResponse: AxiosResponse<CompanySettings[]> = await api.get(`/api/v1/company/${selectedTruck.assignedcompanyid}`);
    const { assignedcompanyid, assigneddriverid, ...cleanData } = selectedTruck;
    const truckReadyToSubmit = {
      unitNumber: cleanData.unitnumber,
      licensePlateNumber: cleanData.licenseplateNumber,
      VIN: cleanData.vin,
      deviceSerialNumber: cleanData.deviceserialnumber
    }

    await api.put(`/api/v1/truck/${selectedTruck.id}`, truckReadyToSubmit);
    // await api.put(`/api/v1/company/${selectedTruck.assignedcompanyid}/truck/${selectedTruck.id}`); // assign truck to company

    notifications.hide('UpdatingTruck');

    notifications.show({
      title: 'Done!',
      message: 'Truck updated successfully. Page will be reloaded.',
      color: 'teal',
    });
    modals.closeAll();

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error: unknown) {
    console.error('Error updating trucks:', error);
    notifications.hide('UpdatingTruck');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while updating the truck.',
      color: 'red',
    });
    throw error;
  }
}