import { Button, Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { handleDeleteCompanySubmit, handleUpdateCompanySubmit } from './Companies.utils';
import { CreateCompanyModalProps, DeleteCompanyModalProps, UpdateCompanyModalProps } from './Companies.types';
import CompanyAddForm from "./CompanyAddForm";

export const CreateCompanyModal: React.FC<CreateCompanyModalProps> = () => {
    const openCreateModal = () =>
        modals.open({
            title: `Create company`,
            centered: true,
            children: (
                <CompanyAddForm />
            ),
        });

    return <Button
        onClick={openCreateModal}
        color="blue"
        size='md'>
        + Create new company
    </Button>;
}

export const DeleteCompanyModal: React.FC<DeleteCompanyModalProps> = ({ index, company }) => {
    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: `Delete ${company.carrierName}`,
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to delete the {company.carrierName} company? This action is destructive and you will have
                    to contact support to restore your data.
                </Text>
            ),
            labels: { confirm: 'Delete company', cancel: "No don't delete it" },
            confirmProps: { color: 'red' },
            onCancel: () => {
                notifications.show({
                    title: 'Operation canceled',
                    message: `Deletion of ${company.carrierName} canceled.`,
                });
            },
            onConfirm: () => {
                handleDeleteCompanySubmit(company)
            }
        });

    return <Button
        onClick={openDeleteModal}
        color="red"
        size='md'>
        Delete {company.carrierName}
    </Button>;
}

export const UpdateCompanyModal: React.FC<UpdateCompanyModalProps> = ({ values, currentIndex, companies }) => {
    const openUpdateModal = () =>
        modals.openConfirmModal({
            title: `Update ${companies[currentIndex].carrierName}?`,
            centered: true,
            children: (
                <Text size="sm">
                    Are you sure you want to make changes to the company.carrierName company?
                </Text>
            ),
            labels: { confirm: 'Update company', cancel: "Cancel" },
            confirmProps: { color: 'green' },
            onCancel: () => {
                notifications.show({
                    title: 'Operation canceled',
                    message: `No changes were made to ${companies[currentIndex].carrierName}.`,
                });
            },
            onConfirm: () => {
                handleUpdateCompanySubmit(values, currentIndex, companies)
            }
        });

    return <Button
        onClick={openUpdateModal}
        color="green"
        size='md'>
        Save and Submit
    </Button>;
}