import { Button, Flex, Menu } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { Vehicle } from './Vehicles.types';
import { CreateVehicleModal, DeleteVehicleModal, UpdateVehicleModal } from './VehicleModals';
import { getTrucks } from './Vehicles.utils'
import { Driver } from '../Drivers/Drivers.types';

function Vehicles() {
  const [trucks, setTrucks] = useState<Vehicle[]>([]);
  const [drivers, setDrivers] = useState<Driver[]>([]);
  localStorage.removeItem('VehicleDeleteConfirmationWord');

  useEffect(() => {
    async function fetchData() {
      try {
        const trucksData = await getTrucks();
        console.log('trucksData', trucksData);
        setDrivers(trucksData.allDrivers);
        setTrucks(trucksData.allTrucks);
      } catch (error) {
        console.error('Error fetching trucks:', error);
      }
    }

    fetchData();
  }, []);

  const columns = useMemo<MRT_ColumnDef<Vehicle>[]>(
    () => [
      {
        accessorKey: 'unitnumber',
        header: 'Unit number',
      },
      {
        accessorKey: 'licenseplatenumber',
        header: 'License Plate num',
      },
      {
        accessorKey: 'vin',
        header: 'VIN',
      },
      {
        accessorKey: 'deviceserialnumber',
        header: 'Device number',
      },
      {
        accessorKey: 'assignedcompanyname',
        header: 'Company',
      },
      {
        accessorKey: 'assigneddrivername',
        header: 'Asigned driver',
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data: trucks,
    initialState: { density: 'xs', columnVisibility: { assignedcompanyname: false }, sorting: [{ id: 'deviceserialnumber', desc: false }] },
    enableRowActions: true,
    positionActionsColumn: "last",
    enableRowSelection: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <CreateVehicleModal />
        <Button color="red" style={{ marginLeft: "5px", marginRight: "5px" }}>
          Release drivers
        </Button>
      </div>
    ),
    renderRowActionMenuItems: ({ row }) => (
      <Flex gap="xs"
        justify="center"
        align="flex-end"
        direction="column"
        wrap="wrap">
        <DeleteVehicleModal selectedTruck={row.original} />
        <UpdateVehicleModal selectedTruck={row.original} allDrivers={drivers}/>
        <Menu.Item
          style={{ textAlign: 'center', }}
          onClick={() => console.log('assign to group')}
        >
          Assingn to group
        </Menu.Item>
      </Flex>
    )
  });


  return (
    <div style={{ margin: '20px' }}>
      <MantineReactTable table={table} />
    </div>
  )
};

export default Vehicles;
