import { Progress } from '@mantine/core';
import styles from './LiveTrackerDrawer.module.css';

const getSatelliteStatus = (satellites: number) => {
  if (satellites === 0) {
    return { color: 'white', text: 'no signal' };
  } else if (satellites < 5) {
    return { color: 'brown', text: 'weak' };
  } else if (satellites < 10) {
    return { color: 'orange', text: 'normal' };
  } else {
    return { color: 'green', text: 'good' };
  }
};

const LiveTrackingDrawerELD = ({ truckData }: any) => {
  const satelliteStatus = getSatelliteStatus(truckData.Satellites);

  return (
    <div className={styles.liveTrackingDrawerDiagnostics}>
      <div className={styles.liveTrackingDrawerDiagnosticsItem}>
        <div className={styles.liveTrackingDrawerDiagnosticsTitle}>ELD device #</div>
        <div className={styles.liveTrackingDrawerDiagnosticsStatus}>{truckData.DeviceSerialNumber}</div>
      </div>
      <div className={styles.liveTrackingDrawerDiagnosticsItem}>
        <div className={styles.liveTrackingDrawerDiagnosticsTitle}>Satellites</div>
        <Progress value={(truckData.Satellites / 30) * 100} color={satelliteStatus.color} size="xl" style={{ width: '25%' }} />
        {truckData.Satellites} ({satelliteStatus.text})
      </div>
    </div>
  );
};

export default LiveTrackingDrawerELD