import { useContext } from 'react';
import { DriverLogContext } from './DriverLog';
import { Accordion } from '@mantine/core';
import { useLocation } from 'react-router-dom';

const DriverLogInfo = () => {
  const { driverLog } = useContext(DriverLogContext);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dateString = queryParams.get('date') ?? '';

  function generateDateArray(dateRange: string) {
    const matchResult = dateRange.match(/(\d{2})(\d{2})(\d{2})-(\d{2})(\d{2})(\d{2})/);

    if (matchResult !== null) {
      const [startMonth, startDay, startYear, endMonth, endDay, endYear] = matchResult.slice(1);

      const startDate = new Date(`20${startYear}-${startMonth}-${startDay}`);
      const endDate = new Date(`20${endYear}-${endMonth}-${endDay}`);

      const dateArray = [];

      let currentDate = startDate;
      while (currentDate <= endDate) {
        dateArray.push(currentDate.toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' }));
        currentDate = new Date(currentDate);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateArray;
    } else {
      return [];
    }
  }

  const dateArray = generateDateArray(dateString);

  const divs = <div className="driverLogInfo-container">
    <div className="row">
      <h2 className="driverLogInfo-title">Log Form</h2>
      <div className="driverLogInfo">
        <div className="driverLogInfo-column">
          <div className="driverLogInfo-name">
            Driver:
          </div>
          <div className="driverLogInfo-name">
            Vehicle Plate No:
          </div>
          <div className="driverLogInfo-name">
            Total Mileage:
          </div>
          <div className="driverLogInfo-name">
            Shipping Documents:
          </div>
          <div className="driverLogInfo-name">
            CoDriver:
          </div>
          <div className="driverLogInfo-name">
            Trailers:
          </div>
          <div className="driverLogInfo-name">
            Carrier Name:
          </div>
        </div>
        <div className="driverLogInfo-column">
          <div className="driverLogInfo-item">
            Add text
          </div>
          <div className="driverLogInfo-item">
          Add text
          </div>
          <div className="driverLogInfo-item">
          Add text
          </div>
          <div className="driverLogInfo-item">
            No shipping docs.
          </div>
          <div className="driverLogInfo-item">
            No codriver
          </div>
          <div className="driverLogInfo-item">
            No trailers
          </div>
          <div className="driverLogInfo-item">
          Add text
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <h2 className="driverLogInfo-title">DVIR's</h2>
      <div className="driverLogInfo">
        <table className="table-container">
          <thead className="table-header">
            <tr>
              <th className="driverLogInfo-name">Date</th>
              <th className="driverLogInfo-name">PlateNo</th>
              <th className="driverLogInfo-name">Trailers</th>
            </tr>
          </thead>
          <tbody className="table-body">
            <tr className="row3">
              <td className="column">11</td>
              <td className="column"></td>
              <td className="column"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="row">
      <h2 className="driverLogInfo-title">Driver signature</h2>
      <div className="driverLogSignature">
      </div>
    </div>
  </div>

  const dataArr = dateArray.map(day => {
    return {
      value: `driver log info - ${day}`,
      description: divs
    }
  })

  const items = dataArr.map((item) => (
    <Accordion.Item key={item.value} value={item.value}>
      <Accordion.Control>{item.value}</Accordion.Control>
      <Accordion.Panel>{item.description}</Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      {dateArray.length > 0 
        ? (
          <Accordion
            defaultValue={dateArray[0]}
            className='driverLogInfoAccordion'
            transitionDuration={500}
            variant="separated"
          >
            {items}
          </Accordion>
        )
        : divs
      }
    </>
  );
}

export default DriverLogInfo;
