import { useContext, useMemo, useState, useEffect, createContext } from 'react';
import {
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_TableOptions,
  useMantineReactTable,
  createRow,
} from 'mantine-react-table';
import { Button, Flex, Text } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { v4 as uuidv4 } from 'uuid';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { DriverLogContext } from './DriverLog';
import { parseELDDataToTableFormat } from '../../utils/DriverlogTable';

type UserLog = {
  date: string;
  isCreating: boolean;
  status: string
  vehicle: string
  startTime: string
  odometer: number
  totalEngineHours: number
  duration: string
  location: any
  note: string
  distance: number
  engineHours: number
};

const Example = () => {
  const [data, setData] = useState<UserLog[]>([])
  const [isCreating, setIsCreating] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({}); //keep track of rows that have been edited
  const [editedLog, seteditedLog] = useState<Record<string, UserLog>>({});
  const [isLoadingData, setIsLoadingData] = useState(true);

  // const { driverLog, currentDriver, driversList } = useContext(DriverLogContext);
  const { driverLog, driversList, currentDriver } = useContext(DriverLogContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!driverLog) {
          throw new Error('driverLog is undefined');
        }

        const formattedData = await parseELDDataToTableFormat(driverLog);
        if (formattedData.length > 0) {
          // formattedData.pop(); // Delete last row in the table
        }
        setData(formattedData);
      } catch (error) {
        // console.error('Error parsing driver log data:', error);
        setIsLoadingData(false);
      } finally {
        setIsLoadingData(false);
      }
    };

    fetchData();
  }, [driversList, driverLog, currentDriver]);


  const statusOptions = ['ON', 'SB', 'OFF'];

  //call CREATE hook
  const { mutateAsync: createUserLog, isLoading: isCreatingUserLog } =
    useCreateUserLog();
  //call READ hook
  const {
    data: fetchedUserLogs = [],
    isError: isLoadingUserLogError,
    isFetching: isFetchingUserLogs,
    isLoading: isLoadingUserLogs,
  } = useGetUserLogs();
  //call UPDATE hook`
  const { mutateAsync: updateUserLogs, isLoading: isUpdatingUserLog } =
    useUpdateUserLogs();

  //CREATE action
  const handleCreateUserLog: MRT_TableOptions<UserLog>['onCreatingRowSave'] = async ({
    values,
    exitCreatingMode,
  }) => {
    setValidationErrors({});
    await createUserLog(values);
    exitCreatingMode();
  };

  //UPDATE action
  const handleSaveUserLogs = async () => {
    if (Object.values(validationErrors).some((error) => !!error)) return;
    await updateUserLogs(Object.values(editedLog));
    seteditedLog({});
  };

  const getBackgroundGradient = (status: string) => {
    switch (status) {
      case 'OFF':
        return 'linear-gradient(90deg, rgba(108,108,108,0.5) 0%, rgba(0,0,0,0) 100%)';
      case 'ON':
        return 'linear-gradient(90deg, rgba(116,95,235,0.5) 0%, rgba(0,0,0,0) 100%)';
      case 'DRV':
        return 'linear-gradient(90deg, rgba(95,235,102,0.5) 0%, rgba(0,0,0,0) 100%)';
      case 'SB':
        return 'linear-gradient(90deg, rgba(255,149,0,0.5) 0%, rgba(0,0,0,0) 100%)';
      default:
        return 'linear-gradient(90deg, rgba(255,0,0,0.5) 0%, rgba(0,0,0,0) 100%)';
    }
  };

  const columns = useMemo<MRT_ColumnDef<UserLog>[]>(
    () => [
      {
        accessorKey: 'status',
        header: 'Status',
        size: 40,
        // Maxsize: 40,
        enableEditing: (row) => row.original.status !== 'DRV',
        editVariant: 'select',
        mantineTableBodyCellProps: (cell) => ({
          style: {
            background: getBackgroundGradient(cell.row.original.status),
          },
        }),
        mantineEditSelectProps: ({ row }) => ({
          data: statusOptions,
          //store edited user log in state to be saved later
          onChange: (value: any) => {
            seteditedLog({
              ...editedLog,
              [row.id]: { ...row.original, status: value },
            });
            const newStatus = value;
            const newBackground = getBackgroundGradient(newStatus);
            row.original.status = newBackground;
          },
        }),
      },
      {
        accessorKey: 'date',
        header: 'Date',
        size: 40,
        enableEditing: false
      },
      {
        accessorKey: 'startTime',
        header: 'Start Time',
        size: 40,
        Maxsize: 40,
        enableEditing: false
      },
      {
        accessorKey: 'odometer',
        header: 'Odometer, km',
        size: 30,
        Maxsize: 40,
        enableEditing: false
      },
      {
        accessorKey: 'vehicle',
        header: 'Vehicle',
        size: 30,
        Maxsize: 40,
        enableEditing: false
      },
      {
        accessorKey: 'distance',
        header: 'Distance',
        size: 30,
        enableEditing: false
      },
      {
        accessorKey: 'engineHours',
        header: 'Eng. hours',
        size: 30,
        enableEditing: false
      },
      {
        accessorKey: 'duration',
        header: 'Duration',
        size: 30,
        enableEditing: false
      },
      {
        accessorKey: 'location',
        header: 'Location',
        size: 30,
        enableEditing: false
      },
      {
        accessorKey: 'note',
        header: 'Note',
        size: 30,
        mantineEditTextInputProps: ({ cell, row }) => ({
          type: 'note',
          required: false,
          error: validationErrors?.[cell.id],
          //store edited user log in state to be saved later
          onBlur: (event) => {
            seteditedLog({ ...editedLog, [row.id]: row.original });
          },
        }),
      },
    ],
    [editedLog, validationErrors],
  );

  const table = useMantineReactTable({
    enablePagination: false,
    columns,
    data: data,

    createDisplayMode: 'modal', // ('row', 'modal', and 'custom' are also available)
    editDisplayMode: 'cell', // ('modal', 'row', 'cell', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row) => `${row.startTime}-${row.date}-${uuidv4()}`,
    mantineToolbarAlertBannerProps: isLoadingUserLogError
      ? {
        color: 'red',
        children: 'Error loading data',
      }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    // onCreatingRowSave: handleCreateUserLog,
    // },
    onCreatingRowSave: (row) => {
      if (isCreating) {
        setData((prevData) => [...prevData, {
          isCreating: row.values.isCreating,
          status: row.values.status,
          vehicle: row.values.vehicle,
          startTime: row.values.startTime,
          odometer: row.values.odometer,
          totalEngineHours: row.values.totalEngineHours,
          duration: row.values.duration,
          location: row.values.location,
          note: row.values.note,
          distance: row.values.distance,
          engineHours: row.values.engineHours
        } as UserLog]);
      }
      setIsCreating(false);

    },
    renderBottomToolbarCustomActions: () => (
      <Flex align="center" gap="md">
        <Button
          color="blue"
          onClick={handleSaveUserLogs}
          disabled={
            Object.keys(editedLog).length === 0 ||
            Object.values(validationErrors).some((error) => !!error)
          }
          loading={isUpdatingUserLog}
        >
          Save
        </Button>
        {Object.values(validationErrors).some((error) => !!error) && (
          <Text color="red">Fix errors before submitting</Text>
        )}
      </Flex>
    ),
    state: {
      isLoading: isLoadingData || isLoadingUserLogs,
      isSaving: isCreatingUserLog || isUpdatingUserLog,
      showAlertBanner: isLoadingUserLogError,
      showProgressBars: isFetchingUserLogs,
    },

  });

  return <MantineReactTable table={table} />
};

//CREATE hook (post new user to api)
function useCreateUserLog() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userLog: UserLog) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserInfo: UserLog) => {
      queryClient.setQueryData(
        ['userLogs'],
        (prevUserLogs: any) =>
          [
            ...prevUserLogs,
            {
              ...newUserInfo,
              id: (Math.random() + 1).toString(36).substring(7),
            },
          ] as UserLog[],
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['userLogs'] }), //refetch users after mutation, disabled for demo
  });
}

//READ hook (get users from api)
function useGetUserLogs() {
  return useQuery<UserLog[]>({
    queryKey: ['userLogs'],
    queryFn: async () => {
      //send api request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve([]);
    },
    refetchOnWindowFocus: false,
  });
}

//UPDATE hook (put users in api)
function useUpdateUserLogs() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userLog: UserLog[]) => {
      //send api update request here
      await new Promise((resolve) => setTimeout(resolve, 1000)); //fake api call
      return Promise.resolve();
    },
    //client side optimistic update
    onMutate: (newUserLogs: UserLog[]) => {
      queryClient.setQueryData(
        ['userLogs'],
        (prevUserLogs: any) =>
          prevUserLogs?.map((prevUserLog: UserLog) => {
            const newUserLog = newUserLogs.find((u) => u.startTime === prevUserLog.startTime);
            return newUserLog ? newUserLog : prevUserLog;
          }),
      );
    },
    // onSettled: () => queryClient.invalidateQueries({ queryKey: ['userLogs'] }), //refetch users after mutation, disabled for demo
  });
}

const queryClient = new QueryClient();

const ExampleWithProviders = () => (
  //Put this with your other react-query providers near root of your app
  <QueryClientProvider client={queryClient}>
    <ModalsProvider>
      <Example />
    </ModalsProvider>
  </QueryClientProvider>
);
export const mantineTableData = createContext<{ formattedData: UserLog[] | null }>({
  formattedData: null
});

export default ExampleWithProviders;