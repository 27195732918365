import {
  TextInput,
  Button,
  Group,
  Box,
  Paper,
  Title,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Link } from "react-router-dom";
import LoginWrapper from "./LoginWrapper";

interface FormValues {
  email: string;
  password: string;
}

function Login() {
  const form = useForm<FormValues>({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  return (
    <LoginWrapper>
      <Box maw={400} mx="auto" className="loginForm-wrapper">
        <Paper shadow="sm" p={20}>
          <Title order={2} align="center" className="loginTitle">
            Reset password
          </Title>
          <form
            className="loginForm"
            onSubmit={form.onSubmit((values) => console.log(values))}
          >
            <TextInput
              required
              label="Email"
              placeholder="your@email.com"
              {...form.getInputProps("email")}
            />

            <Group position="center" mt="md">
              <Button type="submit">Reset password</Button>
            </Group>
            <Text fz="sm" fw={500}>
              Remember your password? Go to{" "}
              <Link className="loginLink" to="/login">
                login
              </Link>{" "}
              page.
            </Text>
          </form>
        </Paper>
      </Box>
    </LoginWrapper>
  );
}

export default Login;
