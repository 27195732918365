import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Button, Select } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"

const ELDLogDatePicker = () => {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);  //should be memoized or stable
  const [selectData, setSelectData] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
  const [selectError, setSelectError] = useState(false); // New state for select input error
  const [datePickerError, setDatePickerError] = useState(false); // New state for date picker input error

  const maxDaysInRange = 8;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/driverList");
        if (response.ok) {
          const jsonData = await response.json();
          setSelectData(jsonData);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleRangeChange = (newRange: [Date | null, Date | null]) => {
    if (newRange[0]) {
      const start = dayjs(newRange[0]);
      const end = newRange[1] ? dayjs(newRange[1]) : start;
      const difference = end.diff(start, 'day');

      if (difference <= maxDaysInRange - 1) {
        setValue([newRange[0], newRange[1]]);
      } else {
        setValue([newRange[0], start.add(maxDaysInRange - 1, 'day').toDate()]);
      }
    } else {
      setValue([null, null]);
    }
    setSelectError(false);
    setDatePickerError(false);
  };

  const handleShowRange = (): void => {
    const startDate = value[0] ? formatDateForURL(value[0]) : '';
    const endDate = value[1] ? formatDateForURL(value[1]) : '';

    if (!selectedDriver) {
      setSelectError(true); // Set select input error to true if it's empty
    } else {
      setSelectError(false); // Reset select input error
    }

    if (!startDate || !endDate) {
      setDatePickerError(true); // Set date picker input error to true if either is empty
    } else {
      setDatePickerError(false); // Reset date picker input error
    }

    if (startDate && endDate && startDate !== endDate && selectedDriver) {
      window.location.href = `/dashboard/support/ELDLog?id=${selectedDriver}&date=${startDate}-${endDate}`;
    } else if (startDate && selectedDriver) {
      window.location.href = `/dashboard/support/ELDLog?id=${selectedDriver}&date=${startDate}`;
    }
  };

  const formatDateForURL = (date: Date): string => {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString().substring(2);
    return `${month}${day}${year}`;
  };

  return (
    <div className="driverLogDatePicker">
      <Select
      className="driverLogDatePickerItem"
        label="Select driver"
        data={selectData}
        searchable
        clearable
        value={selectedDriver}
        onChange={(newValue) => {
          setSelectedDriver(newValue);
          setSelectError(false); // Reset select input error when a value is selected
        }}
        placeholder={selectError ? "Please select a driver" : "Enter driver's name"}
        error={selectError}
      />
      <DatePickerInput
        clearable
        allowSingleDateInRange
        maxDate={new Date()}
        className="driverLogDatePickerItem"
        type="range"
        value={value}
        onChange={handleRangeChange}
        label={`Select range (max ${maxDaysInRange} days)`}
        placeholder={datePickerError ? "Please select a date range" : "Enter date range"}
        error={datePickerError}
      />
      <Button className='driverLogDatePickerItem' onClick={handleShowRange} color="green">{`Show range`}</Button>
    </div>
  )
}

export default ELDLogDatePicker;
