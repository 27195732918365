import { Drawer, Button, Accordion, rem, Group, Flex } from '@mantine/core';
import { IconLocation, IconPhone, IconClock, IconShare3, IconAlertCircle, IconChartInfographic, IconPackageExport, IconRouter } from '@tabler/icons-react';
import styles from './LiveTrackerDrawer.module.css';
import { IconMail, IconUserCircle } from '@tabler/icons-react';
import dayjs from 'dayjs';

import { useEffect, useState } from 'react';
import LiveTrackingDrawerDonuts from './LiveTrackingDrawerDonuts';
import LiveTrackingDrawerHOS from './LiveTrackingDrawerHOS';
import LiveTrackingDrawerDiagnostics from './LiveTrackingDrawerDiagnostics';
import LiveTrackingDrawerLoadDetails from './LiveTrackingDrawerLoadDetails';
import LiveTrackingDrawerELD from './LiveTrackingDrawerELD';
import LiveTrackingShareModal from './LiveTrackingShareModal';
import socketStore from '../../Store/socketStore';
import { observer } from 'mobx-react-lite';
import { getAddressFromCoordinates } from './LiveTrackingUtils';

type LiveTrackingDrawerProps = {
    truckData: any; // Replace `any` with the appropriate type if you have one
    closeDrawer: () => void;
    opened: boolean;
    updateTruckStatus: (id: string, newStatus: string) => void;
};

const LiveTrackingDrawer: React.FC<LiveTrackingDrawerProps> = observer(({ truckData, closeDrawer, opened, updateTruckStatus }) => {
    const [address, setAddress] = useState<undefined | string>('updating...');
    const [status, setStatus] = useState<undefined | string>('updating...');
    const dateToday = dayjs(new Date()).format('YYYY-MM-DD')

    const statusColors: any = {
        'on_duty':
        {
            color: '#0153B4',
            name: 'ON'
        },
        'driving': {
            color: '#5AC534',
            name: 'DRV'
        },
        'sb': {
            color: '#FFCE21',
            name: 'SB'
        },
        'OFF': {
            color: '#000',
            name: 'OFF'
        }
    }

    function getStatus(truckEvent: string | undefined) {
        switch (truckEvent) {
            case 'on_duty':
                return statusColors['on_duty'];
            case 'driving':
                return statusColors['driving'];
            case 'sb':
                return statusColors['sb'];
            case 'off_duty':
                return statusColors['off_duty'];
            default:
                return {
                    color: '#FFC0CB', // Pink color
                    name: 'n/a'
                };
        }
    }

    const { color, name } = getStatus(truckData?.Event);
    useEffect(() => {
        console.log('truckData socket', socketStore.data);
    }, [socketStore.data])

    useEffect(() => {
        if (truckData && opened) {
            getAddressFromCoordinates(truckData.latitude, truckData.longitude)
                .then((address) => setAddress(address))
                .catch((error) => console.error('Error fetching address:', error));
        }
    }, [truckData, opened]);

    function getTimeDifference(date: Date): string {
        const now: Date = new Date();
        const diffMs: number = now.getTime() - date.getTime();

        const seconds: number = Math.floor(diffMs / 1000);
        const minutes: number = Math.floor(seconds / 60);
        const hours: number = Math.floor(minutes / 60);
        const days: number = Math.floor(hours / 24);

        const remainingHours: number = hours % 24;
        const remainingMinutes: number = minutes % 60;
        const remainingSeconds: number = seconds % 60;

        if (days > 0) {
            return `${days} days ${remainingHours} hours`;
        } else {
            return `${String(remainingHours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
        }
    }

    return (
        <Drawer
            size="xs"
            opened={opened}
            onClose={closeDrawer}
            title={
                <Flex
                    gap="xl"
                    justify="center"
                    align="center"
                    direction="row"
                    wrap="wrap" >
                    <div>
                        {truckData ? `Truck ${truckData.LicensePlateNumber} ${truckData.TruckId}` : 'no data'}
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        width: '40px',
                        height: '40px',
                        margin: '-5px 0 0 5px',
                        backgroundColor: color,
                        borderRadius: '50%'
                    }}>
                        {name}
                    </div>
                </Flex>
            }
            withCloseButton
            withOverlay={false}
            position='right'
            styles={{
                inner: { top: '80px', zIndex: 100 },
                body: { backgroundColor: 'white', height: 'calc(100vh - 80px)' },
                content: { backgroundColor: 'white' },
                root: { backgroundColor: 'white' },
                title: { fontWeight: 'bold' },
            }}
        >
            {truckData && (
                <div>
                    <div className={styles.vehicleInfo}>
                        <p className={styles.vehicleType}>Vehicle: {truckData.LicensePlateNumber} - <span className={styles.vehicleSpeed}>{(truckData.Speed * 0.621371192).toFixed(0)} MPH</span></p>
                        <div className={styles.vehicleInfoList}>
                            <Group mt="xs">
                                <IconUserCircle size={16} />
                                <a href={`/dashboard/driver-log?id=${truckData.AssignedDriverId}&to=${dateToday}T23:59:59.999Z&from=${dateToday}T00:00:00.000Z`} className={styles.driverInfoString}>
                                    {`${truckData.FirstName} ${truckData.LastName}` || 'no name'}
                                </a>
                            </Group>
                            <Group mt="xs">
                                <IconLocation size={16} />
                                <a href={`https://www.google.com/maps?q=${truckData.latitude},${truckData.longitude}`} target="_blank" rel="noopener noreferrer" className={styles.driverInfoString}>
                                    {String(address)}
                                </a>
                            </Group>
                            <Group mt="xs">
                                <IconMail size={16} />
                                <a href={`mailto:${truckData.driverEmail}`} className={styles.driverInfoString}>
                                    {truckData.driverEmail || 'no email'}
                                </a>
                            </Group>
                            <Group mt="xs">
                                <IconPhone size={16} />
                                <a href={`tel:${truckData.phone}`} className={styles.driverInfoString}>
                                    {truckData.phone || 'no phone'}
                                </a>
                            </Group>
                            <Group mt="xs">
                                <IconClock size={16} />
                                <div className={styles.driverInfoString}>
                                    Last update {getTimeDifference(new Date(truckData.ServerTime)) || 'no phone'}
                                </div>
                            </Group>
                        </div>
                        <LiveTrackingShareModal truckData={truckData} />
                    </div>
                    <Accordion multiple={true} >
                        <Accordion.Item value="HOS">
                            <Accordion.Control
                                icon={
                                    <IconAlertCircle
                                        style={{ color: 'var(--mantine-color-red-6', width: rem(20), height: rem(20) }}
                                    />
                                }
                            >
                                HOS
                            </Accordion.Control>
                            <Accordion.Panel>
                                <LiveTrackingDrawerDonuts />
                                <LiveTrackingDrawerHOS truckData={truckData} />
                            </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item value="VehicleDiagnostics">
                            <Accordion.Control
                                icon={
                                    <IconChartInfographic
                                        style={{ color: 'var(--mantine-color-red-6', width: rem(20), height: rem(20) }}
                                    />
                                }
                            >
                                Vehicle Diagnostics
                            </Accordion.Control>
                            <Accordion.Panel>
                                <LiveTrackingDrawerDiagnostics truckData={truckData} />
                            </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item value="LoadDetails">
                            <Accordion.Control
                                icon={
                                    <IconPackageExport
                                        style={{ color: 'var(--mantine-color-red-6', width: rem(20), height: rem(20) }}
                                    />
                                }
                            >
                                Load Details
                            </Accordion.Control>
                            <Accordion.Panel>
                                <LiveTrackingDrawerLoadDetails truckData={truckData} />
                            </Accordion.Panel>
                        </Accordion.Item>
                        <Accordion.Item value="ELD">
                            <Accordion.Control
                                icon={
                                    <IconRouter
                                        style={{ color: 'var(--mantine-color-red-6', width: rem(20), height: rem(20) }}
                                    />
                                }
                            >
                                ELD
                            </Accordion.Control>
                            <Accordion.Panel>
                                <LiveTrackingDrawerELD truckData={truckData} />
                            </Accordion.Panel>
                        </Accordion.Item>


                    </Accordion>
                </div>
            )}
        </Drawer>
    );
});

export default LiveTrackingDrawer;
