import * as XLSX from "xlsx";
import { Box, Button, Divider, Stack } from "@mantine/core"
import { MRT_TableInstance } from "mantine-react-table";
import { UserELDLog } from "./ELDLog.types";
import { useContext } from "react";
import { ELDLogContext } from "./ELDLog";
import { useParams } from "react-router-dom";

interface ELDLogsTableTopBtnsProps {
    table: MRT_TableInstance<UserELDLog>;
}

const ELDLogsTableTopBtns: React.FC<ELDLogsTableTopBtnsProps> = ({ table }) => {

    const { ELDLog } = useContext(ELDLogContext);

    const handleExportXLSX = () => {
        const data = table.options.data;
        const date = window.location.search
        const searchParams = new URLSearchParams(date);
        const dateParam = searchParams.get('date');
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${ELDLog?.truck.driverFirstName}_${ELDLog?.truck.driverLastName}_${dateParam}.xlsx`);
    };

    return (
        <Box className=""
            sx={{
                display: 'flex',
                gap: '16px',
                padding: '8px',
                flexWrap: 'wrap',
            }}
        >
            <Stack>
                <Button onClick={handleExportXLSX} color='green'>Export Excel</Button>
            </Stack>
            <Divider size={'xl'} color='black' />
            <Stack>
                <Button>Refresh Graph</Button>
                <Button>Restore Graph</Button>
            </Stack>
            <Stack>
                <Button>Reasign driver</Button>
                <Button>Reasign vehicle</Button>
            </Stack>
            <Stack>
                <Button>Shift</Button>
            </Stack>
            <Stack>
                <Button>Download output file</Button>
            </Stack>
            <Stack>
                <Button>Add row</Button>
                <Button>Delete row</Button>
            </Stack>
        </Box>

    )
}
export default ELDLogsTableTopBtns