import { useEffect, useState } from 'react';
import MapComponent from "./MapComponent"
import MapTable from "./MapTable"
import { observer } from 'mobx-react-lite';
import socketStore from '../../Store/socketStore';

const LiveTracking = observer(() => {
  const [filteredData, setFilteredData] = useState([]);
  const [truckCoordinates, setTruckCoordinates] = useState(null);

  useEffect(() => {
    socketStore.connect();
    return () => {
      socketStore.disconnect();
    };
  }, []);

  const updateFilteredData = (filteredData: any) => {
    setFilteredData(filteredData);
  };

  return (
    <div className="livetrackerContainer">
      <MapComponent filteredData={filteredData} truckCoordinates={truckCoordinates} />
      <MapTable updateFilteredData={updateFilteredData} setTruckCoordinates={setTruckCoordinates} />
    </div>
  )
})

export default LiveTracking;
