export interface WebUser {
    id: number
    webUserName: string
    firstName: string
    lastName: string
    groupName: string | null
    privileges: string | null
    contact: string
    reseller: boolean
}

export const webUsers = [
    {
        id: 1,
        webUserName: 'sirius',
        firstName: 'John',
        lastName: 'Cloe',
        groupName: 'sirius',
        privileges: 'fleetManager',
        contact: 'sirius@log.com',
        reseller: true
    },
    {
        id: 2,
        webUserName: 'employer',
        firstName: 'Roger',
        lastName: 'Klopp',
        groupName: 'sirius',
        privileges: 'Dispatch',
        contact: 'klopp@log.com',
        reseller: false
    }
]