import { injectStores } from '@mobx-devtools/tools';
import { makeAutoObservable } from 'mobx';
import { io, Socket } from 'socket.io-client';

class SocketStore {
    socket: Socket | null = null;
    messages: string[] = [];
    data: any = null;

    constructor() {
        makeAutoObservable(this);
    }

    connect = () => {
        this.socket = io('http://75.119.151.220:3000', {
            transports: ['websocket'],
            withCredentials: true,
            extraHeaders: {
                "my-custom-header": "abcd"
            }
        });

        this.socket.on('connect', () => {
            console.log('Connected to server');
        });

        this.socket.on('events', (data: any) => {
            this.addMessage(data);
            this.setData(data);
        });

        this.socket.on('disconnect', () => {
            console.log('Disconnected from server');
        });

        this.socket.on('message', (data: any) => {
            this.addMessage(data);
        });

        this.socket.on('error', (error: any) => {
            console.error('Socket error:', error);
        });

        this.socket.on('connect_error', (error: any) => {
            console.error('Connection error:', error);
        });
    }

    disconnect = () => {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }

    addMessage = (message: any) => {
        this.messages.push(message);
    }

    setData = (data: any) => {
        this.data = data;
    }

    sendMessage = (message: string) => {
        if (this.socket) {
            console.log('Sending message:', message);
            this.socket.emit('message', message);
        }
    }
}

const socketStore = new SocketStore();
injectStores({ socketStore });
export default socketStore;
