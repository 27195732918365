
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { Button, Group, TextInput, Box, Select, Tabs, Stack, Flex } from '@mantine/core';
import { getInitialValues, handleUpdateCompanySubmit } from './Companies.utils'
import { CreateCompanyModal, DeleteCompanyModal, UpdateCompanyModal } from './CompanyModals';
import { CompanySettings } from './Companies.types';
import states from "../../../data/USstates";
import timezones from "../../../data/timezones";

function Companies() {
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const { companies } = userData;

    const form = useForm<CompanySettings[]>({
        initialValues: getInitialValues(companies),
    });

    return (
        <>
            <div className="tableContainer">
                <Flex gap="xl"
                    justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap">
                    <h1 className="titleDashboard">
                        Company settings
                    </h1>
                    <CreateCompanyModal />
                </Flex>
                <Tabs color="teal" defaultValue={'0'}>
                    <Tabs.List>
                        {companies.map((company: CompanySettings, index: number) => (
                            <Tabs.Tab key={company.carrierName} value={String(index)}>
                                {company.carrierName}
                            </Tabs.Tab>
                        ))}
                    </Tabs.List>
                    {companies.map((company: CompanySettings, index: number) => (
                        <Tabs.Panel value={String(index)} key={company.carrierName}>
                            <Box
                                className="companies-table"
                                component="form"
                                maw={800}
                                mx="auto"
                                onSubmit={form.onSubmit(
                                    (values, _event) => { handleUpdateCompanySubmit(values, index, companies) }
                                )}
                                onReset={form.onReset}
                            >
                                <h2 className="companies-title">Company {company.carrierName} details</h2>
                                <Group position='center' align='flex-start'>
                                    <Stack w={300} align='center' justify='flex-start' className="companies-table-column" >
                                        <TextInput
                                            w={250}
                                            label="USDOT Number:"
                                            mt="sm"
                                            {...form.getInputProps(`${[index]}.dotNumber`)}
                                            disabled
                                        />
                                        <TextInput
                                            w={250}
                                            label="Contact:"
                                            mt="sm"
                                            {...form.getInputProps(`${[index]}.contact`)}
                                            disabled
                                            placeholder='temporary unavailable'
                                        />
                                        <TextInput
                                            w={250}
                                            label="City:"
                                            mt="sm"
                                            {...form.getInputProps(`${[index]}.city`)}
                                        />
                                        <TextInput
                                            w={250}
                                            label="ZIP code:"
                                            type="number"
                                            mt="sm"
                                            {...form.getInputProps(`${[index]}.zipCode`)}
                                        />
                                    </Stack>
                                    <Stack w={300} justify='flex-start' align='center' className="companies-table-column" >
                                        <TextInput
                                            w={250}
                                            label="Name:"
                                            mt="sm"
                                            {...form.getInputProps(`${[index]}.carrierName`)}
                                        />
                                        <TextInput
                                            w={250}
                                            label="Address:"
                                            mt="sm"
                                            {...form.getInputProps(`${[index]}.address`)}
                                        />
                                        <Select
                                            w={250}
                                            label="State:"
                                            placeholder="Pick one"
                                            mt="sm"
                                            data={states}
                                            {...form.getInputProps(`${[index]}.state`)}
                                            onChange={(value) => form.setFieldValue(`${[index]}.state`, value)}
                                        />
                                        <Select
                                            w={250}
                                            label="Timezone:"
                                            placeholder="Pick one"
                                            mt="sm"
                                            data={timezones}
                                            {...form.getInputProps(`${[index]}.timeZone`)}
                                            onChange={(value) => form.setFieldValue(`${[index]}.timeZone`, value)}
                                        />
                                    </Stack>
                                </Group>
                                <Group
                                    mt="xl"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: '30px',
                                        marginTop: '50px'
                                    }}>
                                    <DeleteCompanyModal index={index} company={company} />
                                    <Button
                                        size="md"
                                        variant="outline"
                                        color='red'
                                        type="reset"
                                        onClick={() =>
                                            notifications.show({
                                                title: 'Reset successful.',
                                                message: 'Initial values restored.',
                                                color: 'blue'
                                            })
                                        }
                                    >
                                        Reset to initial values
                                    </Button>
                                    <UpdateCompanyModal values={form.values} currentIndex={index} companies={companies} />
                                </Group>
                            </Box>
                        </Tabs.Panel>
                    ))}
                </Tabs>
            </div>
        </>
    );
}

export default Companies;
