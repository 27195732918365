import { Button, Group, TextInput, Box, Flex, Select, Checkbox, Paper, MultiSelect, PasswordInput, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { DateInput } from '@mantine/dates';
import { CompanySettings } from '../Companies/Companies.types';
import { CreateDeviceModalProps, Device, DeviceCreate } from './Devices.types';
import states from '../../../data/USstates';
import { handleCreateDeviceSubmit } from './Devices.utils';

function DeviceCreateForm({ initialValues }: CreateDeviceModalProps) {
    const [visible, { toggle }] = useDisclosure(false);

    const form = useForm<DeviceCreate>({
        initialValues: initialValues,
        validate: {
        }
    });

    const handleSelectChange = (fieldName: string, value: boolean) => {
        form.setFieldValue(fieldName, value);
    };

    return (
        <div className="tableContainer">
            <Box
                className="devices-table"
                component="form"
                mt={30}
                maw={1000}
                mx="auto"
                onSubmit={form.onSubmit((values: DeviceCreate) => { handleCreateDeviceSubmit(values) })}
                onReset={form.onReset}
            >
                <Flex
                    direction={{ base: 'column', sm: 'row' }}
                    gap={{ base: 'sm', sm: 'lg' }}
                    justify={{ sm: 'center' }}
                >
                    <Paper shadow="xs" p="xl" withBorder >
                        <h3>Devices info</h3>
                        <Flex
                            direction={{ base: 'column', xs: 'row' }}
                            gap={{ base: 'sm', sm: 'lg' }}
                            justify={{ sm: 'center' }}
                            align={{ base: 'start' }}
                            maw={450}
                        >
                            <Group className="devices-table-column" w={250}>
                                <TextInput label="IMEI:" withAsterisk placeholder='Device`s first name'  {...form.getInputProps('IMEI')} />
                                <TextInput label="Serial Number:" withAsterisk placeholder='Device`s last name'  {...form.getInputProps('SerialNumber')} />
                                <TextInput label="MSISDN:" withAsterisk placeholder='Devices email'  {...form.getInputProps('MSISDN')} />
                            </Group>
                        </Flex>
                    </Paper>

                </Flex>
                <Group style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop: '20px' }}>
                    <Button size="md" color='red' onClick={() => modals.closeAll()}                    >
                        Cancel
                    </Button>
                    <Button size="md" color='red' onClick={() => form.reset()} variant="outline">
                        Reset
                    </Button>
                    <Button size="md" color='green' type="submit" onClick={() => console.log(form.values)}>
                        Save and submit
                    </Button>
                </Group>
            </Box>
        </div>
    );
}

export default DeviceCreateForm;
