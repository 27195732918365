import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import truckDRVIconUrl from '../../assets/svgIcons/truckDRVIcon.svg';
import truckOFFIconUrl from '../../assets/svgIcons/truckOFFIcon.svg';
import truckSBIconUrl from '../../assets/svgIcons/truckSBIcon.svg';
import truckONIconUrl from '../../assets/svgIcons/truckONIcon.svg';
import truckTESTIconUrl from '../../assets/svgIcons/truckTESTIcon.svg';
import socketStore from '../../Store/socketStore';
import { observer } from 'mobx-react-lite';
import LiveTrackingDrawer from './LiveTrackingDrawer';

type TruckType = {
  id: string;
  latitude: number;
  longitude: number;
  currentStatus: string;
  FirstName: string;
  LastName: string;
  time?: string;
  direction?: number,
  LicensePlateNumber?: string
};

type MapMarkersProps = {
  filteredData: TruckType[];
};

const MapMarkers: React.FC<MapMarkersProps> = observer(({ filteredData }) => {
  const [trucksData, setTrucksData] = useState(filteredData);
  const [selectedTruck, setSelectedTruck] = useState<TruckType | null>(null);
  const [drawerOpened, setDrawerOpened] = useState(false);

  const handleMarkerClick = (truck: TruckType) => {
    setSelectedTruck(truck);
    setDrawerOpened(true);
  };

  const closeDrawer = () => {
    setDrawerOpened(false);
    setSelectedTruck(null);
  };

  const updateTruckStatus = (id: string, newStatus: string) => {
    setTrucksData(prevData => prevData.map(truck =>
      truck.id === id ? { ...truck, currentStatus: newStatus } : truck
    ));
  };
  const getTruckIcon = (status: string, direction?: number) => {
    const driverIconStyle = `transform: rotate(${direction}deg); border-style: none; vertical-align: middle; width: 25px; height: 25px`

    switch (status) {
      case 'driving':
        const htmlContent = `<img src="${truckDRVIconUrl}" style="${driverIconStyle}"/>`;
        return L.divIcon({
          html: htmlContent,
          iconSize: [25, 25],
          iconAnchor: [12.5, 12.5],
          popupAnchor: [0, -10],
        });
      case 'OFF':
        return L.icon({
          iconUrl: truckOFFIconUrl,
          iconSize: [25, 25],
          iconAnchor: [12.5, 12.5],
          popupAnchor: [0, -10],
        });
      case 'on_duty':
        return L.icon({
          iconUrl: truckONIconUrl,
          iconSize: [25, 25],
          iconAnchor: [12.5, 12.5],
          popupAnchor: [0, -10],
        });
      case 'SB':
        return L.icon({
          iconUrl: truckSBIconUrl,
          iconSize: [25, 25],
          iconAnchor: [12.5, 12.5],
          popupAnchor: [0, -10],
        });
      case 'test':
        return L.icon({
          iconUrl: truckTESTIconUrl,
          iconSize: [25, 25],
          iconAnchor: [12.5, 12.5],
          popupAnchor: [0, -10],
        });
      default:
        return L.icon({
          iconUrl: truckTESTIconUrl,
          iconSize: [25, 25],
          iconAnchor: [12.5, 12.5],
          popupAnchor: [0, -10],
        });
    }
  };

  // useEffect(() => {
  //   socketStore.connect();

  //   return () => {
  //     socketStore.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    if (socketStore.data) {
      setTrucksData(prevData =>
        prevData.map(truck => {
          console.log('truck', truck, (truck.id === socketStore.data.driverid));

          if (truck.id === socketStore.data.driverid) {
            return {
              ...truck,
              latitude: socketStore.data.latitude,
              longitude: socketStore.data.longitude,
              currentStatus: socketStore.data.event,
              direction: Number(socketStore.data.angle) || 0
            };
          }
          return truck;
        })
      );
    }
  }, [socketStore.data]);

  useEffect(() => {
    setTrucksData(filteredData)
  }, [filteredData]);

  return (
    <>
      <MarkerClusterGroup chunkedLoading>
        {trucksData.map((truck: TruckType) => (
          <Marker
            key={truck.id}
            position={[truck.latitude, truck.longitude]}
            icon={getTruckIcon(truck.currentStatus, truck.direction)}
            eventHandlers={{
              click: () => handleMarkerClick(truck),
            }}
          >
            <Popup>
              <div style={{ display: 'flex', flexDirection: 'column' , alignItems: 'center', justifyContent: 'center'}}>
                <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>{`${truck.LastName} ${truck.FirstName}` || 'none'}</div>
                <div>{truck.LicensePlateNumber}</div>
                <div>{truck.currentStatus}</div>
              </div>
            </Popup>
          </Marker >
        ))}
      </MarkerClusterGroup >
      <LiveTrackingDrawer
        truckData={selectedTruck}
        closeDrawer={closeDrawer}
        opened={drawerOpened}
        updateTruckStatus={updateTruckStatus}
      />
    </>
  )
})

export default MapMarkers