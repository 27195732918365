import { useEffect, useMemo, useState } from "react";
import { DatePickerInput } from '@mantine/dates';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from "mantine-react-table";
import { Paper, Box, Button, Select, Alert } from "@mantine/core";
import { CrossIcon, OkIcon } from "../../assets/icons";
import { IconAlertCircle } from '@tabler/icons-react';
import { useNavigate } from "react-router-dom";

type Person = {
  id: String,
  date: string;
  driver: string;
  coDriver: string;
  shippingDocs: string;
  dvir: boolean;
  signed: boolean;
};

const Example = () => {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]); //should be memoized or stable
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<Person[]>([]);
  const [uniqueDrivers, setUniqueDrivers] = useState<string[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleLoadRecords = () => {
    if (!value[0] || !value[1] || !selectedDriver) {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    } else {
      setCustomAlertMessage("Records loaded successfully!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);

      console.log(`Start Date:, ${value[0]}, End Date: ${value[1]}, Driver: ${selectedDriver}`);
    }
  };

  const handlePrint = () => {
    if (!value[0] || !value[1] || !selectedDriver) {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    } else {
      setCustomAlertMessage("Records printed successfully!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);

      console.log(`Start Date:, ${value[0]}, End Date: ${value[1]}, Driver: ${selectedDriver}`);
    }
  };

  const handleSendFile = () => {
    if (!value[0] || !value[1] || !selectedDriver) {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    } else {
      setCustomAlertMessage("File sended successfully!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);

      console.log(`Start Date:, ${value[0]}, End Date: ${value[1]}, Driver: ${selectedDriver}`);
    }
  };

  const handleSendEmail = () => {
    if (!value[0] || !value[1] || !selectedDriver) {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
    } else {
      setCustomAlertMessage("Email sent successfully!");
      setShowSuccessAlert(true);
      setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);

      console.log(`Start Date:, ${value[0]}, End Date: ${value[1]}, Driver: ${selectedDriver}`);
    }
  };

  const goToUsersDiverLog = (row: any) => {
    const date = row.original.dateForLink;
    const id = row.original.id;

    const url = `/dashboard/driver-log?id=${id}&date=${date}`;

    navigate(url);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/driverlogsList");
        if (response.ok) {
          const jsonData = await response.json();

          const transformedData = jsonData.map((item: any) => {
            const month = parseInt(item.date.substring(0, 2), 10);
            const day = parseInt(item.date.substring(2, 4), 10);
            const year = parseInt(`20${item.date.substring(4)}`, 10);
            const date = new Date(year, month - 1, day);

            const isoDateString = date.toISOString();

            return {
              ...item,
              date: isoDateString,
              dateForLink: item.date
            };
          });

          setData(transformedData);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);


  useEffect(() => {
    const uniqueDriverNames = Array.from(new Set(data.map((item) => item.driver)));
    setUniqueDrivers(uniqueDriverNames);
  }, [data]);

  const handleAlertClose = () => {
    setShowErrorAlert(false);
    setShowSuccessAlert(false);
  };

  const columns = useMemo<MRT_ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: "id",
        header: "id",
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.date),
        id: "date",
        header: "Date",
        filterVariant: 'date-range',
        Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString('en-US')
      },
      {
        accessorKey: "driver",
        header: "Driver",
      },
      {
        accessorKey: "coDriver",
        header: "CoDriver",
      },
      {
        accessorKey: "shippingDocs",
        header: "Shipping Docs",
      },
      {
        accessorKey: "dvir",
        header: "DVIR",
        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "center",
              backgroundColor: cell.getValue()
                ? theme.colors.green[9]
                : theme.colors.red[9],
              borderRadius: "20px",
              maxWidth: "40px",
              padding: "10px",
              textAlign: "center",
              ColorInput: "white"
            })}
          >
            {cell.getValue() ? <OkIcon /> : <CrossIcon />}
          </Box>
        ),
      },
      {
        accessorKey: "signed",
        header: "Signed",
        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "center",
              backgroundColor: cell.getValue()
                ? theme.colors.green[9]
                : theme.colors.red[9],
              borderRadius: "20px",
              maxWidth: "40px",
              padding: "10px",
              textAlign: "center",
              ColorInput: "white"
            })}
          >
            {cell.getValue() ? <OkIcon /> : <CrossIcon />}
          </Box>
        ),
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    initialState: {
      density: 'xs',
      columnVisibility: { id: false },
      sorting: [{ id: 'date', desc: true }]
    },
    state: { isLoading },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        goToUsersDiverLog(row)
      },
      sx: {
        cursor: 'pointer',
      },
    }),
  });

  return (
    <div className="tableContainer">
      <h1 className="titleDashboard">Driver Logs</h1>
      {showErrorAlert && (
        <Alert
          title="Error!"
          color="red"
          withCloseButton
          closeButtonLabel="Close alert"
          onClose={handleAlertClose}
        >
          Please select both start and end dates, and a driver before proceeding.
        </Alert>
      )}
      {showSuccessAlert && (
        <Alert
          icon={<IconAlertCircle />}
          title="Great!"
          color="green"
          withCloseButton
          closeButtonLabel="Close alert"
          onClose={handleAlertClose}
        >
          {customAlertMessage}
        </Alert>
      )}

      <div className="driverLogBtns">
        <div className="driverLogBtnsBlock">
          <DatePickerInput
            clearable
            maxDate={new Date()}
            allowSingleDateInRange
            className="datePicker"
            type="range"
            label="Pick dates range"
            placeholder="Pick dates range"
            value={value}
            onChange={setValue}
            mx="auto"
            maw={400}
          />
          <Select
            className="driverSelect"
            required
            label="Select driver"
            placeholder="Pick one"
            data={uniqueDrivers}
            value={selectedDriver}
            onChange={(value) => setSelectedDriver(value)}
          />
          <Button className="btnLogs btnRecords" onClick={handleLoadRecords}>
            Load records
          </Button>
          <Button className="btnLogs btnPrint" onClick={handlePrint}>
            Print
          </Button>
          <Button className="btnLogs btnSendFile" onClick={handleSendFile}>
            Send file
          </Button>
          <Button className="btnLogs btnSendEmail" onClick={handleSendEmail}>
            Send file by email
          </Button>
        </div>
      </div>
      <Paper>
        <MantineReactTable table={table} />
      </Paper>
    </div>
  );
};

export default Example;
