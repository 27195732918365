import { useState } from 'react';
import { Modal, Button, Checkbox, Select, Text, TextInput, Group } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { IconShare3 } from '@tabler/icons-react';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';

export default function LiveTrackingShareModal({ truckData }: any) {
    const [opened, { open, close }] = useDisclosure(false);
    const [showDestination, setShowDestination] = useState<boolean>(false);
    const [showExpiration, setShowExpiration] = useState<boolean>(false);

    const form = useForm({
        initialValues: {
            title: '',
            destination: null,
            expiration: null,
        },

        validate: {
            title: (value) => (value.length < 2 ? 'Title must have at least 2 letters' : null),
            destination: (value) => (showDestination && value === null ? 'please pick destination or deselect the checkbox' : null),
            expiration: (value) => (showExpiration && value === null ? 'please pick expiration time or deselect the checkbox' : null),
        },
    });
    
    const handleSubmit = (values: any) => {
        console.log(values);

        form.reset()
        setShowDestination(false);
        setShowExpiration(false);
        close(); 
    };

    return (
        <>
            <Modal opened={opened} onClose={close} title="Location sharing">
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Text size="sm" color='dimmed' pb={10}>
                        You can share your truck location with your collaborators
                    </Text>
                    <TextInput
                        label="Title"
                        pb={25}
                        placeholder="please name your sharing page"
                        required
                        {...form.getInputProps('title')} />
                    <Checkbox pb={10}
                        label="Show destination"
                        onChange={(event) => setShowDestination(event.currentTarget.checked)}
                    />
                    <Select pb={30}
                        label="Destination"
                        placeholder="Pick value"
                        data={['Moon', 'Uzhhorod', 'Miami', 'Griffindor', 'Hogwarts', 'Prison', 'Library', 'Prisons']}
                        clearable
                        maxDropdownHeight={200}
                        disabled={!showDestination}
                        {...form.getInputProps('destination')}
                    />
                    <Checkbox pb={10}
                        label="Apply expiration date and time"
                        onChange={(event) => setShowExpiration(event.currentTarget.checked)}
                    />

                    <DateTimePicker // TODO: check if time is UTC
                        clearable
                        label="Pick date and time"
                        placeholder="Pick date and time" pb={10}
                        disabled={!showExpiration}
                        {...form.getInputProps('expiration')}
                    />
                    <Group mt="md">
                        <Button type="reset" onClick={close}>Cancel</Button>
                        <Button type="submit">Submit</Button>
                    </Group>
                </form>
            </Modal>

            <Button rightIcon={<IconShare3 size={14} />} onClick={open}>Share truck location</Button>
        </>
    );
}