import { modals } from "@mantine/modals";
import { CreateVehicleModalProps, DeleteVehicleModalProps, UpdateVehicleModalProps } from "./Vehicles.types";
import CreateVehicleForm from "./CreateVehicleForm";
import { Button, Menu, Text, TextInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { handleDeleteVehicleSubmit } from "./Vehicles.utils";
import React from "react";
import UpdateVehicleForm from "./UpdateVehicleForm";

export const CreateVehicleModal: React.FC<CreateVehicleModalProps> = () => {
    const openCreateModal = () =>
        modals.open({
            title: `Add vehicle`,
            centered: true,
            size: 'auto',
            children: (<CreateVehicleForm />),
        });

    return <Button
        onClick={openCreateModal}
        color="green"
        size='sm'
        mr={"lg"}>
        + Add vehicle
    </Button>;
}

export const DeleteVehicleModal: React.FC<DeleteVehicleModalProps> = ({ selectedTruck }) => {
    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: `Delete Truck`,
            centered: true,
            children: (
                <>
                    <Text size="sm" mb={20}>
                        Are you sure you want to delete this vehicle?
                        If you are sure, please type <Text span c="blue" inherit>'delete'</Text> into the box.
                    </Text>
                    <TextInput
                        placeholder="Enter confirmation word"
                        title="Enter 'delete' to confirm"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => localStorage.setItem('VehicleDeleteConfirmationWord', event.currentTarget.value)}
                    />
                </>
            ),
            labels: { confirm: 'Delete vehicle', cancel: "No don't delete it" },
            confirmProps: { color: 'red' },
            onCancel: () => {
                notifications.show({
                    title: 'Operation canceled',
                    message: `Deletion of canceled.`,
                });
            },
            onConfirm: () => {
                const confirmationWord = localStorage.getItem('VehicleDeleteConfirmationWord');
                if (confirmationWord === 'delete') {
                    handleDeleteVehicleSubmit(selectedTruck);
                    localStorage.removeItem('VehicleDeleteConfirmationWord');
                } else {
                    console.log(confirmationWord)
                    notifications.show({
                        title: 'Incorrect confirmation word',
                        message: `Please type 'delete' to confirm deletion.`,
                        color: 'red',
                    });
                }
            },
        });

    return (
        <Menu.Item style={{ textAlign: 'center' }} onClick={openDeleteModal}>
            Delete truck
        </Menu.Item>
    );
};

export const UpdateVehicleModal: React.FC<UpdateVehicleModalProps> = ({ selectedTruck, allDrivers }) => {
    const openUpdateModal = () =>
        modals.open({
            title: `Update vehicle`,
            centered: true,
            size: 'auto',
            children: (<UpdateVehicleForm selectedTruck={selectedTruck} allDrivers={allDrivers}/>),
        });

    return <Menu.Item style={{ textAlign: 'center' }} onClick={openUpdateModal}>
    Edit
</Menu.Item>
}