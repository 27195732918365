import { Button } from "@mantine/core";
import { IconCircleCheckFilled } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useLocation, Location, useNavigate } from "react-router-dom";
import companyStore from "../Store/companyStore";
import { CompanySettings } from "./Administration/Companies/Companies.types";
import api from "./Login/Authentication";
import { observer } from 'mobx-react-lite';

type PageNames = Record<string, string>;

const Header = observer(() => {
  const [title, setTitle] = useState<string>('');
  const location: Location = useLocation();
  const [activeCompanyId, setActiveCompanyId] = useState<number | null>(companyStore.getActiveCompany()?.id || null);
  
  const userData = JSON.parse(localStorage.getItem('userData') || '{}');
  const navigate = useNavigate();

  const pageNames: PageNames = {
    "/dashboard/welcome": "Welcome",
    "/dashboard/driver-logs": "Driver Logs Table",
    "/dashboard/driver-log": "Driver Log",
    "/dashboard/live-tracking": "Live Tracking",
    "/dashboard/inspection-logs": "Inspection Logs",
    "/dashboard/companies": "Companies",
    "/dashboard/drivers": "Drivers",
    "/dashboard/drivers/driver/:id": "Drivers Page",
    "/dashboard/drivers/driver/add-driver": "Create Driver",
    "/dashboard/vehicles": "Vehicles",
    "/dashboard/vehicles/vehicle/:id": "Vehicle Page",
    "/dashboard/vehicles/vehicle/add-vehicle": "Create Vehicle",
    "/dashboard/web-users": "Web Users",
    "/dashboard/web-users/web-user/:id": "Web User Page",
    "/dashboard/web-users/web-user/add-web-user": "Create Web User",
    "/dashboard/support/ELDLog": "ELD Log",
  }

  function handleLogout() {
    const cookies = document.cookie.split("; ");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    
    sessionStorage.removeItem('refreshToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('activeCompany');
    
    companyStore.setActiveCompany(null);
    

    navigate('/login', { replace: true });
  }

  useEffect(() => {
    const currentTitle = pageNames[location.pathname];
    setTitle(currentTitle || "Page");
  }, [location]);

  const handleCompanySwitch = async (company: CompanySettings) => {
    companyStore.setActiveCompany(company);
    await api.get(`/api/v1/company/${company.id}`) // select company endpoint
    setActiveCompanyId(company.id);
  };

  return (
    <div className="header">
      <div className="headerItem headerItemLeft">
        <div className="headerPageTitle">{title}</div>
      </div>
      <div className="headerItem headerItemCenter" >
        {userData.companies && userData.companies.map((company: CompanySettings) => (
          <Button
            key={company.id}
            className="companyItem"
            variant={activeCompanyId === company.id ? "filled" : "light"}
            color={activeCompanyId === company.id ? "lime" : "gray"}
            size="xs"
            radius="xl"
            leftIcon={activeCompanyId === company.id ? <IconCircleCheckFilled size={14}/> : null}
            onClick={() => handleCompanySwitch(company)}>
            {company.carrierName}
          </Button>
        ))}
      </div>
      <div className="headerItem headerItemRight">
        <Button className="logoutBtn roundBtn" onClick={handleLogout}>Log out</Button>
        <Button className="profileBtn roundBtn">Hello, {userData.user.firstName}!</Button>
      </div>
    </div>
  );
});

export default Header;