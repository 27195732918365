import { Link } from 'react-router-dom';
import { Box, Button, Flex, Menu } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { DeviceTable } from './Devices.types';
import { getDevices } from './Devices.utils';
import { CreateDeviceModal,  DeleteDeviceModal,  EditDeviceModal,  } from './DevicesModals';
import { CompanySettings } from '../Companies/Companies.types';
import { initialValues } from './Device.constants';


function Devices() {
  const [data, setData] = useState<DeviceTable[]>([])
  localStorage.removeItem('DeviceDeleteConfirmationWord');


  useEffect(() => {
    async function fetchData() {
      try {
        const devicesData: DeviceTable[] = await getDevices();
        console.log('devicesData', devicesData);
    
        setData(devicesData);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    }
    
    fetchData();
  }, [getDevices]);


  const columns = useMemo<MRT_ColumnDef<DeviceTable>[]>(
    () => [
      {
        accessorKey: 'imei',
        header: 'IMEI',
      },
      {
        accessorKey: 'serialnumber',
        header: 'Serial number',
      },
      {
        accessorKey: 'msisdn',
        header: 'MSISDN',
      },
      {
        accessorKey: 'assignedcompanyid',
        header: 'Company',
      },
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      density: 'xs',
      columnVisibility: {
        isApproved: false,
        isAllowPersonalConv: false
      }
    },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <CreateDeviceModal initialValues={initialValues} />
      </div>
    ),
    renderRowActionMenuItems: ({ row }) => (
      <Flex gap="xs"
        justify="center"
        align="flex-end"
        direction="column"
        wrap="wrap">
        <EditDeviceModal selectedDevice={row.original} />
        <DeleteDeviceModal selectedDevice={row.original} />
      </Flex>
    )
  });

  return (
    <div style={{ margin: '20px' }}>
      <MantineReactTable table={table} />
    </div>
  )
};

export default Devices;

