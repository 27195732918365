import { selectOptions } from "./ELDLog.types";

export const statusOptions: selectOptions = ['ON', 'SB', 'OFF', 'DRV'];
export const isActiveOptions: selectOptions = ['1', '2', '3', '4'];
export const originOptions: selectOptions = ['1', '2', '3', '4'];
export const noteOptions: selectOptions = [
'Pick Up',
'Delivery',
'Fuel',
'Inspection',
'PTI',
'Break',
'Sleep',
'Personal',
];