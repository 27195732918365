import styles from './LiveTrackerDrawer.module.css';

const LiveTrackingDrawerLoadDetails = ({truckData}:any) => {
  return (
    <div className={styles.liveTrackingDrawerDiagnostics}>
      <div className={styles.liveTrackingDrawerDiagnosticsItem}>
        <div className={styles.liveTrackingDrawerDiagnosticsTitle}>Trailer #</div>
        <div className={styles.liveTrackingDrawerDiagnosticsStatus}>{truckData.Trailer || 'N/A'}</div>
      </div>
      <div className={styles.liveTrackingDrawerDiagnosticsItem}>
        <div className={styles.liveTrackingDrawerDiagnosticsTitle}>Load #</div>
        <div className={styles.liveTrackingDrawerDiagnosticsStatus}>{truckData.Load || 'N/A'}</div>
      </div>
    </div>
  )
}
export default LiveTrackingDrawerLoadDetails