import { Alert, Button, Select, TransferList, TransferListData } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates"
import { IconAlertCircle } from "@tabler/icons-react";
import { useState } from "react";
import { drivers, Driver } from "../../mockDB/drivers";

const companies = drivers.map((driver) => driver.companies)
const uniqueCompaniesSet = new Set(companies.flat());
const allDriversOption = "All Drivers";
const uniqueCompanies = [allDriversOption, ...Array.from(uniqueCompaniesSet)];
const driverNames = drivers.map((driver) => `${driver.firstName} ${driver.lastName}`)
const driverObjects: TransferListData = [driverNames.map((name) => ({
    value: name,
    label: name
})), []];

const InspectionLogs = () => {
    const [data, setData] = useState<TransferListData>(driverObjects);
    const [selectedDriver, setSelectedDriver] = useState<string[]>([])
    const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);  //should be memoized or stable
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [customAlertMessage, setCustomAlertMessage] = useState("");
    const [selectedCompany, setSelectedCompany] = useState("");
    const [filteredDrivers, setFilteredDrivers] = useState<Driver[]>([])

    const handleDataChange = (newData: any) => {
        const selectedDrivers = newData.map((item: { value: any; }) => item.value);
        console.log(selectedDrivers);

        setSelectedDriver(selectedDrivers);
        setData(newData);
    };

    const handleCompanyChange = (company: any) => {
        setSelectedCompany(company);

        if (company === allDriversOption) {
            const allDriverNames = drivers.map((driver) => `${driver.firstName} ${driver.lastName}`);
            const newData: TransferListData = [allDriverNames.map((name) => ({
                value: name,
                label: name
            })), []];
            setData(newData);
        } else {
            const filteredDrivers = drivers.filter((driver) => driver.companies.includes(company));
            setFilteredDrivers(filteredDrivers);

            const filteredDriverNames = filteredDrivers.map((driver) => `${driver.firstName} ${driver.lastName}`);
            const newData: TransferListData = [filteredDriverNames.map((name) => ({
                value: name,
                label: name
            })), []];
            setData(newData);
        }
    };

    const handleSendFile = () => {
        if (!value[0] || !value[1] || !selectedDriver) {
            setShowErrorAlert(true);
            setTimeout(() => {
                setShowErrorAlert(false);
            }, 3000);
        } else {
            setCustomAlertMessage("File sended successfully!");
            setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
            }, 3000);

            console.log(`Start Date:, ${value[0]}, End Date: ${value[1]}, Driver: ${selectedDriver}`);
        }
    };

    const handleSendEmail = () => {
        if (!value[0] || !value[1] || !selectedDriver) {
            setShowErrorAlert(true);
            setTimeout(() => {
                setShowErrorAlert(false);
            }, 3000);
        } else {
            setCustomAlertMessage("Email sent successfully!");
            setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
            }, 3000);

            console.log(`Start Date:, ${value[0]}, End Date: ${value[1]}, Driver: ${selectedDriver}`);
        }
    };

    const handleAlertClose = () => {
        setShowErrorAlert(false);
        setShowSuccessAlert(false);
    };

    return (
        <div>
            <div className="tableContainer">
                <h1 className="titleDashboard">Inspection Logs</h1>
                {showErrorAlert && (
                    <Alert
                        title="Error!"
                        color="red"
                        withCloseButton
                        closeButtonLabel="Close alert"
                        onClose={handleAlertClose}
                    >
                        Please select both start and end dates, and a driver before proceeding.
                    </Alert>
                )}
                {showSuccessAlert && (
                    <Alert
                        icon={<IconAlertCircle />}
                        title="Great!"
                        color="green"
                        withCloseButton
                        closeButtonLabel="Close alert"
                        onClose={handleAlertClose}
                    >
                        {customAlertMessage}
                    </Alert>
                )}

                <div className="driverLogBtns">
                    <div className="driverLogBtnsBlock">
                        <Select
                            className="driverSelect"
                            label="Select company"
                            placeholder="Pick value"
                            data={uniqueCompanies}
                            onChange={(value) => handleCompanyChange(value)}
                        />
                        <DatePickerInput
                            allowSingleDateInRange
                            className="datePicker"
                            type="range"
                            label="Pick dates range"
                            placeholder="Pick dates range"
                            value={value}
                            onChange={setValue}
                            mx="auto"
                            maw={500}
                        />
                        <Button className="btnLogs btnSendFile" onClick={handleSendFile}>
                            Send file
                        </Button>
                        <Button className="btnLogs btnSendEmail" onClick={handleSendEmail}>
                            Send file by email
                        </Button>
                        <Button color="green" className="btnLogs btnSendEmail" onClick={handleSendEmail}>
                            Download file
                        </Button>
                    </div>
                </div>
                <div className="inspectionLogContainer">
                    <TransferList
                        className="inspectionLogTransferList"
                        value={data}
                        onChange={handleDataChange}
                        searchPlaceholder="Search..."
                        nothingFound="Nothing here"
                        titles={['Eld Drivers:', 'Drivers for inspection:']}
                        breakpoint="sm"
                        maw={700}
                        listHeight={400}
                    />
                </div>
            </div>
        </div>
    )
}

export default InspectionLogs