import axios from 'axios';

async function fetchCities() {
    try {
        const response = await axios.get('http://localhost:3000/cities');
        return response.data;
    } catch (error) {
        console.error('Error fetching cities:',error);
        return [];
    }
}

function dateFormatter(date) {
    const month = Math.floor(date / 10000);
    const day = Math.floor((date % 10000) / 100);
    const year = date % 100 + 2000;

    return `${month}/${day}/${year}`;
}

function calculateDuration(start,end) {
    const diff = end - start;
    const hours = Math.floor(diff / 3600000);
    const minutes = Math.floor((diff % 3600000) / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);
    return `${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
}

function getEventType(eventType,eventCode) {
    const codes = {
        '1,1': 'OFF',
        '1,2': 'SB',
        '1,3': 'DRV',
        '1,4': 'ON',
        '3,1': 'PC',
        '3,2': 'YM'
    };
    return codes[eventType + ',' + eventCode] || 'Unknown';
}

function convertToUTCMilliseconds(dateStr,timeStr) {
    const year = parseInt("20" + dateStr.substring(4,6),10);
    const month = parseInt(dateStr.substring(0,2),10) - 1;
    const day = parseInt(dateStr.substring(2,4),10);
    const hour = parseInt(timeStr.substring(0,2),10);
    const minute = parseInt(timeStr.substring(2,4),10);
    const second = parseInt(timeStr.substring(4,6),10);

    return Date.UTC(year,month,day,hour,minute,second);
}

export function parseELDDataToApexFormat(eldLog) {
    const apexData = [];
    const durationByStatus = { 'OFF': 0,'SB': 0,'DRV': 0,'ON': 0 };
    const sortOrder = { 'OFF': 1,'SB': 2,'DRV': 3,'ON': 4 };
    const lines = eldLog.driverLog.split('\n');
    let previousEventTime = null;
    let previousEventType = null;

    lines.forEach((line,index) => {
        const parts = line.split(',');
        if (parts.length > 5) {
            const eventTypeCode = parts[3] + ',' + parts[4];
            const dateStr = parts[5];
            const timeStr = parts[6];
            const dateTime = convertToUTCMilliseconds(dateStr,timeStr);

            if (['1,1','1,2','1,3','1,4','3,1','3,2'].includes(eventTypeCode)) {
                if (previousEventTime !== null && previousEventType !== null) {
                    const duration = dateTime - previousEventTime;
                    const effectiveType = ['3,1','3,2'].includes(eventTypeCode) ? 'OFF' : previousEventType;
                    durationByStatus[effectiveType] += duration;

                    const eventData = {
                        x: previousEventType,
                        y: [previousEventTime,dateTime]
                    };

                    if (previousEventType === 'PC' || previousEventType === 'YM') {
                        eventData.name = previousEventType;
                        eventData.x = "OFF";
                        eventData.fillColor = previousEventType === 'PC' ? '#a6ac47' : '#3ace34';
                    }

                    apexData.push(eventData);
                }
                previousEventTime = dateTime;
                previousEventType = getEventType(parts[3],parts[4]);
            }
        }
    });

    const firstEvent = lines.find(line => {
        const parts = line.split(',');
        return parts.length > 5 && ['1,1','1,2','1,3','1,4','3,1','3,2'].includes(parts[3] + ',' + parts[4]);
    });

    let firstEventTime = null;
    if (firstEvent) {
        const parts = firstEvent.split(',');
        const dateStr = parts[5];
        const timeStr = parts[6];
        firstEventTime = convertToUTCMilliseconds(dateStr,timeStr);
    }

    ['OFF','SB','DRV','ON'].forEach(status => {
        const yValue = firstEventTime !== null ? [firstEventTime,firstEventTime] : [0,0];
        apexData.push({
            x: status,
            y: yValue
        });
    });


    if (previousEventTime !== null && previousEventType !== null) {
        const lastEventDate = new Date(previousEventTime);
        // lastEventDate.setUTCHours(24,0o0,0o0);
        const duration = lastEventDate.getTime() - previousEventTime;
        const effectiveType = ['3,1','3,2'].includes(previousEventType) ? 'OFF' : previousEventType;
        durationByStatus[effectiveType] += duration;

        const lastEventData = {
            x: previousEventType,
            y: [previousEventTime,lastEventDate.getTime()]
        };

        if (previousEventType === 'PC' || previousEventType === 'YM') {
            lastEventData.name = previousEventType;
            lastEventData.x = "OFF";
            lastEventData.fillColor = previousEventType === 'PC' ? '#e07781' : '#yourColorForYM';
        }

        apexData.push(lastEventData);
    }

    apexData.sort((a,b) => sortOrder[a.x] - sortOrder[b.x]);

    const sortedDurations = [];
    Object.keys(sortOrder).forEach(status => {
        if (durationByStatus[status] !== undefined) {
            sortedDurations.push({
                status: status,
                duration: durationByStatus[status]
            });
        }
    });

    return { apexData,durationByStatus: sortedDurations };
}


export async function parseELDDataToTableFormat(eldLog) {
    const cities = await fetchCities();
    const tableData = [];
    const lines = eldLog.driverLog.split('\n');
    const validEventTypes = ['1,1','1,2','1,3','1,4','3,1','3,2'];
    let events = [];

    lines.forEach(line => {
        const parts = line.split(',');
        if (parts.length > 5) {
            const eventTypeCode = parts[3] + ',' + parts[4];
            if (validEventTypes.includes(eventTypeCode)) {
                const dateStr = parts[5];
                const timeStr = parts[6];
                const eventDateTime = convertToUTCMilliseconds(dateStr,timeStr);
                events.push({ dateTime: eventDateTime,line });
            }
        }
    });

    for (let i = 0; i < events.length; i++) {
        const parts = events[i].line.split(',');
        const currentEventTime = events[i].dateTime;
        let nextEventTime;

        if (i < events.length - 1) {
            nextEventTime = events[i + 1].dateTime;
        } else {
            nextEventTime = events[i].dateTime;
        }

        const duration = calculateDuration(currentEventTime,nextEventTime);


        const latitude = parseFloat(parts[9]);
        const longitude = parseFloat(parts[10]);

        const odometer = 0; // Assuming odometer is not changing
        const engineHours = 0; // Assuming engineHours is not changing

        const currentLocation = { lat: latitude,lon: longitude };
        const result = findClosestCity(currentLocation.lat,currentLocation.lon,cities);

        const nearestCity = `${result.distance.toFixed(2)} mi, ${result.direction} ${result.closestCity.state_id} ${result.closestCity.city}`;

        const rowData = {
            seqNumber: parts[0].trim(),
            status: getEventType(parts[3],parts[4]),
            vehicle: eldLog.truck.unit,
            date: dateFormatter(parts[5]),
            startTime: new Date(events[i].dateTime).toUTCString().split(' ')[4],
            odometer: parseFloat(odometer),
            engineHours: parseFloat(engineHours),
            duration,
            distance: 0,
            location: (<a
                href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit',textDecoration: 'none' }}
            >
                {nearestCity}
            </a>),
            latitude: parts[9],
            longitude: parts[10],
            elapsedEngineHours: 0,
            elapsedOdometer: 0,
            isActiveStatus: parts[1],
            origin: parts[2],
            note: '',
            totalEngineHours: 0,
            isCreating: true,
        };

        tableData.push(rowData);
    }

    return tableData;
}

function toRad(Value) {
    return Value * Math.PI / 180;
}

function isSameDay(date1,date2) {
    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
}

function findClosestCity(lat,lon,cities) {
    let closestCity = null;
    let minDist = Number.MAX_VALUE;
    let bearing = null;

    cities.forEach(city => {
        const dist = calculateDistance(lat,lon,city.lat,city.lng);
        if (dist < minDist) {
            closestCity = city;
            minDist = dist;
            bearing = calculateBearing(lat,lon,city.lat,city.lng);
        }
    });

    return { closestCity,distance: minDist,direction: getCardinalDirection(bearing) };
}

function calculateDistance(lat1,lon1,lat2,lon2) {
    const R = 3958.8; // Earth radius in miles
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a),Math.sqrt(1 - a));
    return R * c;
}

function calculateBearing(lat1,lon1,lat2,lon2) {
    const dLon = (lon1 - lon2) * Math.PI / 180;
    const y = Math.sin(dLon) * Math.cos(lat1 * Math.PI / 180);
    const x = Math.cos(lat2 * Math.PI / 180) * Math.sin(lat1 * Math.PI / 180) -
        Math.sin(lat2 * Math.PI / 180) * Math.cos(lat1 * Math.PI / 180) * Math.cos(dLon);
    const brng = Math.atan2(y,x) * 180 / Math.PI;
    return (brng + 360) % 360;
}

function getCardinalDirection(angle) {
    const directions = ['N','NNE','NE','ENE',
        'E','ESE','SE','SSE',
        'S','SSW','SW','WSW',
        'W','WNW','NW','NNW'];
    return directions[Math.round(((angle %= 360) < 0 ? angle + 360 : angle) / 22.5) % 16];
}


