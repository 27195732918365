import { Center } from "@mantine/core";

interface LoginWrapperProps {
  children: React.ReactNode;
}

const LoginWrapper: React.FC<LoginWrapperProps> = ({ children }) => {
  return (
    <div className="mainWrapper">
      <div className="bg"></div>
      <Center p="xl">
        <div className="app-container">
          <div className="linkLogin-container">
            <a href="http:google.com" className="linkLogin" target="blank">
              <img
                className="linkicon"
                src="images/icons/envelope.svg"
                alt=""
              />
              horizonpath.eld@gmail.com
            </a>
            <br />
            <a href="http:google.com" className="linkLogin" target="blank">
              <img className="linkicon" src="images/icons/globe.svg" alt="" />
              www.horizonpath.com
            </a>
          </div>
          {children}
        </div>{" "}
      </Center>
    </div>
  );
};
export default LoginWrapper;
