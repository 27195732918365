import api from '../Login/Authentication';
import axios from 'axios';

const apiKey = 'dI0qHH3WpdsWuGFFvnFq' // test accuount Natours Api, natourstestapi@gmail.com

export const getAddressFromCoordinates = async (latitude: number, longitude: number) => {
    try {
        const response = await axios.get(`https://api.maptiler.com/geocoding/${longitude},${latitude}.json`, {
            params: {
                key: apiKey,
                language: 'en'
            }
        });

        if (response.status !== 200) {
            throw new Error(`Unexpected response status: ${response.status}`);
        }

        console.log('Full response:', response.data);

        const features = response.data.features;
        if (!features || features.length === 0) {
            throw new Error('No features found in the response');
        }

        const address = `${features[0]?.place_name.split(',')[0]} \n${features[1]?.place_name}` || 'Address not available';
        console.log('Parsed address:', address);
        return address;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.error('Axios error fetching address:', error.message);
        } else {
            console.error('Unexpected error fetching address:', error);
        }
    }
};