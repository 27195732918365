import { TextInput, Text, Menu, Button } from "@mantine/core";
import { modals } from "@mantine/modals";
import { CreateDriverModalProps, DeleteDriverModalProps, EditDriverModalProps } from "./Drivers.types";
import { notifications } from "@mantine/notifications";
import { handleDeleteDriverSubmit } from "./Drivers.utils";
import DriverCreateForm from "./DriverCreateForm";
import { initialValues } from "./Driver.constants";
import DriverEditForm from "./DriverEditForm";

export const DeleteDriverModal: React.FC<DeleteDriverModalProps> = ({ selectedDriver }) => {
    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: `Delete Driver`,
            centered: true,
            children: (
                <>
                    <Text size="sm" mb={20}>
                        Are you sure you want to delete this driver?
                        If you are sure, please type <Text span c="blue" inherit>'delete'</Text> into the box.
                    </Text>
                    <TextInput
                        placeholder="Enter confirmation word"
                        title="Enter 'delete' to confirm"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => localStorage.setItem('DriverDeleteConfirmationWord', event.currentTarget.value)}
                    />
                </>
            ),
            labels: { confirm: 'Delete driver', cancel: "No don't delete" },
            confirmProps: { color: 'red' },
            onCancel: () => {
                notifications.show({
                    title: 'Operation canceled',
                    message: `Deletion of canceled.`,
                });
            },
            onConfirm: () => {
                const confirmationWord = localStorage.getItem('DriverDeleteConfirmationWord');
                if (confirmationWord === 'delete') {
                    handleDeleteDriverSubmit(selectedDriver);
                    localStorage.removeItem('VehicleDeleteConfirmationWord');
                } else {
                    console.log(confirmationWord)
                    notifications.show({
                        title: 'Incorrect confirmation word',
                        message: `Please type 'delete' to confirm deletion.`,
                        color: 'red',
                    });
                }
            },
        });

    return (
        <Menu.Item style={{ textAlign: 'center' }} onClick={openDeleteModal} color='red'>
            Delete driver
        </Menu.Item>
    );
};

export const EditDriverModal: React.FC<EditDriverModalProps> = ({ selectedDriver }) => {
    const openUpdateModal = () =>
        modals.open({
            title: `Edit driver`,
            centered: true,
            size: 'auto',
            children: (<DriverEditForm selectedDriver={selectedDriver}/>),
        });

    return <Menu.Item style={{ textAlign: 'center' }} onClick={openUpdateModal}>
    Edit
</Menu.Item>
};

export const CreateDriverModal: React.FC<CreateDriverModalProps> = () => {
    const openCreateModal = () =>
        modals.open({
            title: `Add vehicle`,
            centered: true,
            size: 'auto',
            children: (
            <DriverCreateForm initialValues={initialValues} />
        ),
        });

    return <Button
        onClick={openCreateModal}
        color="green"
        size='sm'
        mr={"lg"}>
        + Add driver
    </Button>;
}