import { Link } from 'react-router-dom';
import { Box, Button, Flex, Menu } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { CrossIcon, OkIcon } from '../../../assets/icons';
import { Driver } from './Drivers.types';
import { getDrivers } from './Drivers.utils';
import { CreateDriverModal, DeleteDriverModal, EditDriverModal } from './DriversModals';
import { CompanySettings } from '../Companies/Companies.types';
import { initialValues } from './Driver.constants';


function Drivers() {
  const [data, setData] = useState<Driver[]>([])
  localStorage.removeItem('DriverDeleteConfirmationWord');


  useEffect(() => {
    async function fetchData() {
      try {
        const driversData: Driver[] = await getDrivers();
        console.log('driversData', driversData);
    
        setData(driversData);
      } catch (error) {
        console.error('Error fetching drivers:', error);
      }
    }
    
    fetchData();
  }, []);


  const columns = useMemo<MRT_ColumnDef<Driver>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
      },
      {
        accessorKey: 'DriverLicenseNumber',
        header: 'Driver License',
      },
      {
        accessorKey: 'dispatchers',
        header: 'Dispatchers',
      },
      {
        accessorKey: 'isAllowPersonalConv',
        header: 'Pers Conv',

        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "center",
              backgroundColor: cell.getValue()
                ? theme.colors.green[9]
                : theme.colors.red[9],
              borderRadius: "20px",
              maxWidth: "40px",
              padding: "10px",
              textAlign: "center",
              ColorInput: "white"
            })}
          >
            {cell.getValue() ? <OkIcon /> : <CrossIcon />}
          </Box>
        ),
      },
      {
        accessorKey: 'isApproved',
        header: 'Approved',
        Cell: ({ cell }) => (
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "center",
              backgroundColor: cell.getValue()
                ? theme.colors.green[9]
                : theme.colors.red[9],
              borderRadius: "20px",
              maxWidth: "40px",
              padding: "10px",
              textAlign: "center",
              ColorInput: "white"
            })}
          >
            {cell.getValue() ? <OkIcon /> : <CrossIcon />}
          </Box>
        ),
      }
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data,
    initialState: {
      density: 'xs',
      columnVisibility: {
        isApproved: false,
        isAllowPersonalConv: false
      }
    },
    enableRowActions: true,
    positionActionsColumn: "last",
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <CreateDriverModal initialValues={initialValues} />
      </div>
    ),
    renderRowActionMenuItems: ({ row }) => (
      <Flex gap="xs"
        justify="center"
        align="flex-end"
        direction="column"
        wrap="wrap">
        <EditDriverModal selectedDriver={row.original} />
        <DeleteDriverModal selectedDriver={row.original} />
      </Flex>
    )
  });

  return (
    <div style={{ margin: '20px' }}>
      <MantineReactTable table={table} />
    </div>
  )
};

export default Drivers;

