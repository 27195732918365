export const testLog = 
`2E81,1,2,1,4,052524,081851,98,1.95,37,-94.56,0,1,1,0,0,BC,43
2E81,1,2,1,1,052524,080851,98,1.95,37,-94.56,0,1,1,0,0,BC,43
2E7F,1,1,2,1,052524,073752,59,1.3,36.67,-95.07,0,1,1,0,0,8C,7B
2E7E,1,2,1,3,052524,063752,0,0.3,36.16,-95.83,0,1,1,0,0,05,22
2E7C,1,2,1,4,052524,061638,0,0.05,36.16,-95.83,0,1,1,0,0,25,42
2E77,1,2,1,2,052424,232337,241,4.7,36.16,-95.83,0,1,1,0,0,F4,CB
2E77,1,2,1,2,052424,182337,241,4.7,36.16,-95.83,0,1,1,0,0,F4,CB
2E76,1,1,2,1,052424,181811,240,4.65,36.16,-95.83,0,1,1,0,0,EC,23
2E75,1,1,2,1,052424,171811,179,3.65,35.75,-96.73,0,1,1,0,0,54,8B
2E74,1,1,2,1,052424,161811,114,2.65,35.47,-97.71,0,1,1,0,0,DC,1B
2E73,1,2,1,3,052424,151811,57,1.65,35.53,-98.65,0,1,1,0,0,8C,CB
2E72,1,2,1,1,052424,150222,57,1.4,35.53,-98.65,0,1,1,0,0,1D,22
2E71,1,1,2,1,052424,145304,51,1.25,35.52,-98.75,0,1,1,0,0,35,C2
2E70,1,2,1,3,052424,135304,0,0.25,35.02,-99.09,0,1,1,0,0,45,7D
2E6D,1,2,1,1,052424,125119,120,2.75,35.02,-99.09,0,1,1,0,0,1D,AB
2E6C,1,1,1,4,052424,123726,120,2.6,35.02,-99.09,0,1,1,0,0,15,3A
2E6B,1,1,1,3,052424,123638,120,2.55,35.02,-99.09,0,1,1,0,0,3D,C3
2E6A,1,2,1,4,052424,123134,120,2.45,35.02,-99.09,0,1,1,0,0,75,22
2E69,1,1,1,3,052424,122713,119,2.35,35.02,-99.09,0,1,1,0,0,35,1A
2E68,1,2,1,4,052424,122242,119,2.3,35.02,-99.09,0,1,1,0,0,7D,7A
2E67,1,1,2,1,052424,121520,116,2.15,35.02,-99.06,0,1,1,0,0,45,82
2E66,1,1,2,1,052424,111520,59,1.2,35.54,-98.57,0,1,1,0,0,25,CA
2E65,1,2,1,3,052424,101520,0,0.1,35.42,-97.62,0,1,1,0,0,E5,A2
2E63,1,2,1,1,052424,094343,262,4.85,35.42,-97.62,0,1,1,0,0,94,7A
2E61,1,2,1,4,052424,093705,262,4.75,35.42,-97.62,0,1,1,0,0,AC,3B
2E60,1,1,2,1,052424,092420,252,4.55,35.51,-97.58,0,1,1,0,0,F4,83
2E5F,1,1,2,1,052424,082420,188,3.55,36.32,-97.33,0,1,1,0,0,EC,B0
2E5E,1,1,2,1,052424,072420,124,2.55,37.24,-97.34,0,1,1,0,0,25,5A
2E5D,1,1,2,1,052424,062420,62,1.55,38.1,-97.37,0,1,1,0,0,15,3A
2E5C,1,2,1,3,052424,052420,0,0.6,38.87,-97.61,0,1,1,0,0,05,02
2E5A,1,2,1,4,052424,044634,491,8.7,38.87,-97.6,0,1,1,0,0,7C,B0
2E57,1,2,1,2,052324,181625,491,8.6,38.87,-97.6,0,1,1,0,0,6C,2B
2E56,1,1,2,1,052324,175134,469,8.15,38.88,-98.02,0,1,1,0,0,74,D3
2E55,1,1,2,1,052324,165134,405,7.2,38.88,-99.19,0,1,1,0,0,BC,43
2E54,1,1,2,1,052324,155134,339,6.2,39.1,-100.33,0,1,1,0,0,45,65
2E53,1,1,2,1,052324,145134,274,5.2,39.35,-101.36,0,1,1,0,0,7D,22
2E52,1,1,2,1,052324,135134,211,4.2,39.29,-102.52,0,1,1,0,0,B5,D2
2E51,1,1,2,1,052324,125134,145,3.2,39.27,-103.73,0,1,1,0,0,65,9A
2E50,1,1,2,1,052324,115134,78,2.25,39.74,-104.66,0,1,1,0,0,CC,D3
2E4F,1,1,1,3,052324,105134,21,1.25,40.36,-104.98,0,1,1,0,0,BD,CB
2E4E,1,2,1,1,052324,101209,21,0.75,40.36,-104.9,0,1,1,0,0,85,CA
2E4D,1,2,1,3,052324,094733,0,0.2,40.58,-105,0,1,1,0,0,F5,22
2E4B,1,2,1,1,052324,074153,0,0.25,40.58,-105,0,1,1,0,0,DD,62
2E47,1,2,1,4,052324,071240,66,99.9,40.58,-105,0,1,1,0,0,C4,6A
2E46,1,1,2,1,052324,070319,59,1.05,40.65,-105.14,0,1,1,0,0,65,BA
2E45,1,1,1,3,052324,060319,0,0.05,41.32,-105.6,0,1,1,0,0,CD,FA
2E3F,1,2,1,4,052324,052446,103,1.75,41.31,-105.6,0,1,1,0,0,BD,CB
2E3E,1,1,2,1,052324,045228,62,1.25,41.57,-106.15,0,1,1,0,0,6D,8B
2E3D,1,2,1,3,052324,035227,0,0.25,41.77,-107.2,0,1,1,0,0,95,F2
2E3B,1,2,1,4,052324,033028,214,3.7,41.77,-107.2,0,1,1,0,0,55,E2
2E35,1,2,1,1,052124,172001,213,3.55,41.77,-107.2,0,1,1,0,0,95,7D
2E34,1,1,2,1,052124,164752,190,3,41.74,-107.64,0,1,1,0,0,05,92
2E33,1,1,2,1,052124,154752,124,2,41.69,-108.86,0,1,1,0,0,25,BA
2E32,1,1,2,1,052124,144752,60,1.05,41.51,-109.97,0,1,1,0,0,05,65
2E31,1,2,1,3,052124,134752,0,0,41.26,-110.9,0,1,1,0,0,3E,55
2E2A,1,2,1,1,052124,130400,433,7,41.26,-110.9,0,1,1,0,0,C5,D2
2E29,1,1,2,1,052124,130037,431,6.95,41.25,-111.01,0,1,1,0,0,95,6D
2E28,1,1,2,1,052124,120037,370,5.95,41.14,-111.86,0,1,1,0,0,6D,3A
2E27,1,1,2,1,052124,110037,307,5,41.82,-112.4,0,1,1,0,0,D5,92
2E26,1,1,2,1,052124,100037,242,4,42.39,-113.29,0,1,1,0,0,8D,DA
2E25,1,1,2,1,052124,090037,184,3,42.58,-114.31,0,1,1,0,0,45,75
2E24,1,1,2,1,052124,080037,120,2,42.96,-115.36,0,1,1,0,0,A5,B2
2E23,1,1,2,1,052124,070037,58,1,43.55,-116.18,0,1,1,0,0,4D,C2
2E22,1,1,1,3,052124,060037,0,0.05,44.03,-116.95,0,1,1,0,0,FD,1A
2E1F,1,2,1,4,052124,054340,0,0.05,44.02,-116.9,0,1,1,0,0,D5,EA
2E1A,1,2,1,2,052024,190355,402,9.2,44.02,-116.9,0,1,1,0,0,55,CA
2E19,1,1,2,1,052024,181217,374,8.35,43.94,-117.36,0,1,1,0,0,FC,23
2E18,1,1,2,1,052024,171217,318,7.35,43.77,-118.21,0,1,1,0,0,3D,4A
2E17,1,1,2,1,052024,161217,264,6.35,43.55,-119.09,0,1,1,0,0,C4,4A
2E16,1,1,2,1,052024,151217,200,5.35,43.66,-120.22,0,1,1,0,0,8D,D2
2E15,1,1,2,1,052024,141217,141,4.4,44.06,-121.22,0,1,1,0,0,DD,FA
2E14,1,1,2,1,052024,131217,102,3.4,44.43,-121.71,0,1,1,0,0,DD,F2
2E13,1,1,2,1,052024,121217,67,2.4,44.4,-122.27,0,1,1,0,0,95,55
2E12,1,1,1,3,052024,111217,27,1.4,44.52,-122.91,0,1,1,0,0,8D,B2
2E10,1,1,1,4,052024,104049,27,1.4,44.53,-122.91,0,1,1,0,0,45,25
2E0F,1,1,1,3,052024,102557,19,1.15,44.56,-123.05,0,1,1,0,0,5D,62
2E0E,1,2,1,1,052024,101719,19,0.5,44.55,-123,0,1,1,0,0,85,AA
2E0D,1,2,1,3,052024,095835,0,0.2,44.43,-122.7,0,1,1,0,0,9D,5A
2E05,1,2,1,1,052024,085057,0,0.1,44.43,-122.7,0,1,1,0,0,DD,FA
2E01,1,2,1,4,052024,083038,77,1.85,44.43,-122.7,0,1,1,0,0,35,B2
2E00,1,1,2,1,052024,074832,47,1.15,44.71,-123.05,0,1,1,0,0,65,4D
2DFF,1,2,1,3,052024,064832,0,0.1,45.26,-122.7,0,1,1,0,0,ED,23
2DFC,1,2,1,1,052024,063610,0,0,45.26,-122.7,0,1,1,0,0,1E,22
2DFA,1,2,1,4,052024,061706,0,0,45.26,-122.7,0,1,1,0,0,D5,62
2DF8,1,2,1,3,052024,052700,0,0,45.54,-122.3,0,1,1,0,0,06,55
2DF6,1,2,1,4,052024,051200,0,0,45.54,-122.3,0,1,1,0,0,5E,BA
2DEA,1,2,1,2,051924,184558,253,4.4,45.54,-122.3,0,1,1,0,0,D4,73
2DE9,1,1,2,1,051924,183646,246,4.15,45.54,-122.28,0,1,1,0,0,94,13
2DE8,1,1,2,1,051924,173646,187,3.15,45.65,-121.21,0,1,1,0,0,F4,53
2DE7,1,1,2,1,051924,163646,123,2.15,45.77,-120.12,0,1,1,0,0,25,42
2DE6,1,1,2,1,051924,153646,64,1.15,45.68,-118.86,0,1,1,0,0,44,E3
2DE5,1,2,1,3,051924,143646,0,0.05,45.29,-118,0,1,1,0,0,5D,83
2DE2,1,2,1,1,051924,135011,449,7.25,45.29,-118,0,1,1,0,0,35,5A
2DE1,1,1,1,1,051924,133813,449,6.8,45.29,-118,0,1,1,0,0,F4,1B
2DE0,1,1,2,1,051924,124913,390,6,44.46,-117.33,0,1,1,0,0,35,22
2DDF,1,1,2,1,051924,114913,324,99.9,43.79,-116.74,0,1,1,0,0,04,98
2DDE,1,1,2,1,051924,104913,259,4.05,43.24,-115.84,0,1,1,0,0,D4,63
2DDD,1,1,2,1,051924,094913,194,3.05,42.86,-114.85,0,1,1,0,0,B4,A8
2DDC,1,1,2,1,051924,084913,128,2.05,42.57,-113.74,0,1,1,0,0,E4,6B
2DDB,1,1,2,1,051924,074913,64,1.05,42.01,-112.85,0,1,1,0,0,7D,0B
2DDA,1,2,1,3,051924,064913,0,0.05,41.5,-112,0,1,1,0,0,D5,72
2DD5,1,2,1,4,051924,062000,198,0,41.5,-112,0,1,1,0,0,CD,7A
2DD2,1,2,1,2,051824,165137,198,0,41.5,-112,0,1,1,0,0,AD,B3
2DD1,1,1,2,1,051824,163020,176,0,41.21,-112,0,1,1,0,0,26,35
2DD0,1,1,2,1,051824,153020,113,0,41.17,-111.14,0,1,1,0,0,1E,92
2DCF,1,1,2,1,051824,143020,52,0,41.44,-110.1,0,1,1,0,0,7E,2A
2DCE,1,2,1,3,051824,133020,0,0,41.6,-109.2,0,1,1,0,0,7E,2A
2DCC,1,2,1,1,051824,125819,479,7.45,41.6,-109.2,0,1,1,0,0,94,5B
2DCA,1,2,1,4,051824,124516,480,7.45,41.6,-109.2,0,1,1,0,0,35,A3
2DC9,1,1,2,1,051824,121529,451,6.95,41.67,-108.73,0,1,1,0,0,AC,D0
2DC8,1,1,2,1,051824,111529,386,5.95,41.76,-107.53,0,1,1,0,0,BC,C0
2DC7,1,1,2,1,051824,101529,321,4.95,41.67,-106.36,0,1,1,0,0,D4,EB
2DC6,1,1,2,1,051824,091529,257,3.95,41.2,-105.43,0,1,1,0,0,DC,63
2DC5,1,1,2,1,051824,081529,193,3,41.16,-104.28,0,1,1,0,0,15,0A
2DC4,1,1,2,1,051824,071529,128,2,41.15,-103.08,0,1,1,0,0,55,E2
2DC3,1,1,2,1,051824,061529,62,1,41.08,-101.89,0,1,1,0,0,75,0A
2DC2,1,2,1,3,051824,051529,0,0,41.1,-100.7,0,1,1,0,0,1E,82
2DBD,1,2,1,4,051824,044200,1,1.93,41.1,-100.7,0,1,1,0,0,36,DA
2DB9,1,2,1,2,051724,174710,426,7.05,41.1,-100.7,0,1,1,0,0,BD,F3
`;
