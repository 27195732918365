import dayjs from "dayjs"
import styles from './LiveTrackerDrawer.module.css';

const LiveTrackingDrawerHOS = ({truckData}: any) => {
    const HOSArray = [
        {
            id: 1,
            name: 'Driving limit',
            date: '2022-01-01T00:00:00Z',
        },
        {
            id: 2,
            name: 'Cycle limit',
            date: '2022-01-01T00:00:00Z',
        },
        {
            id: 3,
            name: '30 min break',
            date: '2022-01-01T00:00:00Z',
        }
    ]

    return (
        <div className={styles.liveTrackingDrawerHOS}>
            {HOSArray.map((HOS) => (
                <a key={HOS.id} href={`/dashboard/driver-log?id=${truckData.AssignedDriverId}&to=${dayjs(HOS.date).format('YYYY-MM-DD')}T23:59:59.999Z&from=${dayjs(HOS.date).format('YYYY-MM-DD')}T00:00:00.000Z`}>
                    <div
                    className={styles.liveTrackingDrawerHOSBtn}
                >
                    <div className={styles.liveTrackingDrawerHOSBtnTitle}>{HOS.name}</div>
                    <div className={styles.liveTrackingDrawerHOSBtnTime}>{dayjs(HOS.date).format('HH:mm:ss MM/DD/YY')}</div>
                </div>
                </a>
            ))}
        </div>
    )
}
export default LiveTrackingDrawerHOS