import { Box, Button, Group, Select, Stack, TextInput } from "@mantine/core"
import { modals } from '@mantine/modals';
import states from "../../../data/USstates"
import timezones from "../../../data/timezones"
import { handleCreateCompanySubmit } from "./Companies.utils"
import { useForm } from "@mantine/form"
import { CompanyAddSettings } from "./Companies.types"

const CompaniesForm = () => {
  const form = useForm<CompanyAddSettings>({
    initialValues: {
      dotNumber: '',
      city: '',
      zipCode: '',
      carrierName: '',
      address: '',
      state: '',
      timeZone: '',
      contact: ''
    },
    validate: {
      dotNumber: (value) => (value !== "" ? null : "Invalid first DOT number"),
      contact: (value) => (value !== "" ? null : "Invalid contact"),
      city: (value) => (value !== "" ? null : "Invalid city"),
      zipCode: (value) => (value !== "" ? null : "Invalid zipCode"),
      carrierName: (value) => (value !== "" ? null : "Invalid carier name"),
      address: (value) => (value !== "" ? null : "Invalid address"),
      state: (value) => (value !== "" ? null : "Invalid state"),
      timeZone: (value) => (value !== "" ? null : "Invalid time zone"),
    },
  });


  return (
    <Box
      component="form"
      maw={800}
      mx="auto"
      onSubmit={form.onSubmit(
        (values, _event) => { handleCreateCompanySubmit(values) }
      )}
      onReset={form.onReset}
    >
      <Group position='center' align='flex-start'>
        <Stack w={300} align='center' justify='flex-start' className="companies-table-column" >
          <TextInput
            required
            w={250}
            placeholder="DOT Number"
            label="DOT Number"
            type="number"
            {...form.getInputProps(`dotNumber`)}
            step={1}
          />
          <TextInput
            w={250}
            label="Contact:"
            type="number"
            placeholder="Contact"
            required
            step={2}
            mt="sm"
            {...form.getInputProps(`contact`)}

          />
          <TextInput
            w={250}
            label="City:"
            mt="sm"
            {...form.getInputProps(`city`)}
            placeholder="City"
            required
            step={3}
          />
          <TextInput
            w={250}
            label="ZIP code:"
            type="number"
            mt="sm"
            required
            step={4}
            placeholder="ZIP code"
            {...form.getInputProps(`zipCode`)}
          />
        </Stack>
        <Stack w={300} justify='flex-start' align='center' className="companies-table-column" >
          <TextInput
            w={250}
            label="Name:"
            mt="sm"
            {...form.getInputProps(`carrierName`)}
            placeholder="Carrier Name"
            required
            step={5}
          />
          <TextInput
            w={250}
            label="Address:"
            mt="sm"
            {...form.getInputProps(`address`)}
            placeholder="Address"
            required
            step={6}
          />
          <Select
            w={250}
            label="State:"
            placeholder="Pick one"
            mt="sm"
            data={states}
            {...form.getInputProps(`state`)}
            onChange={(value) => {
              if (value !== null) {
                form.setFieldValue('state', value);
              }
            }}
            required
            step={7}
          />
          <Select
            w={250}
            label="Timezone:"
            placeholder="Pick one"
            mt="sm"
            data={timezones}
            {...form.getInputProps(`timeZone`)}
            onChange={(value) => {
              if (value !== null) {
                form.setFieldValue('timeZone', value);
              }
            }}
            required
            step={8}
          />
        </Stack>
      </Group>
      <Group
        mt="xl"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px',
          marginTop: '50px'
        }}>
        <Button
          size="md"
          color='red'
          onClick={() => modals.closeAll()}
        >
          Cancel
        </Button>
        <Button
          size="md"
          type="submit"
          color='green'
        >
          Create New Company
        </Button>
      </Group>
    </Box>
  )
}
export default CompaniesForm