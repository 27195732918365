import { useContext } from 'react';
import { ELDLogContext } from './ELDLog';
import { Link, useLocation } from 'react-router-dom';
import ELDLogDatePicker from './ELDLogDatePicker';
import { Button } from '@mantine/core';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function formatDate(str: string) {
    const parseDate = (dateStr: string) => {
        if (dateStr.length === 6) {
            const month = dateStr.substring(0, 2);
            const day = dateStr.substring(2, 4);
            const year = '20' + dateStr.substring(4, 6);
            return `${month}/${day}/${year}`;
        }
        return `no data`;
    };

    const dateRange = str.split('-');
    return dateRange.map(parseDate).join(' - ');
}

const ELDLogHeader = () => {
    const { ELDLog } = useContext(ELDLogContext);
    const query = useQuery();
    const date = query.get('date');
    const formattedDate = date ? formatDate(date) : 'No date provided';
    const driver = ELDLog ? ELDLog?.truck.driverFirstName + ' ' + ELDLog?.truck.driverLastName : 'no data'

    return (
        <div className='driverLogHeader' >
            <div className='driverLogHeaderItem'>
                <div className='driverLogHeaderInfo'>
                    <div className='driverLogHeaderInfoItem'>
                        <h4 className='driverLogTitle'>Driver Log: </h4>
                        <h5 className='driverLogTitle'>{formattedDate}</h5>
                        <h4>Driver: </h4>
                        <h5>{driver}</h5>
                    </div>
                    <div className='driverLogHeaderInfoItem'>
                        <ul className='driverLogList'>
                            <li>Vehicle: <b>{ELDLog?.truck.unit}</b></li>
                            <li>odometer: <b>{ELDLog?.truck.odometer ? String(ELDLog.truck.odometer) : '0'}</b></li>
                            <li>Next oil change: <b>{ELDLog?.truck.nextService ? String(ELDLog.truck.odometer) : '0'}</b></li>
                            <li>Driver`s phone num: <b>N\A</b></li>
                            <li>Driver`s email: <b>N\A</b></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='driverLogHeaderItem'>
                <ELDLogDatePicker />
            </div>
            <div className='driverLogHeaderItem'>
                <Button className="driverLogReturnBtn" color={'red'}>
                    <Link
                        style={{ textDecoration: 'none', color: '#fff' }}
                        to={'/dashboard/support/ELDLogs'}>
                        Return to logs
                    </Link>
                </Button>
            </div>
        </div>
    )
}
export default ELDLogHeader