import { useContext, useEffect, useMemo, useState } from 'react';
import {
    MantineReactTable,
    MRT_Cell,
    MRT_ColumnDef,
    useMantineReactTable,
} from 'mantine-react-table';
import { Button, Flex, Text } from '@mantine/core';
import { getBackgroundGradient } from './ELDTableUtils';
import { UserELDLog } from './ELDLog.types';
import { parseELDDataToTableFormat } from '../../../utils/driverLogProcessor';
import { ELDLogContext } from './ELDLog';
import Modals from './ELDLogModals';
import { isActiveOptions, noteOptions, originOptions, statusOptions } from './ELDLogConstants';
import ELDLogsTableTopBtns from './ELDLogsTableTopBtns';

const ELDLogChartTable = () => {
    const [data, setData] = useState<UserELDLog[]>([])
    const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({}); //keep track of rows that have been edited
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [activeModal, setActiveModal] = useState<string | null>(null);
    const [isDirty, setIsDirty] = useState(false); // State to track changes in the table
    const [changedCells, setChangedCells] = useState<string[]>([]); 

    const { ELDLog } = useContext(ELDLogContext);

    useEffect(() => {
        async function fetchData() {
            if (ELDLog) {
                console.log('ELDLog', ELDLog);

                const formattedData = await parseELDDataToTableFormat(ELDLog);
                if (formattedData.length > 0) {
                    formattedData.pop(); // Delete last row in the table
                }
                setData(formattedData);
                setIsLoadingData(false);
            }
        }

        fetchData();
    }, [ELDLog])

    const handleCancelButton = () => {
        setActiveModal('cancel');
    };

    const handleSaveButton = () => {
        setActiveModal('save');
    };

    const handleConfirmSave = () => {
        // Perform save operation here
        console.log('on save', data);
        setActiveModal(null);
        setIsDirty(false);
    };

    const handleConfirmDiscardChanges = async () => {
        // Reset data to initial state or fetch data again
        if (ELDLog) {
            const formattedData = await parseELDDataToTableFormat(ELDLog);
            if (formattedData.length > 0) {
                formattedData.pop(); // Delete last row in the table
            }
            setData(formattedData);
            setIsLoadingData(false);
        }

        setChangedCells([])
        setActiveModal(null);
        setIsDirty(false);
    };

    const handleSaveCell = (cell: MRT_Cell<UserELDLog>, value: any) => {
        const newData = [...data];
        newData[cell.row.index] = {
            ...newData[cell.row.index],
            [cell.column.id]: value
        };

        setChangedCells(prevState => [...prevState, cell.id]);
        setData(newData);
        setIsDirty(true);
    };

    const columns = useMemo<MRT_ColumnDef<UserELDLog>[]>(
        () => [
            {
                accessorKey: 'status',
                header: 'Status',
                editVariant: 'select',
                size: 110,

                mantineEditSelectProps: ({ row }) => ({
                    data: statusOptions,
                    searchable: false,
                }),
            },
            {
                accessorKey: 'seqNumber',
                header: 'S/N',
                editVariant: 'text',
                size: 100,
            },
            {
                accessorKey: 'vehicle',
                header: 'Tuck',
                editVariant: 'text',
                size: 110,
            },
            {
                accessorKey: 'date',
                header: 'Date',
                size: 110,
                editVariant: 'text',
            },
            {
                accessorKey: 'startTime',
                header: 'Start Time',
                size: 100,
                editVariant: 'text',
            },
            {
                accessorKey: 'odometer',
                header: 'Odometer',
                size: 100,
                editVariant: 'text',
            },
            {
                accessorKey: 'engineHours',
                header: 'Eng. hours',
                size: 100,
                editVariant: 'text',
            },
            {
                accessorKey: 'duration',
                header: 'Duration',
                size: 100,
                editVariant: 'text',
            },
            {
                accessorKey: 'distance',
                header: 'Distance',
                size: 100,
                editVariant: 'text',
            },
            {
                accessorKey: 'location',
                header: 'Location',
                size: 140,
                editVariant: 'text',
            },
            {
                accessorKey: 'latitude',
                header: 'Lat',
                size: 100,
                editVariant: 'text',
            },
            {
                accessorKey: 'longitude',
                header: 'Lon',
                size: 100,
                editVariant: 'text',
            },
            {
                accessorKey: 'elapsedEngineHours',
                header: 'El.Eng.Hours',
                size: 100,
                editVariant: 'text',
            },
            {
                accessorKey: 'elapsedOdometer',
                header: 'El.Odometer',
                size: 100,
                editVariant: 'text',
            },
            {
                accessorKey: 'isActiveStatus',
                header: 'Is Active',
                size: 80,
                editVariant: 'select',
                mantineEditSelectProps: ({ row }) => ({
                    data: isActiveOptions,
                    searchable: false,
                }),
            },
            {
                accessorKey: 'origin',
                header: 'Origin',
                size: 80,
                editVariant: 'select',
                mantineEditSelectProps: ({ row }) => ({
                    data: originOptions,
                    searchable: false,
                }),
            },
            {
                accessorKey: 'note',
                header: 'Note',
                editVariant: 'select',
                mantineEditSelectProps: ({ row }) => ({
                    data: noteOptions,
                    searchable: false,
                }),
            },
        ],
        [validationErrors],
    );

    const table = useMantineReactTable({
        columns,
        data: data,
        enablePagination: false,
        editDisplayMode: "cell",
        createDisplayMode: 'modal', // ('row', 'modal', and 'custom' are also available)
        enableEditing: true,
        enableColumnResizing: true,
        enableRowSelection: true,
        mantineSelectCheckboxProps: { color: 'blue', size: 'xs' },
        initialState: {
            density: 'xs',
            columnVisibility: {
                duration: false,
                distance: false,
                elapsedEngineHours: false,
                elapsedOdometer: false,
            },
            sorting: [
                {
                    id: 'date',
                    desc: false,
                },
                {
                    id: 'startTime',
                    desc: false,
                },
            ]
        },
        mantineEditTextInputProps: ({ cell }) => ({
            //onBlur is more efficient, but could use onChange instead
            onBlur: (event) => {
                handleSaveCell(cell, event.target.value);
            },
        }),
        renderBottomToolbarCustomActions: () => (
            <Flex align="center" gap="md">
                <Button
                    color="blue"
                    onClick={handleSaveButton}
                    disabled={!isDirty}
                >
                    Save
                </Button>
                <Button
                    color="red"
                    onClick={handleCancelButton}
                    disabled={!isDirty}
                >
                    Cancel
                </Button>
            </Flex>
        ),
        renderTopToolbarCustomActions: () => (
            <Flex align="center" gap="md">
                <ELDLogsTableTopBtns table={table} />
            </Flex>
        ),
        mantineTableBodyCellProps: (cell) => ({
                style: {
                    background: getBackgroundGradient(cell.row.original.status),
                    color: changedCells.includes(cell.cell.id) ?  'red' : 'black'
                },
        }),
    })

    return (
        <>
            <Modals
                isOpen={activeModal === 'cancel'}
                onClose={() => setActiveModal(null)}
                onConfirm={handleConfirmDiscardChanges}
                title="Do you want to cancel?"
                content="All changes will be ignored and erased. Do you want to continue?"
                confirmText="Yes, I confirm"
                cancelText="No, continue editing"
            />
            <Modals
                isOpen={activeModal === 'save'}
                onClose={() => setActiveModal(null)}
                onConfirm={handleConfirmSave}
                title="Are you sure you want to save?"
                content="Any unsaved changes will be lost."
                confirmText="Yes, save changes"
                cancelText="No, keep editing"
            />
            <MantineReactTable table={table} />
        </>
    );
};

export default ELDLogChartTable;