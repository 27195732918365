import { Box, Button, Container, Group, Loader, Select, Stack, TextInput } from "@mantine/core"
import { modals } from '@mantine/modals';
import { useForm } from "@mantine/form"
import { Vehicle, VehicleUpdate } from "./Vehicles.types";
import { handleCreateTruckSubmit, handleUpdateTruckSubmit } from "./Vehicles.utils";
import { CompanySettings } from "../Companies/Companies.types";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import truckDRVIconUrl from '../../../assets/svgIcons/truckDRVIcon.svg';
import { AxiosResponse } from "axios";
import api from "../../Login/Authentication";
import { Driver } from "../Drivers/Drivers.types";
import { useEffect, useState } from "react";

const UpdateVehiceForm =  ({ selectedTruck, allDrivers }: { selectedTruck: Vehicle, allDrivers: Driver[] }) => {
  const [loading, setLoading] = useState(true);
  const [driverLocation, setDriverLocation] = useState<[number, number]>([41.8337734, -88.0616146]);
  const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData') || '{}').companies : []
  const companiesArray = userData.map((company: CompanySettings) => ({ value: company.id, label: company.carrierName }))

  
  // Fetch driver location
  async function getDriverLocation(driverId: string): Promise<[number, number]> {
    const liveDriversResponse = await api.get(`/api/v1/livemap`);
    const driver = liveDriversResponse.data.find((live: { DriverId: string }) => live.DriverId === driverId);

    if (driver) {
      return [driver.Latitude, driver.Longitude];
    } else {
      return [41.8337734, -88.0616146]; // Default location
    }
  }

  useEffect(() => {
    // Update driver location when the component mounts or selectedTruck.assigneddriverid changes
    if (selectedTruck.assigneddriverid) {
      getDriverLocation(selectedTruck.assigneddriverid).then(location => {
        setDriverLocation(location);
        setLoading(false); // Set loading to false once location is fetched
      });
    }
  }, [selectedTruck.assigneddriverid]);

  const form = useForm<VehicleUpdate>({
    initialValues: {
      unitnumber: selectedTruck.unitnumber,
      licenseplateNumber: selectedTruck.licenseplatenumber,
      vin: selectedTruck.vin,
      deviceserialnumber: selectedTruck.deviceserialnumber,
      assignedcompanyid: selectedTruck.assignedcompanyid,
      assigneddriverid: selectedTruck.assigneddriverid,
    },
    validate: {
      unitnumber: (value) => (value !== "" ? null : "Invalid unitnumber"),
    },
  });

  return (
    <Box
      component="form"
      mx="auto"
      onSubmit={form.onSubmit((values: any, _event) => { handleUpdateTruckSubmit({ id: selectedTruck.id, ...values }) })}
      onReset={form.onReset}
    >
      <Group position='center' align='flex-start'>
        <Stack w={300} align='center' justify='flex-start' className="companies-table-column" >
          <TextInput
            required
            w={250}
            placeholder="unit number"
            label="Unit Number"
            type="number"
            {...form.getInputProps(`unitnumber`)}
            step={1}
          />
          <TextInput
            w={250}
            label="License plate number:"
            type="number"
            placeholder="Contact"
            required
            step={2}
            mt="sm"
            {...form.getInputProps(`licenseplateNumber`)}

          />
          <TextInput
            w={250}
            label="VIN number:"
            mt="sm"
            {...form.getInputProps(`vin`)}
            placeholder="VIN number"
            required
            step={3}
          />
          <TextInput
            w={250}
            label="Device number:"
            mt="sm"
            required
            step={4}
            placeholder="Device number"
            {...form.getInputProps(`deviceserialnumber`)}
          />
          <Select
            w={250}
            label="Company:"
            placeholder="Pick one"
            mt="sm"
            data={companiesArray}
            {...form.getInputProps(`assignedcompanyid`)}
            onChange={(value) => {
              if (value !== null) {
                form.setFieldValue('assignedcompanyid', parseInt(value, 10)
                );
              }
            }}
            required
            step={5}
          />
          <Select
            w={250}
            label="Assingned driver:"
            placeholder="Pick one"
            mt="sm"
            data={allDrivers.map((driver: Driver) => ({ value: driver.id, label: driver.firstName + " " + driver.lastName }))}
            {...form.getInputProps(`assigneddriverid`)}
            onChange={(value) => {
              if (value !== null) {
                form.setFieldValue('assigneddriverid', value);
              }
            }}
            step={6}
          />


        </Stack>
        <Stack justify='flex-start' align='stretch' className="companies-table-column">
          <div style={{  minHeight: '450px%' }}>
        {loading ? (<Loader variant="bars" color="green" size="xl" style={{ margin: '150px' }}/>) : (
        <MapContainer
            center={driverLocation}
            zoom={14}
            scrollWheelZoom={true}
            className='vehicles-map' >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              position={driverLocation}
              icon={L.icon({
                iconUrl: truckDRVIconUrl,
                iconSize: [25, 25],
                iconAnchor: [0, 0],
                popupAnchor: [0, -32],
              })}
            >
              <Popup>
                Assigned driver: <br /> <b>{'truck.driverFirstName'} {'truck.driverLastName'}</b>.
              </Popup>
            </Marker>
          </MapContainer>)}
          </div>
          <Group
            mt="xl"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '30px',
              marginTop: '50px'
            }}>
            <Button
              size="md"
              color='red'
              onClick={() => modals.closeAll()}
            >
              Cancel
            </Button>
            <Button
              size="md"
              type="submit"
              color='green'
            >
              Update truck data
            </Button>
            <div className='vehicle-next-service-tab'>
              <p>Till next service:</p>
              <p>9002 mi</p>
            </div>
          </Group>
        </Stack>
      </Group>


    </Box>
  )
}
export default UpdateVehiceForm