import dayjs from 'dayjs';
import { testLog } from './testLog';
const duration = require('dayjs/plugin/duration');
const utc = require('dayjs/plugin/utc');
dayjs.extend(duration);
dayjs.extend(utc);
const mobileDataExample = [
    { hour: '011233',status: 'OFF' },
    { hour: '020812',status: 'SB' },
    { hour: '075518',status: 'DRV' },
    { hour: '125113',status: 'OFF' },
    { hour: '171233',status: 'ON' },
    { hour: '181833',status: 'DRV' },
];
const report = {
    "id": 10,
    "servertime": "2024-06-11T10:02:00.000Z",
    "status": "driving",
    "location": "789 Oak St, Chicago, IL",
    "latitude": 41.878113,
    "longitude": -87.629799,
    "driverid": "aded6721-19b1-40fd-861c-159f655a6aba",
    "deviceid": 100,
    "truckid": 100,
    "assignedcompanyid": 68,
    "note": null
}

const timeNow = '052524,081951'
// const timeNow = dayjs(Date.now(), 'MMDDYY,HHmmss')
const codes = {
    '1,1': 'OFF',
    '1,2': 'SB',
    '1,3': 'DRV',
    '1,4': 'ON',
};

const HOSAlerts = {
    shiftOverTime: false,
}

const drivingBreak30m = 1800000 // 30 minutes
const driving8h = 28800000  // 8 hours
const breakFullTimeMS10h = 36000000 // 10 hours
const diveFullTimeMS11h = 39600000 // 11 hours
const shiftFullTimeMS14h = 50400000 // 14 hours
const cycleFullTimeMS70h = 252000000 // 70 hours
const sleepTimeMS34h = 122400000 // 34 hours 

function formatMilliseconds(milliseconds) {
    let totalSeconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds % 3600) / 60);
    let seconds = totalSeconds % 60;

    return `${hours.toString().padStart(2,'0')}:${minutes.toString().padStart(2,'0')}:${seconds.toString().padStart(2,'0')}`;
}
function parseDate(dateStr,timeStr) {
    const year = parseInt("20" + dateStr.substring(4,6),10);
    const month = parseInt(dateStr.substring(0,2),10) - 1;
    const day = parseInt(dateStr.substring(2,4),10);
    const hours = parseInt(timeStr.substring(0,2),10) - 2; // -2 is for testing, TODO: set timezone
    const minutes = parseInt(timeStr.substring(2,4),10);
    const seconds = parseInt(timeStr.substring(4,6),10);

    return Date.UTC(year,month,day,hours,minutes,seconds);
}

function updateLogWithNewTime(log,newTime) {
    const logLines = log.trim().split('\n');
    const firstLine = logLines[0].split(',');

    const [newDate,newTimeValue] = newTime.split(',');
    firstLine[5] = newDate;
    firstLine[6] = newTimeValue;

    const updatedFirstLine = firstLine.join(',');
    const reversedLogLines = logLines.reverse();
    reversedLogLines.push(updatedFirstLine);

    return reversedLogLines;
}

const logLines = updateLogWithNewTime(testLog,timeNow);

const filteredLogLines = logLines.filter(line => {
    const parts = line.split(',');
    const eventCode = `${parts[3]},${parts[4]}`;
    return codes.hasOwnProperty(eventCode);
});

let events = [];

for (let i = 0; i < filteredLogLines.length; i++) {
    const line = filteredLogLines[i];
    const parts = line.split(',');

    const seq = parts[0];
    const eventCode = `${parts[3]},${parts[4]}`;
    const date = parts[5];
    const time = parts[6];

    if (codes[eventCode]) {
        const eventTime = parseDate(date,time);
        let formattedDuration = "00:00:00";

        let duration = 0;
        if (i < filteredLogLines.length - 1) {
            const nextLine = filteredLogLines[i + 1].split(',');
            const nextDate = nextLine[5];
            const nextTime = nextLine[6];
            const nextEventTime = parseDate(nextDate,nextTime);
            duration = (nextEventTime - eventTime);

            const hours = Math.floor(duration / 3600000);
            const minutes = Math.floor((duration % 3600000) / 60000);
            const seconds = Math.floor((duration % 60000) / 1000);
            formattedDuration = `${String(hours).padStart(2,'0')}:${String(minutes).padStart(2,'0')}:${String(seconds).padStart(2,'0')}`;

        }

        events.push({
            seq: seq,
            event: codes[eventCode],
            time: eventTime,
            time2: dayjs(eventTime).format('YYYY-MM-DD HH:mm:ss'),
            duration,
            formattedDuration,
        });
    }
}

// TODO: make expander for envents if there is no reset (additional call for more period)

const getBreakTime = (events) => {
    let totalDriving = 0;

    for (let i = events.length - 1; i >= 0; i--) {
        const event = events[i];
        let stopToAdd = 0;

        const { event: eventType,duration } = event;

        if (eventType === 'DRV') {
            totalDriving += duration;
            stopToAdd = 0;
        }
        if (eventType === 'SB' || eventType === 'OFF' || eventType === 'ON') {
            stopToAdd += duration;
        }
        if (totalDriving > driving8h) console.error('HOS alert: 30 MIN. BREAK IS REQUIRED');
        if (stopToAdd > drivingBreak30m) {
            break;
        }
    }

    return driving8h - totalDriving >= 0 ? formatMilliseconds(driving8h - totalDriving) : '00:00:00';
};

const getDriveTime = (events) => {
    let sleep = 0;
    let totalDriving = 0;
    let stopToAdd = 0;

    for (let i = events.length - 1; i >= 0; i--) {
        const event = events[i];
        const { event: eventType,duration } = event;

        if (eventType === 'SB' || eventType === 'OFF') {
            sleep += duration;
            stopToAdd += duration;
        }
        if (eventType === 'DRV') {
            totalDriving += duration;
            totalDriving += stopToAdd;
            sleep = 0;
            stopToAdd = 0;
        }

        if (totalDriving > diveFullTimeMS11h) console.error('HOS alert: DRIVING LIMIT');
        if (sleep > diveFullTimeMS11h) {
            break;
        }
    }

    return diveFullTimeMS11h - totalDriving >= 0 ? formatMilliseconds(diveFullTimeMS11h - totalDriving) : '00:00:00';
};

const getCycleTime = (events) => {
    let sleep = 0;
    let totalCycle = 0;
    const eventSeqArr = []

    for (let i = events.length - 1; i >= 0; i--) {
        const event = events[i];
        const { event: eventType,duration } = event;

        if (eventType === 'SB' || eventType === 'OFF') {
            sleep += duration;
        }
        if (eventType === 'ON' || eventType === 'DRV') {
            totalCycle += duration;
            sleep = 0;
            eventSeqArr.push(event.seq,totalCycle);
        }

        if (totalCycle > cycleFullTimeMS70h) console.error('HOS alert: CYCLE LIMIT');
        if (sleep > sleepTimeMS34h) {
            break;
        }
    }
    return cycleFullTimeMS70h - totalCycle >= 0 ? formatMilliseconds(cycleFullTimeMS70h - totalCycle) : '00:00:00';
};

const getShiftTime = (events) => {
    let sleep = 0;
    let totalShift = 0;
    let stopToAdd = 0;

    for (let i = events.length - 1; i >= 0; i--) {
        const event = events[i];
        const { event: eventType,duration } = event;

        if (eventType === 'SB' || eventType === 'OFF') {
            sleep += duration;
            stopToAdd += duration;
        }
        if (eventType === 'ON' || eventType === 'DRV') {
            totalShift += duration;
            totalShift += stopToAdd;
            sleep = 0;
            stopToAdd = 0;
        }

        if (totalShift > shiftFullTimeMS14h) console.error('HOS alert: SHIFT LIMIT');
        if (sleep > breakFullTimeMS10h) {
            break;
        }
    }

    return shiftFullTimeMS14h - totalShift >= 0 ? formatMilliseconds(shiftFullTimeMS14h - totalShift) : '00:00:00';
};

let totalShift = getShiftTime(events);
let totalCycle = getCycleTime(events);
let totalDriving = getDriveTime(events);
let totalBreak = getBreakTime(events);

function foo() {
    const finalTimers = {
        totalBreak,
        totalDriving,
        totalShift,
        totalCycle,
    }
    
    return finalTimers
}

export default foo