import axios from "axios";
import { API_BASE_URL } from "./environments";

const api = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
});

export function setCookie(name: string, value: string, seconds: number) {
    let expires = "";
    if (seconds) {
        let date = new Date();
        date.setTime(date.getTime() + (seconds * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(cookieName: string) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function isTokenValid(token: string) {
    try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        const currentTime = Date.now() / 1000;
        return payload.exp > currentTime;
    } catch (e) {
        console.error("Error decoding token:", e);
        return false;
    }
}

export async function refreshAccessToken(refreshToken?: string) {
    try {
        const response = await api.post(`/api/v1/user/refresh`, {
            refreshToken: refreshToken || sessionStorage.getItem('refreshToken'),
        }, {
            withCredentials: true,
        });

        const data = response.data;
        const newAccessToken = data.accessToken;

        if (newAccessToken) {
            setCookie('accessToken', newAccessToken, 60);
        }

        return newAccessToken;
    } catch (error) {
        console.error('Error refreshing token:', error);
        return null;
    }
}

api.interceptors.request.use(
    async (config) => {
        const token = getCookie('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = sessionStorage.getItem('refreshToken');

            if (refreshToken && isTokenValid(refreshToken)) {
                const newAccessToken = await refreshAccessToken(refreshToken);

                if (newAccessToken) {
                    api.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
                    return api(originalRequest);
                }
            }
        }

        return Promise.reject(error);
    }
);

export default api;
