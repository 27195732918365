import { Link, useLocation } from "react-router-dom";
import {
  HomeIcon,
  DriverLogIcon,
  InspectionLogsIcon,
  HelpIcon,
  RulesIcon,
  LiveTrackingIcon,
  ReportsIcon,
  AdministrationIcon,
  SupportPanelIcon,
} from "../assets/icons";
import { NavLink } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { Drawer, Button, Group } from '@mantine/core';
import { IconAdjustments } from "@tabler/icons-react";

const linksData = [
  {
    label: "Admin Panel",
    to: "/dashboard/admin-page",
    icon: <IconAdjustments />
  },
  {
    label: "Home",
    to: "/dashboard/welcome",
    icon: <HomeIcon />
  },
  {
    label: "Driver log",
    to: "/dashboard/driver-log",
    icon: <DriverLogIcon />
  },
  // { // DEMO only
  //   label: "Dispatch Panel",
  //   to: "/dashboard/dispatch-panel/",
  //   icon: <ReportsIcon />,
  //   subLinks: [
  //     { label: "Dispatch", to: "/dashboard/dispatch/" },
  //     { label: "My Drivers", to: "/dashboard/my-drivers/" },
  //     { label: "My Trucks", to: "/dashboard/my-trucks/" },
  //     { label: "My Loads", to: "/dashboard/my-loads/" }
  //   ]
  // },
  {
    label: "Inspection logs",
    to: "/dashboard/inspection-logs/",
    icon: <InspectionLogsIcon />
  },
  {
    label: "Live Tracking",
    to: "/dashboard/live-tracking",
    icon: <LiveTrackingIcon />
  },
  {
    label: "Reports",
    to: "/dashboard/reports",
    icon: <ReportsIcon />,
    subLinks: [
      { label: "Driver logs", to: "/dashboard/driver-logs" },
      { label: "Unidentified driving", to: "/dashboard/unidentified-driving" },
      { label: "DVIR", to: "/dashboard/dvir" },
      { label: "IFTA", to: "/dashboard/ifta" },
      { label: "Hos violations", to: "/dashboard/hos-violations" },
      { label: "HOS Timers", to: "/dashboard/hos-timers" },
      { label: "Events origin", to: "/dashboard/events-origin" }
    ]
  },
  {
    label: "Administration",
    to: "/dashboard/administration",
    icon: <AdministrationIcon />,
    subLinks: [
      { label: "Companies", to: "/dashboard/companies" },
      { label: "Drivers", to: "/dashboard/drivers" },
      { label: "Vehicles", to: "/dashboard/vehicles" },
      { label: "Devices", to: "/dashboard/devices" },
      { label: "Web users", to: "/dashboard/web-users" }
    ]
  },
  {
    label: "Support",
    to: "/dashboard/support",
    icon: <SupportPanelIcon />,
    subLinks: [
      { label: "ELD Logs", to: "/dashboard/support/ELDLogs" },
      { label: "ELD Log", to: "/dashboard/support/ELDLog" },
      { label: "Devices", to: "/dashboard/devices" },
      { label: "Customers", to: "/dashboard/customers" },
      { label: "Output files history", to: "/dashboard/output-files-history" },
      { label: "User switch", to: "/dashboard/user-switch" }
    ]
  },
  {
    label: "Rules",
    to: "/dashboard/rules",
    icon: <RulesIcon />
  },
  {
    label: "Help",
    to: "/dashboard/help",
    icon: <HelpIcon />,
    subLinks: [
      { label: "Report a problem", to: "/dashboard/report-a-problem" },
      { label: "Logify ELD Documentation", to: "/dashboard/logify-eld-documentation" }
    ]
  }
]


const SidebarLink = ({ link, isActive }: any) => (
  <NavLink
    key={link.to}
    component={Link}
    to={link.to}
    label={link.label}
    className={`sidebarNavLink${isActive ? " activeIcon" : ""}`}
    icon={link.icon}
    childrenOffset={28}
  >
    {link.subLinks &&
      link.subLinks.map((subLink: any) => (
        <NavLink
          key={subLink.to}
          component={Link}
          to={subLink.to}
          label={subLink.label}
          className="sidebarNavLink sidebarNavSubLink"
        />
      ))}
  </NavLink>
);

const Sidebar = () => {
  const location = useLocation();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Drawer 
      opened={opened} 
      onClose={close} 
      size="xs" 
      withCloseButton={false}
      styles={{
        content: { backgroundColor: '#262626' },
    }}
      >
        <div className="sidebar">
          <div className="sidebarLogo">
            <img
              src="../images/logifyLogo.svg"
              alt=""
              className="sidebarLogoImage"
            />
          </div>
          {linksData.map((link) => (
            <SidebarLink
              key={link.to}
              link={link}
              isActive={link.to === location.pathname}
            />
          ))}
        </div>
      </Drawer >
      <Group position="right" style={{ position: "fixed", top: "8px", left: "0px", zIndex: "5" }}>
        <Button onClick={open}>{`<<<`}</Button>
      </Group>
    </>
  );
};

export default Sidebar;
