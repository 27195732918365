import { notifications } from "@mantine/notifications";
import { AxiosResponse } from "axios";
import { Device, DeviceCreate, DeviceTable } from "./Devices.types";
import api from "../../Login/Authentication";
import { modals } from "@mantine/modals";

export async function getDevices(): Promise<DeviceTable[]> {
  try {
    notifications.show({
      id: 'Updating',
      title: 'Retrieving devices data',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });

    const devicesResponse: AxiosResponse<DeviceTable[]> = await api.get('/api/v1/device');
    const devicesTable = devicesResponse.data;

    notifications.hide('Updating');
    notifications.show({
      title: 'Done',
      message: 'Device list updated successfully.',
      color: 'teal',
    });

    return devicesTable;
  } catch (error: unknown) {
    console.error('Error fetching devices list:', error);
    notifications.hide('Updating');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while updating devices list.',
      color: 'red',
    });
    throw error;
  }
}

export async function handleDeleteDeviceSubmit(selectedDevice: Device) {
  try {
    notifications.show({
      id: 'DeletingDevice',
      title: 'Removing device from company',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });
    console.log('selectedDevice', selectedDevice);
    
    await api.delete(`/api/v1/device/${selectedDevice.id}`);
    console.log('delete device', selectedDevice);
    notifications.hide('DeletingTruck');

    notifications.show({
      title: 'Done!',
      message: 'Device deleted successfully. Page will be reloaded.',
      color: 'teal',
    });
    modals.closeAll();

    setTimeout(() => {
      window.location.reload();
    }, 2000);

  } catch (error: unknown) {
    console.error('Error deleting device:', error);
    notifications.hide('DeletingDevice');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while removing the truck.',
      color: 'red',
    });
    throw error;
  }
}

export const handleCreateDeviceSubmit = async (device: DeviceCreate) => {
  try {
    notifications.show({
      id: 'CreatingDevice',
      title: 'Creating device',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });

    const deviceReadyToSubmit = {
      // TODO add all fields
      IMEI: device.imei,
      SerialNumber: device.serialnumber,
      MSISDN: device.msisdn,
    }

    await api.post(`/api/v1/device/`, deviceReadyToSubmit);

    notifications.hide('CreatingDevice');

    notifications.show({
      title: 'Done!',
      message: 'Device added successfully. Page will be reloaded.',
      color: 'teal',
    });
    modals.closeAll();

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error: unknown) {
    console.error('Error creating device:', error);
    notifications.hide('CreatingDevice');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while updating the company.',
      color: 'red',
    });
    throw error;
  }
}
export const handleEditDeviceSubmit = async (selectedDevice: Device) => {
  // TODO: if company is different what should be done next - create clone of a device? Create isActive status?
  try {
    notifications.show({
      id: 'CreatingDevice',
      title: 'Creating device',
      message: 'Please wait...',
      loading: true,
      autoClose: false,
    });

    await api.get(`/api/v1/company/${selectedDevice.assignedcompanyid}`);
    console.log('selectedDevice', selectedDevice);

    const deviceReadyToSubmit = {
      IMEI: selectedDevice.imei,
      SerialNumber: selectedDevice.serialnumber,
      MSISDN: selectedDevice.msisdn,
    }

    await api.put(`/api/v1/device/${selectedDevice.id}`, deviceReadyToSubmit);

    notifications.hide('CreatingDevice');

    notifications.show({
      title: 'Done!',
      message: 'Device added successfully. Page will be reloaded.',
      color: 'teal',
    });
    modals.closeAll();

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  } catch (error: unknown) {
    console.error('Error creating device:', error);
    notifications.hide('CreatingDevice');

    notifications.show({
      title: 'Error',
      message: 'An error occurred while updating the company.',
      color: 'red',
    });
    throw error;
  }
}
