const DriverLogLegend = () => {
    return (
        <div className="legendContainer">
            <div className="legendItem" style={{background: "#3192e0"}}>original event</div>
            <div className="legendItem" style={{background: "#a6ac47"}}>personal conv.</div>
            <div className="legendItem" style={{background: "#3ace34"}}>yard moves</div>
            <div className="legendItem" style={{background: "#20f732"}}>engine start</div>
            <div className="legendItem" style={{background: "#f7051d"}}>engine stop</div>
        </div>
    )
}
export default DriverLogLegend