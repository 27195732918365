import { autorun, makeAutoObservable } from 'mobx';
import { injectStores } from '@mobx-devtools/tools';
import { CompanySettings } from '../components/Administration/Companies/Companies.types';

class CompanyStore {
  private activeCompany: CompanySettings | null = null;

  constructor() {
    makeAutoObservable(this);
    this.initializeActiveCompany();

    autorun(() => {
      if (this.activeCompany) {
        localStorage.setItem('activeCompany', JSON.stringify(this.activeCompany));
      } else {
        localStorage.removeItem('activeCompany');
      }
    });
  }

  private initializeActiveCompany = () => {
    const activeCompanyData = localStorage.getItem('activeCompany');
    if (activeCompanyData) {
      this.activeCompany = JSON.parse(activeCompanyData) as CompanySettings;
    }
  }

  setActiveCompany = (company: CompanySettings | null) => {
    this.activeCompany = company;
    if (company) {
      localStorage.setItem('activeCompany', JSON.stringify(company));
    } else {
      localStorage.removeItem('activeCompany'); 
    }
  }

  getActiveCompany = (): CompanySettings | null => {
    return this.activeCompany;
  }
}

const companyStore = new CompanyStore();
injectStores({ companyStore });

export default companyStore;
