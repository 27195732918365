import React from 'react';
import { Modal, Button, Text, Flex } from '@mantine/core';

interface ModalsProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    content: React.ReactNode; 
    confirmText: string;
    cancelText: string;
}

const Modals: React.FC<ModalsProps> = ({ isOpen, onClose, onConfirm, title, content, confirmText, cancelText }) => {
    return (
        <Modal title={title} opened={isOpen} onClose={onClose}>
            <Flex mih={50}
                gap="xl"
                justify="center"
                align="center"
                direction="row"
                wrap="wrap">
                <Text size="lg">{content}</Text>
                <Button onClick={onConfirm} color="red">
                    {confirmText}
                </Button>
                <Button onClick={onClose} color="blue">
                    {cancelText}
                </Button>
            </Flex>
        </Modal>
    );
};

export default Modals;