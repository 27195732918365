import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { Button, Select } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { DriverLogContext } from "./DriverLog";
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc);
dayjs.extend(timezone);



const DriverLogDatePicker = () => {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);  //should be memoized or stable
  const [selectData, setSelectData] = useState<{ value: string; label: string; }[]>([]);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
  const [selectError, setSelectError] = useState(false); // New state for select input error
  const [datePickerError, setDatePickerError] = useState(false); // New state for date picker input error
  const [loading, setLoading] = useState(true);

  const maxDaysInRange = 8;
  const { driversList } = useContext(DriverLogContext);

  useEffect(() => {
    const fetchDriversList = () => {
      try {
        if (driversList) {
          setSelectData(driversList.map((driver) => ({ value: driver.id, label: `${driver.lastName} ${driver.firstName}` })));
        }
      } catch (error) {
        console.error('Error fetching drivers list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDriversList();
  }, [driversList]);

  const handleRangeChange = (newRange: [Date | null, Date | null]) => {
    if (newRange[0]) {
      const start = dayjs(newRange[0]);
      const end = newRange[1] ? dayjs(newRange[1]) : start;
      const difference = end.diff(start, 'day');

      if (difference <= maxDaysInRange - 1) {
        setValue([newRange[0], newRange[1]]);
      } else {
        setValue([newRange[0], start.add(maxDaysInRange - 1, 'day').toDate()]);
      }
    } else {
      setValue([null, null]);
    }
    setSelectError(false);
    setDatePickerError(false);
  };

  const formatStartDate = (dateString: string) => {
    return dayjs(dateString).format('YYYY-MM-DD') + 'T00:00:00.000Z';
  }

  const formatEndDate = (dateString: string) => {
    return dayjs(dateString).format('YYYY-MM-DD') + 'T23:59:59.999Z';
  }

  const handleShowRange = (): void => {
    const startDate = value[0] ? value[0].toLocaleDateString() : '';
    const endDate = value[1] ? value[1].toLocaleDateString() : '';

    if (!selectedDriver) {
      setSelectError(true); // Set select input error to true if it's empty
    } else {
      setSelectError(false); // Reset select input error
    }

    if (!startDate || !endDate) {
      setDatePickerError(true); // Set date picker input error to true if either is empty
    } else {
      setDatePickerError(false); // Reset date picker input error
    }

    if (startDate && endDate && selectedDriver) {
      window.location.href = `/dashboard/driver-log?id=${selectedDriver}&to=${formatEndDate(endDate)}&from=${formatStartDate(startDate)}`;
    }
  };

  return (
    <div className="driverLogDatePicker">
      <Select
        className="driverLogDatePickerItem"
        label="Select driver"
        data={selectData}
        disabled={loading}
        searchable
        clearable
        value={selectedDriver}
        onChange={(newValue) => {
          setSelectedDriver(newValue);
          setSelectError(false); // Reset select input error when a value is selected
        }}
        placeholder={selectError ? "Please select a driver" : "Enter driver's name"}
        error={selectError}
      />
      <DatePickerInput
        clearable
        allowSingleDateInRange
        maxDate={new Date()}
        className="driverLogDatePickerItem"
        type="range"
        value={value}
        onChange={handleRangeChange}
        label={`Select range (max ${maxDaysInRange} days)`}
        placeholder={datePickerError ? "Please select a date range" : "Enter date range"}
        error={datePickerError}
      />
      <Button className='driverLogDatePickerItem' onClick={handleShowRange} color="green">{`Show range`}</Button>
    </div>
  )
}

export default DriverLogDatePicker;
