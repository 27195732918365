import { useContext,useEffect,useState } from 'react';
import { jsPDF } from 'jspdf';
import { Button } from '@mantine/core';
// import { parseELDDataToTableFormat } from '../../utils/driverLogProcessor';
import { DriverLogContext } from './DriverLog';
import { mantineTableData } from './DriverLogChartTable';

const PdfGenerator = () => {
  const [tableData,setTableData] = useState([]);
  const { driverLog } = useContext(DriverLogContext);

  useEffect(() => {
    async function fetchData() {
      if (driverLog && mantineTableData) {
        // const formattedData = await parseELDDataToTableFormat(driverLog);
        const formattedData = []
        if (formattedData.length > 0) {
          formattedData.pop(); // Delete last row in the table
        }
        setTableData(formattedData);
      }
    }

    fetchData();
  },[driverLog]);

  const handleExportPDF = async () => {
    const doc = new jsPDF();

    const data = {
      'Driver Id': `${driverLog?.truck?.driverFirstName.toLowerCase()}${driverLog?.truck.driverLastName.toLowerCase()}@gmail.com`,
      'Driver': `${driverLog?.truck?.driverFirstName} ${driverLog?.truck.driverLastName}`,
      'License state': 'Florida',
      'Driver license number': 'E220-000-69-183-0',
      'Co-Driver': 'None',
      'Co-Driver Id': 'None',
      'Carrier': 'Sirius Logistics Inc',
      'Main office': '1685 Fleetwood Drive',
      '24-hour Period Start Time': '00:00:00',
      'Time zone': 'Central Standard Time',
      'Truck Tractor Id': '9862',
      'Truck Tractor Vin': '3AKJGLDR0HSHW9862',
      'Trailers': '53102',
      'Shipping documents': '0266631-0002',
      'Total miles driving': '692'
    };

    const currentFontName = "Helvetica"
    const currentFontStyle = "normal"
    const boldFontWeight = 'bold';
    const normalFontWeight = 'normal';

    const chartInstance = window.Apex._chartInstances.find(
      (chart) => chart.id === "driverlog"
    );
    const base64Chart = await chartInstance.chart.dataURI();
    const base64SignatureImage = window.getComputedStyle(document.querySelector('.driverLogSignature')).backgroundImage.replace('url("','').slice(0,-2);

    doc.setFont(currentFontName,currentFontStyle,boldFontWeight);
    doc.setFontSize(20);
    doc.text(`Driver's daily log ${tableData["Date"]}`,61,10);
    doc.setFont(currentFontName,currentFontStyle,normalFontWeight);
    doc.setFontSize(14);
    doc.text('USA 70 Hour / 8 day (Property)',73,17);

    doc.setFontSize(10);
    doc.setFont(currentFontName,currentFontStyle,boldFontWeight);
    doc.text('Driver Id:',10,30)
    doc.text('Driver:',10,35)
    doc.text('License state:',10,40)
    doc.text('Driver license number:',10,45)
    doc.text('Co-Driver:',10,50)
    doc.text('Co-Driver Id:',10,55)
    doc.text('Carrier:',10,60)
    doc.text('Main office:',10,65)

    doc.text('24-hour Period Start Time:',105,30)
    doc.text('Time zone:',105,35)
    doc.text('Truck Tractor Id:',105,40)
    doc.text('Truck Tractor Vin:',105,45)
    doc.text('Trailers:',105,50)
    doc.text('Shipping documents:',105,55)
    doc.text('Co-Driver Id:',105,60)
    doc.text('Total miles driving:',105,65)

    doc.setFont(currentFontName,currentFontStyle,normalFontWeight);
    doc.text(data['Driver Id'],57,30)
    doc.text(data['Driver'],57,35)
    doc.text(data['License state'],57,40)
    doc.text(data['Driver license number'],57,45)
    doc.text(data['Co-Driver'],57,50)
    doc.text(data['Co-Driver Id'],57,55)
    doc.text(data['Carrier'],57,60)
    doc.text(data['Main office'],57,65)

    doc.text(data['24-hour Period Start Time'],155,30)
    doc.text(data['Time zone'],155,35)
    doc.text(data['Truck Tractor Id'],155,40)
    doc.text(data['Truck Tractor Vin'],155,45)
    doc.text(data['Trailers'],155,50)
    doc.text(data['Shipping documents'],155,55)
    doc.text(data['Co-Driver Id'],155,60)
    doc.text(data['Total miles driving'],155,65)

    doc.addImage(base64Chart.imgURI,'PNG',10,75,190,50);

    let startYPosition = 130; // Starting vertical position for placing the table

    // Calculate the height of the table to set the next vertical position
    const tableHeight = doc.previousAutoTable.finalY; // Getting the vertical position after the table is completed
    const pageHeight = doc.internal.pageSize.height;
    const remainingSpace = pageHeight - tableHeight;

    // If the occupied space on the page after the table is less than the height of the table, go to a new page
    if (remainingSpace < tableHeight) {
      doc.addPage(); // Adding a new page
      startYPosition = 10; // Starting vertical position for the new page
    }

    // Generate a table for the following pages with the correct vertical position
    doc.autoTable({
      styles: {
        halign: 'center',
        tableLineColor: 200,
        fontSize: 10,
        cellPadding: 1,
        head: {
          fontSize: 12,
          fontStyle: 'bold',
        },
      },
      columnStyles: {
        0: {
          cellWidth: 15,
          halign: 'left',
        },
        1: {
          cellWidth: 25,
        },
        2: {
          cellWidth: 30
        },
        3: {
          cellWidth: 20,
        },
        4: {
          cellWidth: 25
        },
        5: {
          cellWidth: 40,
          fontSize: 8
        },
        6: {
          fontSize: 8
        },
      },
      startY: startYPosition,
      head: [['Status','Start Time','Date','Odometer','Duration','Location','Note']],
      body: tableData.map((log) => [
        log.status,
        log.startTime,
        log.date,
        log.odometer,
        log.duration,
        log.location.props.children,
        log.note,
      ]),
    });

    doc.text('Signature',140,285)
    doc.addImage(base64SignatureImage,'jpeg',150,270,50,20);

    doc.save(`${data['Driver']}_${tableData["Date"]}_log.pdf`);
  };
  return (
    <Button
      id="getPdf"
      onClick={handleExportPDF}
      variant="gradient"
      gradient={{ from: 'blue',to: 'grape',deg: 262 }}
    >
      Download report
    </Button>
  );
}

export default PdfGenerator;
