import states from "../../../data/USstates";
import timezones from "../../../data/timezones";
import api from "../../Login/Authentication";
import { CompanyAddSettings, CompanySettings } from "./Companies.types";
import { notifications } from "@mantine/notifications";

export function getInitialValues(companies: CompanySettings[]) {
    return companies.map((company) => ({
        id: company.id,
        dotNumber: company.dotNumber,
        city: company.city,
        zipCode: company.zipCode,
        carrierName: company.carrierName,
        address: company.address,
        state: company.state,
        timeZone: company.timeZone,
        contact: company.contact,
    }));
}

export const validateSubmitForm = (values: CompanySettings, currentIndex: number) => {
    const errors: any = {};

    // DotNumber Validation
    // if (values.dotNumber !== companies[currentIndex].dotNumber) {
    //     errors.dotNumber = "You can`t change DOT number";
    // }

    // CarrierName Validation
    if (values.carrierName.length < 3) {
        errors.carrierName = "CarrierName must be at least 3 characters";
    }

    // Address Validation
    if (values.address.length < 3) {
        errors.address = "Address must be at least 3 characters";
    }
    // City Validation
    if (values.city.length < 3) {
        errors.city = "City must be at least 3 characters";
    }

    // State Validation
    const validStates = states.map((state) => state.value);
    if (!validStates.includes(values.state)) {
        errors.state = "State must be in XX format";
    }

    // Time Zone Validation
    const validTimeZones = timezones.map((zone) => zone.value);
    if (!validTimeZones.includes(values.timeZone)) {
        errors.timeZone = "Invalid Time Zone";
    }
    // ZipCode Validation
    if (!/^\d+$/.test(values.zipCode)) {
        errors.zipCode = "ZipCode must contain only numbers";
    }

    return errors;
};



export const handleUpdateCompanySubmit = async (values: CompanySettings[], currentIndex: number, companies: CompanySettings[]) => {
    try {
        const currentTabValues = values[currentIndex];
        const errors = validateSubmitForm(currentTabValues, currentIndex);

        if (Object.keys(errors).length > 0) {
            throw new Error("Validation errors occurred");
        }

        notifications.show({
            id: 'Updating company',
            title: 'Updating company',
            message: 'Please wait...',
            loading: true,
            autoClose: false,
        });

        const response = await api.put(`/api/v1/company/${currentTabValues.id}`, currentTabValues);

        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        localStorage.removeItem('userData');
        const { companies, ...restUserData } = userData;
        const updatedUserData = {
            ...restUserData,
            companies: response.data
        };
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        setTimeout(() => {
            window.location.reload();
        }, 2000);

        notifications.hide('Updating company');

        notifications.show({
            title: 'Company updated',
            message: `Company updated successfully. Page will reloaded`,
            color: 'teal',
        });

        console.log(response);
    } catch (error) {
        notifications.hide('Updating company');

        notifications.show({
            title: 'Error',
            message: 'An error occurred while updating the company.',
            color: 'red',
        });

        console.error(error);
    }
}


export const handleCreateCompanySubmit = async (values: CompanyAddSettings) => {
    try {
        notifications.show({
            id: 'Creating company',
            title: 'Creating company',
            message: 'Please wait...',
            loading: true,
            autoClose: false,
        });

        const response = await api.post(`/api/v1/company`, values);

        const userData = JSON.parse(localStorage.getItem('userData') || '{}')
        localStorage.removeItem('userData');
        const { companies, ...restUserData } = userData;
        const updatedUserData = {
            ...restUserData,
            companies: response.data
        };
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        setTimeout(() => {
            window.location.reload();
        }, 2000);

        notifications.hide('Creating company');

        notifications.show({
            title: 'Company created',
            message: `Company created successfully. Page will reloaded`,
            color: 'teal',
        });

        console.log(response);
    } catch (error) {
        notifications.hide('Creating company');

        notifications.show({
            title: 'Error',
            message: 'An error occurred while creating the company.',
            color: 'red',
        });

        console.error(error);
    }
}

export const handleDeleteCompanySubmit = async (company: CompanySettings) => {
    try {
        notifications.show({
            id: 'Deleting company',
            title: 'Deleting company',
            message: 'Please wait...',
            loading: true,
            autoClose: false,
        });

        const response = await api.delete(`/api/v1/company/${company.id}`);

        const userData = JSON.parse(localStorage.getItem('userData') || '{}')
        localStorage.removeItem('userData');
        const { companies, ...restUserData } = userData;
        const updatedUserData = {
            ...restUserData,
            companies: response.data
        };
        localStorage.setItem('userData', JSON.stringify(updatedUserData));
        setTimeout(() => {
            window.location.reload();
        }, 2000);

        notifications.hide('Deleting company');

        notifications.show({
            title: 'Company deleted',
            message: `${company.carrierName} deleted successfully. Page will be reloaded.`,
            color: 'teal',
        });

        console.log(response);
    } catch (error) {
        notifications.hide('Deleting company');

        notifications.show({
            title: 'Error',
            message: 'An error occurred while deleting the company.',
            color: 'red',
        });

        console.error(error);
    }
}

