export const getBackgroundGradient = (status: string): string => {
  switch (status) {
    case 'OFF':
      return 'rgba(235,235,235,0.36)';
    case 'ON':
      return 'rgba(0,134,255,0.2)';
    case 'DRV':
      return 'rgba(0,255,64,0.22)';
    case 'SB':
      return 'rgba(255,183,0,0.36)';
    default:
      return 'rgba(255,0,0,0.35)';
  }
};