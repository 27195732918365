import { useEffect, useMemo, useState } from "react";
import {
    MantineReactTable,
    useMantineReactTable,
    type MRT_ColumnDef,
} from "mantine-react-table";
import { Paper, MultiSelect, Button, Box, Drawer, LoadingOverlay } from "@mantine/core";
import api from "../Login/Authentication";
import { useDisclosure } from "@mantine/hooks";

type TruckType = {
    companyName: string;
    supervisor: string;
    dispatcher: string
    id: string;
    driverFullName: string;
    currentStatus: string;
    currentSpeed: number;
    longitude: number;
    latitude: number;
    time?: string
};
export const eventCodes: EventCodesType = {
    'on_duty': 'ON',
    'OFF': 'OFF',
    'SB': 'SB',
    'driving': 'DRV',

    'PC': 'PC',
    'YM': 'YM',
    'ignition_stop': 'PD',
    'ignition_start': 'PU',
    'login': 'LI',
    'test_event': 'TE'
};

type EventCodesType = {
    [key: string]: string;
};

const Example = ({ updateFilteredData, setTruckCoordinates }: any) => {
    const [data, setData] = useState<TruckType[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState<string[]>([]);
    const [selectedSupervisor, setSelectedSupervisor] = useState<string[]>([]);
    const [selectedDispatcher, setSelectedDispatcher] = useState<string[]>([]);
    const [selectedDriverName, setSelectedDriverName] = useState<string[]>([]);
    const [selectedTruckId, setSelectedTruckId] = useState<string[]>([]);
    const [tableContainerVisible, setTableContainerVisible] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const [buttonText, setButtonText] = useState('Open table');
    const [buttonColor, setButtonColor] = useState('green');

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await api.get('/api/v1/livemap');
                const responseData = response.data;
                const companiesResponse = await api.get('/api/v1/company');
                const companysArray = companiesResponse.data.map((company: any) => String(company.id));
                setSelectedCompany(companysArray);

                const updatedData = responseData.map((item: any) => ({
                    companyName: String(item.AssignedCompanyId),
                    supervisor: "none", // add supervisor name when available
                    dispatcher: "none", // add dispatcher name when available
                    id: item.AssignedDriverId,
                    driverFullName: `${item.FirstName} ${item.LastName}` || 'none',
                    currentStatus: item.Event || 'UNKNOWN',
                    currentSpeed: item.Speed,
                    longitude: item.Longitude,
                    latitude: item.Latitude,
                    time: item.Servertime,
                    ...item
                }));
                setData(updatedData);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        

    useEffect(() => {
        if (opened) {
            fetchData();
        }
    }, [opened]);

    useEffect(() => {
            fetchData();
    }, []);
    
    const filteredData = useMemo(() => {
        return data.filter(
            (truck) =>
                (selectedCompany.length === 0 ||
                    selectedCompany.includes(truck.companyName)) &&
                (selectedSupervisor.length === 0 ||
                    selectedSupervisor.includes(truck.supervisor)) &&
                (selectedDispatcher.length === 0 ||
                    selectedDispatcher.includes(truck.dispatcher)) &&
                (selectedDriverName.length === 0 ||
                    selectedDriverName.includes(truck.driverFullName)) &&
                (selectedTruckId.length === 0 ||
                    selectedTruckId.includes(truck.id))
        );
    }, [selectedCompany, selectedSupervisor, selectedDispatcher, selectedDriverName, selectedTruckId]);

    useEffect(() => {
        // Update the parent component with the filtered data
        updateFilteredData(filteredData);
    }, [filteredData, updateFilteredData]);

    const handleButtonClick = () => {
        if (opened) {
            close();
            setButtonText('Open table');
            setButtonColor('green');
        } else {
            open();
            setButtonText('Close table');
            setButtonColor('red');
        }
    };

    const columns = useMemo<MRT_ColumnDef<TruckType>[]>(
        () => [
            {
                accessorKey: "companyName",
                header: "Company",
                size: 0
            },
            {
                accessorKey: "supervisor",
                header: "Supervisor",
                size: 0
            },
            {
                accessorKey: "dispatcher",
                header: "Dispatcher",
                size: 0
            },
            {
                accessorKey: "currentStatus",
                header: "Status",
                size: 0,
                Cell: ({ cell }) => (
                    <Box
                        className="statusCell"
                        sx={() => ({
                            backgroundColor:
                                cell.getValue<string>() === "driving"
                                    ? "#5AC534"
                                    : cell.getValue<string>() === "SB"
                                        ? "#FFCE21"
                                        : cell.getValue<string>() === "OFF"
                                            ? "#000000"
                                            : cell.getValue<string>() === "on_duty"
                                                ? "#0153B4"
                                                : cell.getValue<string>() === "test"
                                                    ? "#fc030f"
                                                    : "#e37d7d",
                            borderRadius: '4px',
                            color: '#fff',
                            maxWidth: '9ch',
                            padding: '4px',
                        })}
                    >
                        {eventCodes[cell.getValue<string>()] || 'n/a'}
                    </Box>
                ),
            },
            {
                accessorKey: "id",
                header: "id",
                size: 0
            },
            {
                accessorKey: "driverFullName",
                header: "Drivers name",
                size: 0
            },
            {
                accessorKey: "currentSpeed",
                header: "Speed",
                size: 0
            },
        ],
        []
    );

    const table = useMantineReactTable({
        columns,
        state: {
            isLoading: isLoading
        },
        data: filteredData, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        initialState: {
            density: 'xs',
            columnVisibility: {
                id: false
            }
        },
        // enableHiding: false,
        enablePagination: false,
        mantineTableContainerProps: { sx: { maxHeight: '500px' } },
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                setTruckCoordinates(row.original)
            },
            sx: {
                cursor: 'pointer',
            },
        }),
    });

    return (
        <>
            <div style={{ position: 'absolute', top: '70px', left: '180px', zIndex: 200 }}>
                <Button color={buttonColor} onClick={handleButtonClick}>{buttonText}</Button>
            </div>
            <Drawer
                opened={opened}
                onClose={handleButtonClick}
                position='bottom'
                size="80%"
                withCloseButton
                withOverlay={false}
                styles={{
                    inner: { maxWidth: '70%', zIndex: 100 },
                    body: { padding: 0, backgroundColor: 'white', height: '100%' },
                    header: { width: '50px', position: 'absolute', top: '0', right: '0px', zIndex: 1010 },
                }}
            >
                <Paper>
                    <div
                        style={{
                            display: "flex",
                            paddingBottom: "15px",
                            justifyContent: "space-between",
                            maxWidth: '95%',
                        }}
                    >
                        <MultiSelect
                            data={Array.from(new Set(data.map((truck) => truck.companyName)))}
                            label="Company"
                            placeholder="Select company"
                            searchable
                            value={selectedCompany}
                            onChange={(newValues) => setSelectedCompany(newValues)}
                            nothingFound="Nothing found"
                            style={{
                                padding: "15px",
                                width: "200px"
                            }}
                        />
                        <MultiSelect
                            data={Array.from(
                                new Set(data.map((truck) => truck.supervisor))
                            )}
                            label="Supervisor"
                            placeholder="Select Supervisor"
                            searchable
                            value={selectedSupervisor}
                            onChange={(newValues) => setSelectedSupervisor(newValues)}
                            nothingFound="Nothing found"
                            style={{
                                padding: "15px",
                                width: "200px"
                            }}
                        />
                        <MultiSelect
                            data={Array.from(
                                new Set(data.map((truck) => truck.dispatcher))
                            )}
                            label="Dispatcher"
                            placeholder="Select Dispatcher"
                            searchable
                            value={selectedDispatcher}
                            onChange={(newValues) => setSelectedDispatcher(newValues)}
                            nothingFound="Nothing found"
                            style={{
                                padding: "15px",
                                width: "200px"
                            }}
                        />
                        <MultiSelect
                            data={Array.from(
                                new Set(data.map((truck) => truck.driverFullName))
                            )}
                            label="Drivers Name"
                            placeholder="Select Driver"
                            searchable
                            value={selectedDriverName}
                            onChange={(newValues) => setSelectedDriverName(newValues)}
                            nothingFound="Nothing found"
                            style={{
                                padding: "15px",
                                width: "200px"
                            }}
                        />
                        <MultiSelect
                            data={Array.from(
                                new Set(data.map((truck) => truck.id))
                            )}
                            label="Truck ID"
                            placeholder="Select truck ID"
                            searchable
                            value={selectedTruckId}
                            onChange={(newValues) => setSelectedTruckId(newValues)}
                            nothingFound="Nothing found"
                            style={{
                                padding: "15px",
                                width: "200px"
                            }}
                        />
                    </div>
                    <Box pos="relative">
                        <LoadingOverlay visible={isLoading} loaderProps={{ children: 'Loading...' }} />
                        <MantineReactTable table={table} />
                    </Box>
                </Paper>
            </Drawer>
        </>
    );
};

export default Example;
