import { useForm } from '@mantine/form';
import { Button, Group, TextInput, Box, Flex, Select, Paper, PasswordInput, Checkbox } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';
import { webUsers, WebUser } from '../../../mockDB/webusers';

function WebUserPage() {
  const { id } = useParams()
  const webUser = webUsers.find((user) => {
    if (id) {
      return user.id === +id;
    }
    return false;
  });
  const form = useForm<WebUser>({ initialValues: webUser });

  const handleSelectChange = (fieldName: string, value: boolean) => {
    form.setFieldValue(fieldName, value);
  };
  
  if (!webUser) {
    return (
      <Flex mih={50} gap="md" justify="center"
        align="center"
        direction="column"
        wrap="wrap">
        <div>
          <h1>User not found</h1>
        </div>
        <div>
          <Link to={`/dashboard/web-users`}>
            <Button size="md" type="submit">
              Back to table
            </Button>
          </Link>
        </div>
      </Flex>
    );
  }

  const handleSubmit = (values: WebUser) => {
    console.log(values);
  };

  return (
    <div className="tableContainer">
      <Flex mih={50} gap="md" align="center">
        <h1 className="titleDashboard">User Settings</h1>
        <Link to={`/dashboard/web-users`}>
          <Button size="xs" type="submit">
            Back to table
          </Button>
        </Link>
      </Flex>
      <Box className="web-users-table" component="form" mt={30} maw={1000} mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
        <Flex
          direction={{ base: 'column', sm: 'row' }}
          gap={{ base: 'sm', sm: 'lg' }}
          justify={{ sm: 'center' }}
        >
          <Paper shadow="xs" p="xl" withBorder >
            <h3>User info</h3>
            <Flex
              direction={{ base: 'column', xs: 'row' }}
              gap={{ base: 'sm', sm: 'lg' }}
              justify={{ sm: 'center' }}
              align={{ base: 'start' }}
              maw={450}
            >
              <Group className="vehicles-table-column" w={250}>
                <TextInput w={'100%'} label="User name:"  {...form.getInputProps('webUserName')} />
                <TextInput w={'100%'} label="First name:"  {...form.getInputProps('firstName')} />
                <PasswordInput w={'100%'} label="New password:"  {...form.getInputProps('password')} />
                <TextInput w={'100%'} label="Contact:"  {...form.getInputProps('contact')} />
                <Checkbox
                  defaultChecked={form.values.reseller}
                  onChange={() => handleSelectChange('reseller', !form.values.reseller)}
                  label="Reseller"
                />
              </Group>
              <Group className="vehicles-table-column" w={250}>
                <Select w={'100%'}
                  label="Group name:"
                  placeholder="Pick one"
                  data={['sirius']}
                  defaultValue={webUser.groupName}
                  onChange={(value) => form.setFieldValue('groupName', value)}
                />
                <TextInput w={'100%'} label="Last name:" {...form.getInputProps('lastName')} />
                <PasswordInput w={'100%'} label="Confirm password:"  {...form.getInputProps('passwordConfirm')} />
                <Select w={'100%'}
                  label="Privileges:"
                  placeholder="Pick one"
                  data={[
                    { value: 'fleetManager', label: 'Fleet Manager' },
                    { value: 'liveTracking', label: 'Live tracking' },
                    { value: 'logs', label: 'Logs' },
                    { value: 'supportAdmin', label: 'Support Admin' }
                  ]}
                  defaultValue={webUser.privileges}
                  onChange={(value) => form.setFieldValue('privileges', value)}
                />
              </Group>

            </Flex>
          </Paper>

        </Flex>
        <Group mt={30} style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <Button size="md" color='red' onClick={() => form.reset()}>
            Reset to initial values
          </Button>
          <Button size="md" color='green' type="submit">
            Save and submit
          </Button>
        </Group>
      </Box>
    </div>
  );
}

export default WebUserPage;
