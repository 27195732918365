const timezones = [
    {
      value: "America/New_York",
      label: "(UTC-05:00) Eastern Time",
    },
    {
      value: "America/Chicago",
      label: "(UTC-06:00) Central Time",
    },
    {
      value: "America/Denver",
      label: "(UTC-07:00) Mountain Time",
    },
    {
      value: "America/Phoenix",
      label: "(UTC-07:00) Mountain Time (No DST)",
    },
    {
      value: "America/Los_Angeles",
      label: "(UTC-08:00) Pacific Time",
    },
    {
      value: "America/Anchorage",
      label: "(UTC-09:00) Alaska Time",
    },
    {
      value: "America/Adak",
      label: "(UTC-10:00) Hawaii-Aleutian Time",
    },
    {
      value: "Pacific/Honolulu",
      label: "(UTC-10:00) Hawaii Time",
    },
  ]

  export default timezones