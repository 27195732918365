import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';
import { useMemo } from 'react';
import {
  MantineReactTable,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { webUsers, WebUser } from '../../../mockDB/webusers';

function Vehicles() {
  const data = webUsers

  const columns = useMemo<MRT_ColumnDef<WebUser>[]>(
    () => [
      {
        accessorKey: 'webUserName',
        header: 'User Name',
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
      },
      {
        accessorKey: 'groupName',
        header: 'Group Name',
      },
      {
        accessorKey: 'privileges',
        header: 'Privileges',
      },
      {
        accessorKey: 'id',
        header: 'Actions',
        enableColumnOrdering: false,
        Cell: ({ cell }) => (
          <>
            <Link to={`/dashboard/web-users/web-user/${cell.getValue()}`}>
              <Button size="xs" type="submit" style={{ marginLeft: "5px", marginRight: "5px" }}>
                Edit
              </Button>
            </Link>
            <Button style={{ marginLeft: "5px", marginRight: "5px" }}
              size="xs"
              type="submit"
              color='red'
              onClick={() => handleDelete(cell.getValue())}
            >
              Delete
            </Button>
          </>
        ),
      }
    ],
    [],
  );

  const table = useMantineReactTable({
    columns,
    data,
    initialState: { density: 'xs' },
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <Link to={`/dashboard/web-users/web-user/add-web-user`}>
          <Button style={{ margin: "0 15px" }} color="green">
            Add new user
          </Button>
        </Link>
      </div>
    ),
  });

  const handleDelete = (id: any) => {
    console.log(`user with id:${id} has been delted`);
  };

  return (
    <div style={{ margin: '20px' }}>
      <MantineReactTable table={table} />
    </div>
  )
};

export default Vehicles;

