import {
  TextInput,
  PasswordInput,
  Button,
  Group,
  Box,
  Paper,
  Title,
  Text,
  Alert,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Link, useNavigate } from "react-router-dom";
import LoginWrapper from "./LoginWrapper";
import { IconInfoCircle } from "@tabler/icons-react";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { observer } from 'mobx-react-lite';
import userStore from "../../Store/userStore";
import { isTokenValid } from "./Authentication";
import companyStore from "../../Store/companyStore";

interface FormValues {
  email: string;
  password: string;
}

const Login = observer(() => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const alertCircleicon = <IconInfoCircle />;

  const form = useForm<FormValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : "Invalid email",
      // password: (value) =>
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/.test(value)
      //     ? null
      //     : "Invalid password",
    },
  });

  const handleSubmit = async (values: FormValues) => {
    try {
      notifications.show({
        id: 'setActiveCompany',
        title: 'Logging in...',
        message: 'Please wait...',
        loading: true,
        autoClose: false,
      });

      await userStore.login(values.email, values.password);

      if (userStore.accessToken && isTokenValid(userStore.accessToken)) {
        companyStore.setActiveCompany(userStore.activeCompany);
        navigate('/dashboard/welcome');

        notifications.hide('setActiveCompany');
        if(userStore.activeCompany){
        notifications.show({
          title: 'Login successful!',
          message: `Welcome back to ${userStore.activeCompany.carrierName}!`,
          color: 'teal',
          icon: <IconInfoCircle />,
        });}
      }
    } catch (error: any) {
      console.error('Error during login:', error);

      notifications.hide('setActiveCompany');
      notifications.show({
        title: 'Authentication error!',
        message: 'Something went wrong.',
        color: 'red',
        icon: <IconInfoCircle />,
      });

      if (error.response && error.response.status === 420) {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
      }
    }
  };

  return (
    <LoginWrapper>
      <Box maw={400} mx="auto" className="loginForm-wrapper styles">
        <Paper shadow="sm" p={20}>
          {showAlert ? (
            <Alert variant="light" color="red" title="Authentication error!" withCloseButton icon={alertCircleicon} onClose={() => setShowAlert(false)}>
              Wrong login or password.
            </Alert>
          ) : (
            <Title order={2} align="center" className="loginTitle">
              Login
            </Title>
          )}
          <form className="loginForm" onSubmit={form.onSubmit(handleSubmit)}>
            <TextInput
              placeholder="Email"
              required
              label="Email"
              error={form.errors.email && "Please specify valid email"}
              value={form.values.email}
              onChange={(event) => form.setFieldValue("email", event.currentTarget.value)}
              onBlur={() => form.validateField("email")}
            />
            <PasswordInput
              placeholder="Password"
              required
              label="Password"
              description="Password must be min 8 characters long, contain at least one digit, one uppercase and lowercase letter, and one special character"
              error={form.errors.password && "Please specify valid password"}
              value={form.values.password}
              onChange={(event) => form.setFieldValue("password", event.currentTarget.value)}
              onBlur={() => form.validateField("password")}
            />
            <Group position="center" mt="md">
              <Button type="submit">Submit</Button>
            </Group>
            <Text fz="sm" fw={500}>
              You don't have an account? Go to{" "}
              <Link className="loginLink" to="/signup">
                sign up
              </Link>{" "}
              page.
            </Text>
            <Text fz="sm" fw={500}>
              Forgot password? Go to{" "}
              <Link className="loginLink" to="/resetpassword">
                reset password
              </Link>{" "}
              page.
            </Text>
          </form>
        </Paper>
      </Box>
    </LoginWrapper>
  );
});

export default Login;
