export const initialValues = {
    id: '',
    AssignedCompanyID: null,
    DateOfHiring: null,
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    phone: '',
    DriverLicenseNumber: '',
    DriverLicenseState: '',
    vehicleGroup: '',
    NumberOfLogsShown: '8',
    cargoType: '',
    HoursOfServiceRule: '',
    isAllowedChangeSettings: false,
    enableSendingLogs: false,
    restart: null,
    is30MinBreak: false,
    isAllowYardMove: false,
    isAllowDrivingException: false,
    isAllowPersonalConv: false,
    isExemptDriver: false,
    frequencyOfHOSAlert: null,
    isApproved: false,
    dispatchers: [],
    supervisors: [],
    password: '',
    confirmPassword: '',
    isActive: false
}

export const vehicleGroupData = [{
    value: "companyDriver",
    label: "Company Driver",
},
{
    value: "leaseDriver",
    label: "Lease Driver",
},
{
    value: "ownerOperator",
    label: "Owner Operator",
}]

export const cargoTypeData = [{
    value: "property",
    label: "Property",
}, {
    value: "passenger",
    label: "Passenger",
}, {
    value: "oilAndGas",
    label: "Oil and Gas",
}]

export const ruleData = [{
    value: "usa70h8d",
    label: "USA 70 Hour / 8 day",
}, {
    value: "usa60h7d",
    label: "USA 60 Hour / 7 day",
}]

export const restartData = [{
    value: "24",
    label: "24 Hour restart",
}, {
    value: "34",
    label: "34 Hour restart",
}]

export const HOSAlertData = [{
    value: "never",
    label: "Never",
}, {
    value: "15",
    label: "15 min before violation",
},
{
    value: "30",
    label: "30 min before violation",
},
{
    value: "45",
    label: "45 min before violation",
},
{
    value: "60",
    label: "1 hour before violation",
}]

