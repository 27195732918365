import React from "react";
import "../src/App.css";
import MainRouter from "./navigation/MainRouter";

const App: React.FC = () => {
  return (
      <MainRouter />
  );
};

export default App;
