import { createContext, useContext, useState, useEffect } from 'react';
import ELDLogDonuts from './ELDLogDonuts';
import ELDLogHeader from './ELDLogHeader';
import ELDLogChartTable from './ELDLogChartTable';
import ELDLogTimeChart from './ELDLogTimeChart';
import api from '../../Login/Authentication';

interface Truck {
  driverFirstName: String;
  driverLastName: String;
  id: String;
  companyName: String;
  supervisor: String;
  dispatcher: String;
  latitude: Number
  longitude: Number
  currentStatus: String
  breakHours: String;
  driveHours: String;
  shifrHours: String;
  cycleHours: String;
  engineHours: Number;
  idleTime: Number;
  fuelLevel: Number;
  hoursWorkedToday: String;
  totalHoursSinceLastRestart: String;
  hoursAvailableTomorrow: String;
  currentSpeed: Number;
  VIN: String;
  unit: String;
  deviceNumber: String;
  yearOfProduction: Number;
  ELDserialNumber: String;
  odometer: Number;
  nextService: Number;
  address: String;
  fuelTankVolume: Number;
  coDriver: String
}

interface ELDLog {
  truck: Truck;
  ELDLog: String
}

export const ELDLogContext = createContext<{ ELDLog: ELDLog | null }>({
  ELDLog: null
});

const fetchELDLog = async (id: string, date: string): Promise<ELDLog | null> => {
  try {
    const response = await api.get(`/driverLog?id=${id}&date=${date}`);
    response.data.driverLog = replaceIfEndsWithTheEnd(response.data.driverLog, date)
    
    return response.data;
  } catch (error) {
    console.error('Error fetching ELD log:', error);

    return null;
  }
};

function replaceIfEndsWithTheEnd(inputString: string, date: string) {
  const lines = inputString.split('\n');
  const [dateNow, timeNow] = getCurrentDateTimeInFormat();
  
  if (lines.length < 2) {
    return inputString;
  }
  
  const lastLine = lines[lines.length - 1].trim();
  const previousLine = lines[lines.length - 2].trim();
  
  if (lastLine === "theEnd") {
    const parts = previousLine.split(',');
    
    parts[6] = timeNow;
    parts[5] = dateNow;
    const modifiedString = parts.join(',');
    const res = inputString.replace(/theEnd$/, `${modifiedString}`);

    return res
  } else {
    return inputString;
  }
}

function getCurrentDateTimeInFormat() {
  const currentDate = new Date();
  
  const year = currentDate.getFullYear().toString().slice(-2); 
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');
  
  const dateFormatted = month + day + year;
  const timeFormatted = hours + minutes + seconds;
  
  return [dateFormatted, timeFormatted];
}

const ELDLogProvider = ({ children }: any) => {
  const [ELDLog, setELDLog] = useState<ELDLog | null>(null);
  const urlParams = new URLSearchParams(window.location.search);
  const truckId = urlParams.get('id');
  const date = urlParams.get('date');
  useEffect(() => {
    if (truckId && date) {
      fetchELDLog(truckId, date).then(setELDLog);
    }
  }, []);

  return (
    <ELDLogContext.Provider value={{ ELDLog }}>
      {children}
    </ELDLogContext.Provider>
  );
};

export const useELDLog = () => useContext(ELDLogContext);

const ELDLog = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const truckId = urlParams.get('id');
  const date = urlParams.get('date');

  if (!truckId || !date) {
    window.location.href = '/dashboard/support/ELDLog?id=false&date=false';
    return null;
  }

  return (
    <ELDLogProvider>
      <ELDLogHeader />
      <div className='chart'>
        <ELDLogDonuts />
        <div className='apexContainer'>
          <ELDLogTimeChart />
        </div>
      </div>
      <div className='driverLogTable'>
        <ELDLogChartTable />
      </div>
    </ELDLogProvider>
  );
};

export default ELDLog;
