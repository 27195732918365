import { useContext } from 'react';
import dayjs from 'dayjs';
import { DriverLogContext } from './DriverLog';
import { Link, useLocation } from 'react-router-dom';
import DriverLogDatePicker from './DriverLogDatePicker';
import { Button } from '@mantine/core';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const DriverLogHeader = () => {
    const { driverLog, currentDriver } = useContext(DriverLogContext);
    const query = useQuery();
    const dateTo = dayjs(query.get('to')).utc();
    const dateFrom = dayjs(query.get('from')).utc();
    const formattedDate = dateTo && dateFrom ? `${dateFrom.format('MM/DD/YYYY')} - ${dateTo.format('MM/DD/YYYY')}` : 'No date provided';
    const driver = query.get('id') ? `${currentDriver?.firstName} ${currentDriver?.lastName}` : currentDriver ? currentDriver?.firstName : 'no data'
    const uniqueTruckIds = driverLog ? Array.from(new Set(driverLog.map(item => item.truckid))).join(' ,') : 'no data';
    
    return (
        <div className='driverLogHeader' >
            <div className='driverLogHeaderItem'>
                <div className='driverLogHeaderInfo'>
                    <div className='driverLogHeaderInfoItem'>
                        <h4 className='driverLogTitle'>Driver Log: </h4>
                        <h5 className='driverLogTitle'>{formattedDate}</h5>
                        <h4>Driver: </h4>
                        <h5>{driver}</h5>
                    </div>
                    <div className='driverLogHeaderInfoItem'>
                        <ul className='driverLogList'>
                            <li>Vehicle: <b>{uniqueTruckIds}</b></li>
                            <li>Odometer: <b>lll</b></li>
                            <li>Next oil change: <b>654</b></li>
                            <li>Driver`s phone num: <b>N\A</b></li>
                            <li>Driver`s email: <b>N\A</b></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='driverLogHeaderItem'>
                <DriverLogDatePicker />
            </div>
            <div className='driverLogHeaderItem'>
                <Button className="driverLogReturnBtn" color={'red'}>
                    <Link
                        style={{ textDecoration: 'none', color: '#fff' }}
                        to={'/dashboard/driver-logs'}>
                        Return to logs
                    </Link>
                </Button>
            </div>
        </div>
    )
}
export default DriverLogHeader