import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet } from "react-router-dom"; // Import Outlet from react-router-dom

const Dashboard = () => {
  return (
    <div className="dashboardContainer">
      <Sidebar />
      <div className="rightBlock">
        <Header />
        <div className="mainContainer">
          {/* Outlet will render the child routes */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
