import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const logStatuses = {
    'on_duty': 'ON',
    'OFF': 'OFF',
    'SB': 'SB',
    'driving': 'DRV',

    'PC': 'PC',
    'YM': 'YM',
    'ignition_stop': 'PD',
    'ignition_start': 'PU',
    'login': 'LI',
    'test_event': 'TE'
}

function calculateDuration(startTime,endTime) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const diffInMs = endDate - startDate;

    const hours = Math.floor(diffInMs / (1000 * 60 * 60));
    const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

    const formattedHours = String(hours).padStart(2,'0');
    const formattedMinutes = String(minutes).padStart(2,'0');
    const formattedSeconds = String(seconds).padStart(2,'0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export async function parseELDDataToTableFormat(eldLog) {
    const tableData = [];
    let previousDate = null;
    let duration = '';
    // eldLog = eldLog.reverse()
    for (let i = 0; i < eldLog.length; i++) {
        const date = dayjs(eldLog[i].shiftedTime).utc();
        if (i < eldLog.length - 1) {
            const nextEventTime = dayjs(eldLog[i + 1].shiftedTime).utc();
            duration = calculateDuration(date,nextEventTime)
        }
        const dist = () => {
            if (i === 0) {
                return 0
            } else {
                return eldLog[i].totalDistance / 1000 - eldLog[i - 1].totalDistance / 1000
            }
        }
        
        previousDate = date;
        const rowData = {
            seqNumber: String(eldLog[i].id), // TODO: Add the actual sequence number
            status: logStatuses[eldLog[i].status],
            vehicle: eldLog[i].truckid,
            date: date.format('MM/DD/YYYY'),
            startTime: date.format('HH:mm:ss'),
            odometer: eldLog[i].totalDistance / 1000,
            engineHours: eldLog[i].engineHours,
            duration: String(duration),
            distance: Number(dist().toFixed(3)),
            location: (<a
                href={`https://www.google.com/maps/search/?api=1&query=${eldLog[i].latitude},${eldLog[i].longitude}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit',textDecoration: 'none' }}
            >
                {eldLog[i].location}
            </a>),
            latitude: '',
            longitude: '',
            elapsedEngineHours: 0,
            elapsedOdometer: 0,
            isActiveStatus: '',
            origin: '',
            note: eldLog[i].note,
            totalEngineHours: 0,
            isCreating: true,
        };

        tableData.push(rowData);
    }

    return tableData;
}