import { createContext, useContext, useState, useEffect } from 'react';
import DriverLogDonuts from './DriverLogDonuts';
import DriverLogHeader from './DriverLogHeader';
import DriverLogInfo from './DriverLogInfo';
import DriverLogTableSimple from './DriverLogChartTable';
import DriverLogTimeChart from './DriverLogTimeChart';
import api from '../Login/Authentication';
import type { DriverLogType } from './DriverLog.types';
import mocklog from '../../mockDB/mockDriverLog.json'
import { getDrivers } from '../Administration/Drivers/Drivers.utils';
import { Driver } from '../Administration/Drivers/Drivers.types';
import { AxiosResponse } from 'axios';


export const DriverLogContext = createContext<{ driverLog: DriverLogType[] | null, driversList: Driver[] | null, currentDriver: Driver | null }>({
  driverLog: null,
  driversList: null,
  currentDriver: null
});

const TZ = [
  { "zone": "America/New_York", "time": -5 },
  { "zone": "America/Chicago", "time": -6 }, //America/Chicago
  { "zone": "America/Denver", "time": -7 },
  { "zone": "America/Los_Angeles", "time": -8 },
  { "zone": "America/Anchorage", "time": -9 },
  { "zone": "America/Adak", "time": -10 },
  { "zone": "Pacific/Honolulu", "time": -10 },
  { "zone": "America/Phoenix", "time": -7 },  // Arizona не використовує літній час
  { "zone": "America/Indiana/Indianapolis", "time": -5 },  // Eastern Time без літнього часу
  { "zone": "America/Indiana/Marengo", "time": -5 },  // Eastern Time без літнього часу
  { "zone": "America/Indiana/Knox", "time": -6 },  // Central Time без літнього часу
  { "zone": "America/Indiana/Vevay", "time": -5 },  // Eastern Time без літнього часу
  { "zone": "America/Kentucky/Louisville", "time": -5 },  // Eastern Time без літнього часу
  { "zone": "America/Kentucky/Monticello", "time": -5 }  // Eastern Time без літнього часу
];

const activeCompany = localStorage.getItem('activeCompany');
const companyTimezone = activeCompany ? JSON.parse(activeCompany).timeZone : '';

const timeZoneObj = TZ.find(tz => tz.zone === companyTimezone);
const timeShift = timeZoneObj?.time || 0;

const fetchDriverLog = async (id: string, dateTo: string, dateFrom: string): Promise<DriverLogType[] | null> => {
  try {
    const response = await api.get(`/api/v1/report/driver?driverid=${id}&to=${dateTo}&from=${dateFrom}&timeZone=${companyTimezone}`);
    const sortedData = response.data.sort((a: DriverLogType, b: DriverLogType) => {
      return new Date(b.servertime).getTime() - new Date(a.servertime).getTime();
    });
    console.log('sortedData', sortedData); // for QA

    sortedData.forEach((item: DriverLogType) => {
      const servertime = new Date(item.servertime);
      const shiftedTime = new Date(servertime);
      shiftedTime.setHours(shiftedTime.getHours() + timeShift);
      item.shiftedTime = shiftedTime.toISOString();
    });

    return sortedData;
  } catch (error) {
    console.error('Error fetching driver log:', error);
    return null;
  }
};


const DriverLogProvider = ({ children }: any) => {
  const [driverLog, setDriverLog] = useState<DriverLogType[] | null>(null);
  const [currentDriver, setCurrentDriver] = useState<Driver | null>(null);
  const [driversList, setDriversList] = useState<Driver[] | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const driverId = urlParams.get('id');
  const dateTo = urlParams.get('to');
  const dateFrom = urlParams.get('from');

  useEffect(() => {
    const fetchDriversList = async () => {
      try {
        const drivers = await getDrivers();
        setDriversList(drivers);
      } catch (error) {
        console.error('Error fetching drivers list:', error);
      }
    };

    fetchDriversList();
  }, []);

  useEffect(() => {
    const findCurrentDriver = (drivers: Driver[], id: string) => {
      return drivers.find(driver => driver.id === id) || null;
    };

    if (driverId && dateTo && dateFrom && driversList) {
      const currentDriver = findCurrentDriver(driversList, driverId);
      setCurrentDriver(currentDriver);
      fetchDriverLog(driverId, dateTo, dateFrom)
        .then(setDriverLog)
        .catch(error => console.error('Error fetching driver log:', error));
    }
  }, [driverId, dateTo, dateFrom, driversList]);

  return (
    <DriverLogContext.Provider value={{ driverLog, driversList, currentDriver }}>
      {children}
    </DriverLogContext.Provider>
  );
};

export const useDriverLog = () => useContext(DriverLogContext);

const DriverLog = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const driverId = urlParams.get('id');
  const dateTo = urlParams.get('to');
  const dateFrom = urlParams.get('from');

  return (
    <DriverLogProvider>
      <DriverLogHeader />
      <div className='chart'>
        <DriverLogDonuts />
        <div className='apexContainer'>
          <DriverLogTimeChart />
        </div>
      </div>
      <div className='driverLogTable'>
        <DriverLogTableSimple />
      </div>
      <DriverLogInfo />
    </DriverLogProvider>
  );
};

export default DriverLog;
