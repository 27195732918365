import { RingProgress } from '@mantine/core';
import foo from '../../utils/HOSTimers';
import styles from './LiveTrackerDrawer.module.css';

function timeToPercentage(timeString: string, totalHours: number) {
    foo()
    const timeParts = timeString.split(":");
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);
    const seconds = parseInt(timeParts[2]);

    const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
    const percentage = (totalSeconds / (totalHours * 3600)) * 100;

    return percentage;
}

// function percentageToTime(percentage: number, totalHours: number) {
//   const totalSeconds = (percentage / 100) * totalHours * 3600;

//   const hours = Math.floor(totalSeconds / 3600);
//   const minutes = Math.floor((totalSeconds % 3600) / 60);
//   const seconds = Math.floor(totalSeconds % 60);

//   const timeString = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
//   return timeString;
// }

// 8-hours driving
const nonStopDrivingMax = 8;
const drivingToday = "07:03:58"
const percentageNonStopDriving = timeToPercentage(drivingToday, nonStopDrivingMax);

// 11-hours day drive
const dayDrivingMax = 11;
const dayDrivingToday = "06:03:58"
const percentageDayDriving = timeToPercentage(dayDrivingToday, dayDrivingMax);

// 14-hours shift
const dayShiftMax = 14;
const dayShiftToday = "09:03:58"
const percentageDayShift = timeToPercentage(dayShiftToday, dayShiftMax);

// cycle
const cycleMax = 70
const cycleLeft = "66:03:58"
const percentageCycle = timeToPercentage(cycleLeft, cycleMax);

// const timeInput = "08:00:00";
// const totalHours = 24
// const percentageOutput = timeToPercentage(timeInput, totalHours);
// console.log(`${timeInput} equals ${percentageOutput.toFixed(2)}%`);

// const percentageInput = 18.298;
// const timeOutput = percentageToTime(percentageInput, totalHours);
// console.log(`${percentageInput}% equals ${timeOutput}`);


export default function LiveTrackingDrawerDonuts() {
    return (
        <div className={styles.liveTrackingDrawerDonuts}>
            <div className={styles.liveTrackingDrawerDonutsItem} style={{ borderRight: '#ababab 1px solid' }}>
                <div className={styles.liveTrackingDrawerDonutsTitle}>BREAK</div>
                <div className={styles.liveTrackingDrawerDonutsTime}>{drivingToday}</div>
                <RingProgress
                    size={50}
                    thickness={6}
                    roundCaps
                    sections={[
                        {
                            value: percentageNonStopDriving,
                            color: percentageNonStopDriving >= 100 ? 'red' : '#04498a'
                        }
                    ]}
                />
            </div>

            <div className={styles.liveTrackingDrawerDonutsItem}  style={{ borderRight: '#ababab 1px solid' }}>
                <div className={styles.liveTrackingDrawerDonutsTitle}>DRIVE</div>
                <div className={styles.liveTrackingDrawerDonutsTime}>{dayDrivingToday}</div>
                <RingProgress
                    size={50}
                    thickness={6}
                    roundCaps
                    sections={[
                        {
                            value: percentageNonStopDriving,
                            color: percentageNonStopDriving >= 100 ? 'red' : '#13b03d'
                        }
                    ]}
                />
            </div>
            <div className={styles.liveTrackingDrawerDonutsItem}  style={{ borderRight: '#ababab 1px solid' }}>
                <div className={styles.liveTrackingDrawerDonutsTitle}>SHIFT</div>
                <div className={styles.liveTrackingDrawerDonutsTime}>{dayShiftToday}</div>
                <RingProgress
                    size={50}
                    thickness={6}
                    roundCaps
                    sections={[
                        {
                            value: percentageNonStopDriving,
                            color: percentageNonStopDriving >= 100 ? 'red' : '#53b7e6'
                        }
                    ]}
                />
            </div>
            <div className={styles.liveTrackingDrawerDonutsItem} >
                <div className={styles.liveTrackingDrawerDonutsTitle}>CYCLE</div>
                <div className={styles.liveTrackingDrawerDonutsTime}>{cycleLeft}</div>
                <RingProgress
                    size={50}
                    thickness={6}
                    roundCaps
                    sections={[
                        {
                            value: percentageNonStopDriving,
                            color: percentageNonStopDriving >= 100 ? 'red' : '#626d73'
                        }
                    ]}
                />
            </div>

        </div>
    );
}