export interface Driver {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phone: string;
  driverLicenseNumber: string;
  driverLicenseState: string | null;
  vehicleGroup: string | null;
  numberOfLogsShown: number;
  cargoType: string | null;
  rule: string | null;
  ELDCompliance: boolean;
  isAllowedChangeSettings: boolean;
  odometerUnits: string;
  enableSendingLogs: boolean;
  restart: string;
  is30MinBreak: boolean;
  Is16HourShortHaulException: boolean;
  isAllowYardMove: boolean;
  isAllowDrivingException: boolean;
  isAllowPersonalConv: boolean;
  isExemptDriver: boolean;
  frequencyOfHOSAlert: string;
  isApproved: boolean;
  companies: string[]
  supervisors: string[]
  dispatchers: string[]
}

export const drivers: Driver[] = [{
  id: 13423,
  firstName: 'John',
  lastName: 'Doe',
  email: 'john@example.com',
  username: 'johndoe123',
  phone: '123-456-7890',
  driverLicenseNumber: 'DL12345',
  driverLicenseState: 'CA',
  vehicleGroup: 'sirius',
  numberOfLogsShown: 100,
  cargoType: 'oilAndGas',
  rule: 'usa70h8d',
  ELDCompliance: true,
  isAllowedChangeSettings: false,
  odometerUnits: 'miles',
  enableSendingLogs: false,
  restart: '34',
  is30MinBreak: true,
  Is16HourShortHaulException: false,
  isAllowYardMove: true,
  isAllowDrivingException: false,
  isAllowPersonalConv: true,
  isExemptDriver: false,
  frequencyOfHOSAlert: 'never',
  isApproved: true,
  companies: ['Sirius'],
  supervisors: ['Leonardo', 'Michelangelo'],
  dispatchers: ['Leonardo', 'Jonty', 'Darcey', 'Jordanne']
}, {
  id: 64562,
  firstName: 'Jane',
  lastName: 'Smith',
  email: 'jane@example.com',
  username: 'janesmith456',
  phone: '987-654-3210',
  driverLicenseNumber: 'DL54321',
  driverLicenseState: 'NY',
  vehicleGroup: 'sirius',
  numberOfLogsShown: 120,
  cargoType: 'property',
  rule: 'usa70h8d',
  ELDCompliance: true,
  isAllowedChangeSettings: true,
  odometerUnits: 'kilometers',
  enableSendingLogs: false,
  restart: '34',
  is30MinBreak: false,
  Is16HourShortHaulException: true,
  isAllowYardMove: false,
  isAllowDrivingException: true,
  isAllowPersonalConv: false,
  isExemptDriver: false,
  frequencyOfHOSAlert: '15',
  isApproved: true,
  companies: ['Orion'],
  supervisors: ['Splinter', 'Michelangelo'],
  dispatchers: ['Jordanne', 'Aliya', 'Michelangelo', 'Dewey', 'Yousef']
}, {
  id: 257673,
  firstName: 'Michael',
  lastName: 'Johnson',
  email: 'michael@example.com',
  username: 'michaelj',
  phone: '555-123-4567',
  driverLicenseNumber: 'DL67890',
  driverLicenseState: 'TX',
  vehicleGroup: 'sirius',
  numberOfLogsShown: 90,
  cargoType: 'property',
  rule: 'usa60h7d',
  ELDCompliance: true,
  isAllowedChangeSettings: true,
  odometerUnits: 'miles',
  enableSendingLogs: true,
  restart: '24',
  is30MinBreak: true,
  Is16HourShortHaulException: false,
  isAllowYardMove: true,
  isAllowDrivingException: false,
  isAllowPersonalConv: true,
  isExemptDriver: false,
  frequencyOfHOSAlert: '30',
  isApproved: true,
  companies: ['Orion'],
  supervisors: ['Donatello', 'Raphaello'],
  dispatchers: ['Darcey', 'Jordanne']
}, {
  id: 67974,
  firstName: 'Emily',
  lastName: 'Brown',
  email: 'emily@example.com',
  username: 'emilyb',
  phone: '777-555-8888',
  driverLicenseNumber: 'DL77777',
  driverLicenseState: 'FL',
  vehicleGroup: 'orion',
  numberOfLogsShown: 110,
  cargoType: 'passenger',
  rule: 'usa60h7d',
  ELDCompliance: false,
  isAllowedChangeSettings: false,
  odometerUnits: 'miles',
  enableSendingLogs: true,
  restart: '34',
  is30MinBreak: false,
  Is16HourShortHaulException: true,
  isAllowYardMove: true,
  isAllowDrivingException: true,
  isAllowPersonalConv: true,
  isExemptDriver: false,
  frequencyOfHOSAlert: '60',
  isApproved: false,
  companies: ['Sirius'],
  supervisors: ['Michelangelo'],
  dispatchers: ['Darcey', 'Jordanne', 'Aliya']
}]
