import { useEffect, useState } from 'react';
import { Box, Button, LoadingOverlay, Paper } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { refreshAccessToken, getCookie } from '../components/Login/Authentication';
import { Link } from 'react-router-dom';

const ProtectedRoute = ({ children }: any) => {
    const isAuthenticated = getCookie('accessToken');
    const [loading, setLoading] = useState(true);
    const [visible, { toggle }] = useDisclosure(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!isAuthenticated) {
                    await refreshAccessToken();
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [isAuthenticated]);

    if (loading) {
        return (
            <Box pos="relative">
                <LoadingOverlay visible={visible} loaderProps={{ children: 'Loading...' }} />
            </Box>
        );
    }

    if (!isAuthenticated) {
        return <div className='errorPage'>
            <Paper shadow="xs" p="xl">
                <h2>Oooops....</h2>
                <h3>something went wrong</h3>
                <Link to={`/login`}>
                    <Button type="submit">
                        login
                    </Button>
                </Link>
            </Paper>
            {/* <ErrorPage/> */}
        </div>;
    }

    return <>{children}</>; // Render protected content
};

export default ProtectedRoute;
