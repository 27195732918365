import { Title, Text, Button, Box } from '@mantine/core';
import useStyles from './Welcome.styles';
import { Link } from 'react-router-dom';
import companyStore from "../Store/companyStore";
import { observer } from 'mobx-react-lite';

const Welcome = observer(() => {
    const { classes } = useStyles();

    const activeCompany = companyStore.getActiveCompany();

    return (
        <div className='welcome'>
            <Title className={classes.title} align="center" mt={100}>
                Welcome to{' '}
                <Text inherit variant="gradient" component="span">
                    {activeCompany ? activeCompany.carrierName : 'your Dashboard'}
                </Text>, 
            </Title>
            <Text color="dimmed" align="center" size="lg" sx={{ maxWidth: 580 }} mx="auto" mt="xl">
                We're thrilled to have you here and ready to streamline your fleet management experience.
                Our user-friendly interface is designed to make your life easier and your operations more efficient.
                Whether you're a seasoned pro or new to the world of electronic logging devices, we've got you covered.
            </Text>
            <div className='welcomeBtnContainer'>
                <Box className='welcomePaper'>
                    <Button className="welcomeBtn" color={'blue'} size="md">
                        <Link
                            style={{ textDecoration: 'none', color: '#fff' }}
                            to={'/dashboard/driver-logs'}>
                            Driver Logs
                        </Link>
                    </Button>
                    <Button className="welcomeBtn" color={'blue'} size="md">
                        <Link
                            style={{ textDecoration: 'none', color: '#fff' }}
                            to={'/dashboard/live-tracking'}>
                            Live tracking
                        </Link>
                    </Button>
                    <Button className="welcomeBtn" color={'blue'} size="md">
                        <Link
                            style={{ textDecoration: 'none', color: '#fff' }}
                            to={'/dashboard/inspection-logs/'}>
                            Inspection Logs
                        </Link>
                    </Button>
                    <Button className="welcomeBtn" color={'blue'} size="md">
                        <Link
                            style={{ textDecoration: 'none', color: '#fff' }}
                            to={'/dashboard/driver-logs'}>
                            Need Help?
                        </Link>
                    </Button>
                </Box>
            </div>
        </div>
    );
});

export default Welcome;
