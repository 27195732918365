
import { useLocation, Link } from "react-router-dom"
import { Button } from "@mantine/core"

const ELDLogPrevNextBtns = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const dateString = queryParams.get('date') ?? '';

    const convertToDate = (str: string): Date => {
        const month = parseInt(str.substring(0, 2), 10) - 1;
        const day = parseInt(str.substring(2, 4), 10);
        const year = parseInt("20" + str.substring(4, 6), 10);
        return new Date(year, month, day);
    };

    const currentDate: Date = convertToDate(dateString);

    const changeDate = (date: Date, days: number): Date => {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + days);
        return newDate;
    };

    const prevDate: Date = changeDate(currentDate, -1);
    const nextDate: Date = changeDate(currentDate, 1);

    const formatDateForURL = (date: Date): string => {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear().toString().substring(2);
        return `${month}${day}${year}`;
      };

    const handlePrev = (): void => {
        window.location.href = `/dashboard/support/ELDLog?id=${id}&date=${formatDateForURL(prevDate)}`;
    };

    const handleNext = (): void => {
        window.location.href = `/dashboard/support/ELDLog?id=${id}&date=${formatDateForURL(nextDate)}`;
    };

    return (
        <div className="driverLogTimeChartBtnsRight">
            <div className='driverLogTimeChartBtnDivider'></div>
            <Button className='driverLogTimeChartBtn' onClick={handlePrev}>{`<<Prev`}</Button>
            <Button className='driverLogTimeChartBtn' onClick={handleNext}>{`Next>>`}</Button>
        </div>
    )
}
export default ELDLogPrevNextBtns