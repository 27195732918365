import {
  TextInput,
  PasswordInput,
  Group,
  Box,
  Paper,
  Title,
  Text,
  Select,
  Grid,
  Button,
  Alert,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Link } from "react-router-dom";
import LoginWrapper from "./LoginWrapper";
import states from "../../data/USstates";
import timeZones from "../../data/timezones";
import { useNavigate } from 'react-router-dom';
import { isTokenValid } from "./Authentication";
import { notifications } from "@mantine/notifications";
import { IconInfoCircle } from "@tabler/icons-react";
import { SignupFormValues } from "./Authentication.types";
import { observer } from "mobx-react-lite";
import userStore from "../../Store/userStore";
import companyStore from "../../Store/companyStore";
import { useState } from "react";

const Signup = observer(()=> {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const alertCircleicon = <IconInfoCircle />;
  const form: any = useForm<SignupFormValues>({
    initialValues: {
      username: 'values.email',
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      confirmPassword: "",
      password: "",
      dotNumber: "",
      carrierName: "",
      address: "",
      city: "",
      state: "",
      timeZone: "",
      zipCode: "",
    },

    validate: {
      firstName: (value) => (value !== "" ? null : "Invalid first name"),
      lastName: (value) => (value !== "" ? null : "Invalid last name"),
      email: (value) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : "Invalid email",
      phone: (value) =>
        /^\+?[0-9\s]+$/i.test(value) ? null : "Invalid phone number",
      password: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/.test(value)
          ? null
          : "Invalid password",
      confirmPassword: (value) =>
        value === form.values.password ? null : "Passwords do not match",
      dotNumber: (value) =>
        value !== undefined ? null : "Invalid first DOT number",
      carrierName: (value) => (value !== "" ? null : "Invalid carier name"),
      address: (value) => (value !== "" ? null : "Invalid address"),
      city: (value) => (value !== "" ? null : "Invalid city"),
      zipCode: (value) => (value !== undefined ? null : "Invalid zipCode"),
    },
  });

  const handleSubmit = async (values: SignupFormValues) => {
    try {
      notifications.show({
        id: 'signupProcess',
        title: 'Signing up...',
        message: 'Please wait...',
        loading: true,
        autoClose: false,
      });

      await userStore.signup(values);

      if (userStore.accessToken && isTokenValid(userStore.accessToken)) {
        companyStore.setActiveCompany(userStore.activeCompany);
        navigate('/dashboard/welcome');

        notifications.hide('signupProcess');
        if(userStore.activeCompany){
          notifications.show({
            title: 'Signup successful!',
            message: `Welcome to ${userStore.activeCompany.carrierName}!`,
            color: 'teal',
            icon: <IconInfoCircle />,
          });
        }
      }
    } catch (error: any) {
      console.error('Error during signup:', error);

      notifications.hide('signupProcess');
      notifications.show({
        title: 'Authentication error!',
        message: 'Something went wrong.',
        color: 'red',
        icon: <IconInfoCircle />,
      });

      if (error.response && error.response.status === 420) {
        setShowAlert(true);
        setTimeout(() => setShowAlert(false), 5000);
      }
    }
  };

  return (
    <LoginWrapper>
      <Box maw={1000} mx="auto" className="loginForm-wrapper">
        <Paper shadow="sm" p={30}>
        {showAlert ? (
            <Alert variant="light" color="red" title="Authentication error!" withCloseButton icon={alertCircleicon} onClose={() => setShowAlert(false)}>
              There was an issue with your signup information.
            </Alert>
          ) : (
            <Title order={2} align="center" className="signupTitle">
              Sign Up
            </Title>
          )}
          <form className="signupForm" onSubmit={form.onSubmit(handleSubmit)}>
            <Grid justify="center" grow>
              <Grid.Col sm={6} className="loginFirstColumn">
                <div className="signupRow">
                  <Title order={3} align="center" className="loginTitle">
                    Account information
                  </Title>
                  <TextInput
                    required
                    label="First name"
                    placeholder="First name"
                    error={
                      form.errors.firstName && "Please specify valid first name"
                    }
                    value={form.values.firstName}
                    onChange={(event) =>
                      form.setFieldValue("firstName", event.currentTarget.value)
                    }
                    onBlur={() => form.validateField("firstName")}
                  />
                  <TextInput
                    required
                    label="Last name"
                    placeholder="Last name"
                    error={
                      form.errors.lastName && "Please specify valid last name"
                    }
                    value={form.values.lastName}
                    onChange={(event) =>
                      form.setFieldValue("lastName", event.currentTarget.value)
                    }
                    onBlur={() => form.validateField("lastName")}
                  />
                  <TextInput
                    required
                    label="Email"
                    placeholder="your@email.com"
                    error={form.errors.email && "Please specify valid email"}
                    value={form.values.email}
                    onChange={(event) =>
                      form.setFieldValue("email", event.currentTarget.value)
                    }
                    onBlur={() => form.validateField("email")}
                  />
                  <TextInput
                    required
                    type="number"
                    label="Phone number"
                    placeholder="+1 (888) 888 - 88 - 88"
                    error={
                      form.errors.phone && "Please specify valid phone number"
                    }
                    value={form.values.phone}
                    onChange={(event) =>
                      form.setFieldValue("phone", event.currentTarget.value)
                    }
                    onBlur={() => form.validateField("phone")}
                  />
                  <PasswordInput
                    placeholder="Password"
                    required
                    label="Password"
                    description="Password be min 8 characters long, must contain at least one digit, one uppercase and lowercase letter and at least one special character"
                    error={
                      form.errors.password && "Please specify valid password"
                    }
                    value={form.values.password}
                    onChange={(event) =>
                      form.setFieldValue("password", event.currentTarget.value)
                    }
                    onBlur={() => form.validateField("password")}
                  />
                  <PasswordInput
                    placeholder="Password confirm"
                    label="Password confirm"
                    required
                    error={
                      form.errors.confirmPassword &&
                      "Passwords do not match"
                    }
                    value={form.values.confirmPassword}
                    onChange={(event) =>
                      form.setFieldValue(
                        "confirmPassword",
                        event.currentTarget.value
                      )
                    }
                    onBlur={() => form.validateField("confirmPassword")}
                  />
                </div>
                <Text fz="sm" fw={500}>
                  Already have an account? Go to{" "}
                  <Link className="loginLink" to="/login">
                    login
                  </Link>{" "}
                  page.
                </Text>
              </Grid.Col>
              <Grid.Col sm={6} className="loginSecondColumn">
                <div className="signupRow">
                  <Title order={3} align="center" className="loginTitle">
                    Carrier details
                  </Title>
                  <TextInput
                    required
                    placeholder="DOT Number"
                    label="DOT Number"
                    type="number"
                    error={
                      form.errors.dotNumber && "Please specify valid DOT Number"
                    }
                    value={form.values.dotNumber}
                    onChange={(event) =>
                      form.setFieldValue("dotNumber", event.currentTarget.value)
                    }
                    onBlur={() => form.validateField("dotNumber")}
                  />
                  <TextInput
                    required
                    label="Carrier Name"
                    value={form.values.carrierName}
                    error={
                      form.errors.carrierName && "Please specify valid carrier name"
                    }
                    onChange={(event) =>
                      form.setFieldValue(
                        "carrierName",
                        event.currentTarget.value
                      )
                    }
                    onBlur={() => form.validateField("carrierName")}
                  />
                  <TextInput
                    required
                    label="Address"
                    value={form.values.address}
                    error={
                      form.errors.address && "Please specify valid address"
                    }
                    onChange={(event) =>
                      form.setFieldValue("address", event.currentTarget.value)
                    }
                    onBlur={() => form.validateField("address")}
                  />
                  <TextInput
                    required
                    label="City"
                    value={form.values.city}
                    error={
                      form.errors.city && "Please specify city"
                    }
                    onChange={(event) =>
                      form.setFieldValue("city", event.currentTarget.value)
                    }
                    onBlur={() => form.validateField("city")}
                  />
                  <Select
                    required
                    label="State Name"
                    placeholder="Pick one"
                    data={states}
                    value={form.values.state}
                    onChange={(value) => form.setFieldValue("state", value)}
                  />
                    <TextInput
                      required
                      label="Zip code"
                      type="number"
                      error={
                        form.errors.zipCode && "Please specify valid zipCode"
                      }
                      value={form.values.zipCode}
                      onChange={(event) =>
                        form.setFieldValue("zipCode", event.currentTarget.value)
                      }
                      onBlur={() => form.validateField("zipCode")}
                    />
                  <Select
                    required
                    label="Time zone"
                    value={form.values.timeZone}
                    placeholder="Pick one"
                    data={timeZones}
                    onChange={(value) => form.setFieldValue("timeZone", value)}
                  />
                  <Group position="center" mt="md">
                    <Button size="xs" type="submit" onSubmit={form.onSubmit(handleSubmit)}>
                      Save
                    </Button>
                  </Group>
                </div>
              </Grid.Col>
            </Grid>
          </form>
        </Paper>
      </Box>
    </LoginWrapper>
  );
})

export default Signup;
