import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import { Button } from '@mantine/core';
import MapMarkers from './MapMarkers';

type TruckType = {
  id: string;
  latitude: number;
  longitude: number;
  currentStatus: string;
  FirstName: string;
  LastName: string;
  time?: string
};

type MapComponentProps = {
  filteredData: TruckType[];
  truckCoordinates: TruckType | null;
};

const MapComponent: React.FC<MapComponentProps> = ({ truckCoordinates, filteredData }) => {
  const [tileLayerUrl, setTileLayerUrl] = useState('https://tile.openstreetmap.org/{z}/{x}/{y}.png');
  const [mapCenter, setMapCenter] = useState<LatLngTuple>([40.7187355293246, -99.38189098797235]);
  const [mapZoom, setMapZoom] = useState(5);

  const handleToggleLayer = () => {
    if (tileLayerUrl === 'https://tile.openstreetmap.org/{z}/{x}/{y}.png') {
      setTileLayerUrl('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}');
    } else {
      setTileLayerUrl('https://tile.openstreetmap.org/{z}/{x}/{y}.png');
    }
  };

  useEffect(() => {
    if (truckCoordinates) {
      setMapCenter([truckCoordinates.latitude, truckCoordinates.longitude]);
      setMapZoom(14)
    }
  }, [truckCoordinates]);

  return (
    <MapContainer
      key={`${mapCenter[0]}-${mapCenter[1]}-${mapZoom}`}
      className='mapPaper'
      center={mapCenter}
      zoom={mapZoom}
      zoomControl={false}
    >
      <TileLayer attribution='...' url={tileLayerUrl} />
      <MapMarkers filteredData={filteredData} />
      <div style={{ position: 'absolute', top: '15px', left: '10px', zIndex: 1000 }}>
        <Button onClick={handleToggleLayer}>Toggle TileLayer</Button>
      </div>
    </MapContainer>
  );
};

export default MapComponent;
